import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import useLogsAPI from './useLogsAPI';
import {
  entityCreate, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import * as AC from '../redux/employeeRequest/employeeRequestSlice';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';

export default function useEmployeeRequestAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const getEmployeeRequestsList = (parentUUID, options = {}) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'employeeRequest',
            parent: parentUUID,
            ...options,
          },
          constants: [
            AC.GET_DEPARTMENT_EMPLOYEE_REQUESTS_START,
            AC.GET_DEPARTMENT_EMPLOYEE_REQUESTS_SUCCESS,
            AC.GET_DEPARTMENT_EMPLOYEE_REQUESTS_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок для отдела!');
      return null;
    }
  };

  const getAllDepartmentsRequestsList = (parentUUID, options = {}) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'employeeRequest',
            parent: parentUUID,
            ...options,
          },
          constants: [
            AC.GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_START,
            AC.GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_SUCCESS,
            AC.GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок для всех отделов!');
      return null;
    }
  };

  const getDepartmentsList = (parentUUID, options = {}) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_type: 'department',
            parent: parentUUID,
            ...options,
          },
          constants: [
            AC.GET_DEPARTMENTS_LIST_START,
            AC.GET_DEPARTMENTS_LIST_SUCCESS,
            AC.GET_DEPARTMENTS_LIST_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список отделов!');
      return null;
    }
  };

  const createEmployeeRequest = async (parentUUID, data) => {
    try {
      const newEmployeeRequest = await dispatch(
        entityCreate({
          data: {
            parent: parentUUID,
            ...data,
          },
          constants: [
            AC.CREATE_EMPLOYEE_REQUEST_START,
            AC.CREATE_EMPLOYEE_REQUEST_SUCCESS,
            AC.CREATE_EMPLOYEE_REQUEST_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newEmployeeRequest, '[0].uuid', '')) {
        createLog({
          parent: get(newEmployeeRequest, '[0].uuid', ''),
          type: 'CREATE',
          entityType: data?.entity_type,
          values: get(newEmployeeRequest, '[0].params', ''),
        });
      }

      return newEmployeeRequest;
    } catch (e) {
      antNotification.error('Не удалось создать заявку!');
      return null;
    }
  };

  const editEmployeeRequest = async (requestUUID, data) => {
    try {
      const newEmployeeRequest = await dispatch(
        entityUpdate({
          data: {
            entity_uuid: requestUUID,
            ...data,
          },
          constants: [
            AC.EDIT_EMPLOYEE_REQUEST_START,
            AC.EDIT_EMPLOYEE_REQUEST_SUCCESS,
            AC.EDIT_EMPLOYEE_REQUEST_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newEmployeeRequest, '[0].uuid', '')) {
        createLog({
          parent: get(newEmployeeRequest, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: data?.entity_type,
          values: get(newEmployeeRequest, '[0].params', ''),
        });
      }

      return newEmployeeRequest;
    } catch (e) {
      antNotification.error('Не удалось изменить заявку!');
      return null;
    }
  };

  return {
    getEmployeeRequestsList,
    getAllDepartmentsRequestsList,
    getDepartmentsList,
    createEmployeeRequest,
    editEmployeeRequest,
  };
}
