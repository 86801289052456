import React, { useEffect, useState } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import {
  Col, Flex, Modal, Row, Table,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { capitalize, get } from 'lodash';
import useAPI from '../../../../api/useAPI';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';

import '../vacation-styles.scss';
import TableVacations from '../../../user/vacationsRequests/TableVacations';
import VacationTimeStatus from '../VacationTimeStatus';
import CopyURL from '../../../../components/commonComponents/CopyURL';
import { getFullNameOrInitials } from '../../../../utils/utils54origins';
import useVacationsAPI from '../../../../api/useVacationsAPI';

export default function UserDayOffHistory({
  isOpen,
  onClose,
  actor = '',
  uinfo = {},
}) {
  const deliveryPartition = useSelector(getDeliveryPartition);

  const [vacations, setVacations] = useState([]);
  const [sickdays, setSickdays] = useState([]);
  const [dayOffs, setDayOffs] = useState([]);

  const [isVacationFetching, setIsVacationFetching] = useState(true);
  const [isDayOffsFetching, setIsDayOffsFetching] = useState(true);

  const startDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  const {
    getListOfMonthlyReports,
  } = useAPI();

  const { getListOfVacations } = useVacationsAPI();

  const getDaysBetweenDates = (reports, start, end) => reports.flatMap((r) => get(r, 'params.days', [])
    .filter((day) => {
      const date = dayjs(day?.rangeDates?.[0]);
      return day?.rangeDates?.[0] && dayjs(date).isBetween(start, end, null, '[]') && dayjs(date).day() >= 1 && dayjs(date).day() <= 5;
    }));

  const getDaysByType = (days = [], types = []) => {
    const objDays = {};
    types.forEach((type) => {
      objDays[type] = [];
    });

    days.forEach((day) => {
      if (types.includes(day.type)) {
        objDays[day.type].push(day);
      }
    });

    return objDays;
  };

  const getColumnsCallback = (defaultColumns) => {
    const data = defaultColumns.reduce(((acc, i) => {
      if (i?.key === 'status') {
        return [
          {
            dataIndex: 'uuid',
            width: 20,
            title: '',
            render: (uuid) => (
              <CopyURL
                entityType="vacation"
                entityUUID={uuid}
              />
            ),
          }, i, ...acc];
      }

      if (i?.key === 'date') {
        return [
          ...acc,
          {
            title: 'Статус (время)',
            dataIndex: ['rangeDates'],
            render: (el, row) => (
              <VacationTimeStatus
                status={row?.params?.status}
                start_date={row?.params?.start_date}
                end_date={row?.params?.end_date}
                rangeDates={row?.params?.rangeDates}
              />
            ),
          },
          i,
        ];
      }

      if (i?.key === 'created') {
        return [
          ...acc,
          {
            title: 'Рассмотрел',
            dataIndex: ['params', 'reviewed'],
            render: (reviewed, row) => (reviewed?.uinfo ? capitalize(`${get(reviewed?.uinfo, 'first_name')} ${get(reviewed?.uinfo, 'last_name')}`) : '-'),
          }, i];
      }
      return [...acc, i];
    }), []);

    return data;
  };

  const initVacations = async () => {
    setIsVacationFetching(true);
    const configRequest = {
      actor,
      params: {
        status: ['pending', 'approved', 'rejected', 'pre-agreed'],
        rangeDates: enumerateDaysBetweenDates(
          startDate,
          endDate,
        ),
      },
    };

    const res = await getListOfVacations(
      deliveryPartition,
      configRequest,
      [
        'GET_VACATIONS_FOR_HISTORY_REQUEST',
        'GET_VACATIONS_FOR_HISTORY_SUCCESS',
        'GET_VACATIONS_FOR_HISTORY_FAILURE',
      ],
    );

    setVacations(res.data);
    setIsVacationFetching(false);
  };

  const initDayOffsAndSickdays = async () => {
    setIsDayOffsFetching(true);
    const startOfMonth = dayjs(startDate).startOf('month');
    const endOfMonth = dayjs(endDate).endOf('month');
    let currentMonth = startOfMonth;
    const monthsInRange = [];

    while (currentMonth.isBefore(endOfMonth) || currentMonth.isSame(endOfMonth)) {
      monthsInRange.push(currentMonth.format('YYYY-MM'));
      currentMonth = currentMonth.add(1, 'month').startOf('month');
    }

    const configRequest = {
      actor,
      params: {
        date: monthsInRange,
      },
    };

    const res = await getListOfMonthlyReports(deliveryPartition, configRequest);
    const filteredDays = getDaysBetweenDates(res.data, startDate, endDate);
    const { sickday, dayoff } = getDaysByType(filteredDays, ['sickday', 'dayoff']);

    setSickdays(sickday);
    setDayOffs(dayoff);
    setIsDayOffsFetching(false);
  };

  useEffect(() => {
    if (actor) {
      initVacations();
      initDayOffsAndSickdays();
    }
  }, [actor]);

  const dayColumns = [{
    title: '',
    width: '10%',
    dataIndex: ['type'],
    render: (cell) => {
      if (cell === 'sickday') {
        return (
          <Col
            className="day-off-button sickday-button-selected"
          >
            Б
          </Col>
        );
      }
      if (cell === 'dayoff') {
        return (
          <Col
            className="day-off-button dayoff-button-selected"
          >
            О
          </Col>
        );
      }
      return null;
    },
  }, {
    title: 'Дата',
    dataIndex: ['rangeDates'],
    render: (cell) => (cell?.[0] ? `${cell?.[0]} (${dayjs(cell?.[0]).format('dd')})` : '-'),
  }, {
    title: 'Создан',
    dataIndex: ['rangeDates'],
    render: (cell) => cell?.[0] ?? '-',
  }];

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={900}
      title={`Данные пользователя ${getFullNameOrInitials({ uinfo }, 'fullname')} с ${dayjs(startDate).format('DD MMM YYYY')} по ${dayjs(endDate).format('DD MMM YYYY')}`}
      centered
      destroyOnClose
    >
      <Row
        align="space-between"
      >
        <Col
          style={{ width: 420 }}
        >
          <span className="history-header">
            Больничные
          </span>
          {' '}
          {sickdays.length}
          {' '}
          дней
          <Flex
            className="history-container-dashed"
          >
            <Table
              size="small"
              loading={isDayOffsFetching}
              dataSource={sickdays}
              pagination={false}
              columns={dayColumns}
              scroll={{ y: 300 }}
            />
          </Flex>
        </Col>
        <Col
          style={{ width: 420 }}
        >
          <span className="history-header">
            Отгулы
          </span>
          {' '}
          {dayOffs.length}
          {' '}
          дней
          <Flex
            className="history-container-dashed"
          >
            <Table
              size="small"
              loading={isDayOffsFetching}
              dataSource={dayOffs}
              pagination={false}
              columns={dayColumns}
              scroll={{ y: 300 }}
            />
          </Flex>
        </Col>
      </Row>
      <Flex
        className="mt-3 w-100"
        vertical
      >
        <span className="history-header">
          Отпуска
        </span>
        <Flex
          className="history-container-dashed"
          style={{
            height: 500,
            overflowY: 'scroll',
          }}
        >
          <TableVacations
            id="listVacationForHistory"
            total={vacations.length}
            loading={isVacationFetching}
            isSorterStatus={false}
            hide={{ type: true }}
            vacations={vacations}
            getColumnsCallback={getColumnsCallback}
            hidePagination
          />
        </Flex>
      </Flex>
    </Modal>
  );
}

UserDayOffHistory.propTypes = {
  isOpen: bool,
  onClose: func,
  actor: string,
  uinfo: object,
};
