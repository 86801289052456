import { useDispatch, useSelector } from 'react-redux';
import { affixCreate, getListAndReadAffix } from './actions/affix';
import { SERVICE_PARTITION_NAME } from './app_config';
import { getProfileUUID } from '../redux/profile/selectors';

export default function useLogsAPI() {
  const dispatch = useDispatch();

  const actorUUID = useSelector(getProfileUUID);

  const createLog = ({
    parent,
    values,
    type,
    entityType,
    files = [],
  }) => {
    const data = {
      entity_uuid: parent,
      actor: actorUUID,
      affix_type: `${entityType}Log`,
      params: {
        type: type || 'UPDATE',
        values,
      },
    };

    let payload;
    if (files.length) {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      files.forEach((file) => formData.append('file', file));
      payload = formData;
    } else {
      payload = { ...data };
    }

    dispatch(affixCreate({
      data: payload,
      constants: [
        'CREATE_LOG_REQUEST',
        'CREATE_LOG_SUCCESS',
        'CREATE_LOG_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
  };

  const getListOfLogs = ({
    parent,
    affixTypes,
    ...options
  }) => {
    const data = {
      entity_uuid: parent,
      affix_type: affixTypes,
      depth: 0,
      ...options,
    };

    return dispatch(getListAndReadAffix({
      data,
      constants: [
        'GET_LIST_OF_LOGS_REQUEST',
        'GET_LIST_OF_LOGS_SUCCESS',
        'GET_LIST_OF_LOGS_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
  };

  return {
    createLog,
    getListOfLogs,
  };
}
