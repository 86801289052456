import React from 'react';
import cn from 'classnames';
import { Col, Row, Tag } from 'antd';
import PropTypes, { element } from 'prop-types';
import { propOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import './UniGeneralButtons.scss';

// import { ACTIVITIES_TABS } from './commonComponents/activities/activitiesTabs';

export default function UniGeneralButtons({
  inPanel,
  switchViewFunc,
  viewMode,
  tabs = [],
  classNameTab,
  beforeTabsContent,
  affterTabsContent,
}) {
  const { t } = useTranslation();

  const getTranslate = (item) => {
    const { name, pathForTranslate, title } = item;

    if (!name) {
      return '';
    }

    if (pathForTranslate) {
      return capitalize(t(pathForTranslate, (title || name).toLowerCase()));
    }
    // if (name === ACTIVITIES_TABS.COMMENTS) {
    //   return capitalize(t(`wms.noun.${(title || name).toLowerCase()}.1`, (title || name).toLowerCase()));
    // }
    return capitalize(t(`wms.labels.${(title || name).toLowerCase()}`, (title || name).toLowerCase()));
  };

  const nameClassName = classNameTab || 'general_item';
  // console.log('viewMode',viewMode)
  const tabContent = (item) => (
    <div
      key={item.name}
      className={cn('relative', { 'tab-container': inPanel })}
    >
      <span
        className={`px-3 ${nameClassName} ${viewMode?.toLowerCase() === item?.name?.toLowerCase() ? 'active' : ''} ${item.disabled ? 'disabledTab' : ''}`}
        onClick={item.disabled
          ? () => { }
          : () => switchViewFunc(item.name)}
        role="button"
        tabIndex="0"
      >
        {item.icon && (
          <span className="mr-1">
            {propOr('', 'icon', item)}
          </span>
        )}
        {getTranslate(item)}
      </span>
    </div>
  );

  return (
    <Row type="flex" align="middle">
      {beforeTabsContent && beforeTabsContent}
      <Col className="w-full">
        <div style={{ gap: '5px' }} className="flex flex-wrap">
          {tabs.map((item, i) => {
            const { version, subData } = item;
            const key = `tab_${i}`;
            if (subData && subData?.labelForRecord) {
              return (
                <div className="relative">
                  <Tag
                    color="warning"
                    className="subData-tag"
                  >
                    {subData?.labelForRecord}
                  </Tag>
                  {tabContent(item, key)}
                </div>
              );
            }
            return tabContent(item, key);
          })}
        </div>
      </Col>
      {affterTabsContent && (
        <Col className="flex justify-end grow ml-1">{affterTabsContent}</Col>
      )}
    </Row>
  );
}

UniGeneralButtons.propTypes = {
  inPanel: PropTypes.bool,
  viewMode: PropTypes.string,
  switchViewFunc: PropTypes.func,
  tabs: PropTypes.array,
  classNameTab: PropTypes.string,
  affterTabsContent: element,
  beforeTabsContent: element,
};
