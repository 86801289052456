import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowULeftTop, mdiPencil } from '@mdi/js';

import {
  any,
  bool,
  string,
  func,
} from 'prop-types';
import { Button, Flex, Tooltip } from 'antd';

import { capitalize } from 'lodash';

export default function EditButton({
  id,
  className,
  isEditFlag,
  isHidden = false,
  onClick,
  disabled,
  hideCancel,
}) {
  const { t } = useTranslation();
  const toggleBtn = (e) => onClick?.(e);
  const closeEdit = (e) => {
    const close = true;
    onClick?.(e, close);
  };

  return (
    !isHidden && (
      <Flex
        align="center"
        className="mr-2"
      >
        <Tooltip
          title="Закрыть"
          color="#E28000"
        >
          <Button
            size="small"
            id={id}
            shape="default"
            className={cn('mr-1 view_btn_link__orange border', { '!hidden': !isEditFlag || hideCancel })}
            onClick={closeEdit}
          >
            <Icon path={mdiArrowULeftTop} size={0.8} />
          </Button>
        </Tooltip>

        {isEditFlag ? (
          <Button
            size="small"
            id={id}
            shape="default"
            // style={{
            //   border: disabled ? '' : '#72b153de solid 1px',
            //   color: disabled ? '' : '#72b153de'
            // }}
            className={cn(`${className}`, { view_btn_link: !isEditFlag, btnGreen: isEditFlag })}
            disabled={disabled}
            onClick={toggleBtn}
          >
            Save
          </Button>
        ) : (
          <Button
            size="small"
            id={id}
            shape="default"
            className={cn(`${className} !w-7 flex items-center justify-center !h-7 p-0 !rounded-full border-white text-blue-500 hover:!border-blue-500`, {
            })}
            disabled={disabled}
            onClick={toggleBtn}
          >
            <Flex>
              <Icon path={mdiPencil} size={0.85} />
            </Flex>
          </Button>
        )}
      </Flex>
    )
  );
}
EditButton.propTypes = {
  className: string,
  isEditFlag: bool,
  isHidden: bool,
  disabled: bool,
  hideCancel: bool,
  id: any,
  onClick: func,
};
