import {
  Alert,
  Col,
  Modal,
  Row,
} from 'antd';
import { pathOr, pick, prop } from 'ramda';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Prompt } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';

import {
  useLocation, useHistory,
} from 'react-router-dom';
import classNames from 'classnames';
import ViewingPermsCard from './ViewingPermsCard';
// import InfoAndSetPermissionsCard from '../../components/permissions/InfoAndSetPermissionsCard';
// import CreatorPermsCard from '../../components/permissions/CreatorPermsCard';

// import {
//   clearActorsFetching,
//   clearActorsWithBanedUsers,
//   clearEntityPermissions,
// } from '../../../entity/actions/EntityActionsForHook';

import {
  CLEAR_AND_GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS,
  CLEAR_AND_GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_FAILURE,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_REQUEST,
  GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_FAILURE,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_REQUEST,
  GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS,
  GET_PERSONAL_USER_PERMS_FOR_PSP_FAILURE,
  GET_PERSONAL_USER_PERMS_FOR_PSP_REQUEST,
  GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_FAILURE,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_REQUEST,
  GET_SELECTED_ACTOR_PERM_FOR_PSP_SUCCESS,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_FAILURE,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_REQUEST,
  GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS,
} from '../../redux/permissions/permissionsSlice';
import useURLParams from '../../hooks/useURLParams';
import PermissionsContext from '../../context/PermissionsContext';
import {
  getEntityPermission,
  getActorPermissionsTree,
  createEntityPermissions,
} from '../../redux/permissions/permissionsActions copy';
import CreatorPermsCard from './CreatorPermsCard';
import InfoAndSetPermissionsCard from './InfoAndSetPermissionsCard';

const defaultPerms = {
  create: false,
  read: false,
  update: false,
  delete: false,
  list: false,
  set: false,
};

const defaultNotSaved = { creatorCard: false, infoCard: false, expandCard: false };

function PermissionsStartPage({
  isModalMode = false,
  entityUUID,
  nameSectionPermissions,
  partitionType = 'DELIVERY',
  options = {},
  className = '',
}) {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const { getURLParams, clearSearchParams } = useURLParams();

  const [notSavedPermissions, setNotSavedPermissions] = useState(defaultNotSaved);
  const [resetFromMainContainer, setResetFromMainContainer] = useState(false);

  const { activeActorPerm } = getURLParams();

  const { isFullWidthMode, personalCardClassName = '' } = options;

  const getName = (row) => {
    let name = '';
    switch (prop('actor_type', row)) {
      case 'group':
        name = pathOr('Name no found', ['uinfo', 'group_name'], row);
        break;
      case 'service':
        name = pathOr('Name no found', ['uinfo', 'service_name'], row);
        break;
      default:
        name = `${pathOr('Name', ['uinfo', 'first_name'], row)}
     ${pathOr('not found', ['uinfo', 'last_name'], row)}`;
    }

    return name;
  };

  const checkIsUserType = (type) => type === 'user'
    || type === 'classic_user'
    || type === 'phantom';

  const setNotSavedDataCallback = (type, bool) => {
    if (notSavedPermissions[type] !== bool) {
      setNotSavedPermissions((state) => ({
        ...state,
        [type]: bool,
      }));
    }
  };

  const getActorTreePermissions = (uuid) => {
    const data = {
      entity_uuid: entityUUID,
      actor: uuid,
    };

    return dispatch(getActorPermissionsTree({
      data,
      partition: partitionType,
    }));
  };

  const updateSwitchDataCallback = (currentItem, itemsWithNewPermForSend, newPermData) => {
    let checkActor = false;

    const newPerms = [...itemsWithNewPermForSend].map((i) => {
      if (prop('actor_uuid', i) === prop('actor_uuid', currentItem)
        && prop('entity_uuid', i) === prop('entity_uuid', currentItem)) {
        checkActor = true;
        return ({
          ...i,
          ...newPermData,
        });
      }
      return i;
    });

    if (!checkActor) {
      newPerms.push({
        ...currentItem,
        ...newPermData,
      });
    }

    return [...newPerms];
  };

  const updatePermissions = (params) => dispatch(createEntityPermissions({
    data: params,
    partition: partitionType,
  }));

  const viewingAlert = (type, message, description) => (
    <Alert
      message={message}
      description={description}
      type={type}
      className="mt-2"
      showIcon
      icon={(
        <Icon
          className="mr-3"
          path={mdiAlertCircleOutline}
          size={3}
        />
      )}
    />
  );

  const getConstantsForFetchingPerms = (type = '', withClear = false) => {
    switch (type) {
      case 'defaultGroups':
        return [
          GET_DEFAULT_GROUPS_PERMS_FOR_PSP_REQUEST,
          withClear
            ? CLEAR_AND_GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS
            : GET_DEFAULT_GROUPS_PERMS_FOR_PSP_SUCCESS,
          GET_DEFAULT_GROUPS_PERMS_FOR_PSP_FAILURE,
        ];
      case 'otherGroups':
        return [
          GET_OTHER_GROUPS_PERMS_FOR_PSP_REQUEST,
          withClear
            ? CLEAR_AND_GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS
            : GET_OTHER_GROUPS_PERMS_FOR_PSP_SUCCESS,
          GET_OTHER_GROUPS_PERMS_FOR_PSP_FAILURE,
        ];
      case 'personalUsers':
        return [
          GET_PERSONAL_USER_PERMS_FOR_PSP_REQUEST,
          withClear
            ? CLEAR_AND_GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS
            : GET_PERSONAL_USER_PERMS_FOR_PSP_SUCCESS,
          GET_PERSONAL_USER_PERMS_FOR_PSP_FAILURE,
        ];
      case 'usersInGroup':
        return [
          GET_USER_PERMS_IN_GROUP_FOR_PSP_REQUEST,
          withClear
            ? CLEAR_AND_GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS
            : GET_USER_PERMS_IN_GROUP_FOR_PSP_SUCCESS,
          GET_USER_PERMS_IN_GROUP_FOR_PSP_FAILURE,
        ];
      case 'selectedPerm':
        return [
          GET_SELECTED_ACTOR_PERM_FOR_PSP_REQUEST,
          GET_SELECTED_ACTOR_PERM_FOR_PSP_SUCCESS,
          GET_SELECTED_ACTOR_PERM_FOR_PSP_FAILURE,
        ];
      default: return [
        'GET_PERMS_FOR_PSP_REQUEST',
        'GET_PERMS_FOR_PSP_SUCCESS',
        'GET_PERMS_FOR_PSP_FAILURE',
      ];
    }
  };

  const getEntityPerms = ({
    uuid, params, type, withClear,
  }) => {
    const config = {
      partition: partitionType,
      params: {
        entity_uuid: uuid || entityUUID,
        ...params,
      },
      constants: getConstantsForFetchingPerms(type, withClear),
    };

    return new Promise((resolve) => dispatch(getEntityPermission(config, (res) => resolve(res))));
  };

  const saveTreePermissions = async (data) => {
    const initialDataReducer = {
      currentEntity: { entity_uuid: '', data: [] },
      otherEntity: { entity_uuid: '', data: [] },
    };

    const filteredData = data.reduce((acc, currentItem) => {
      const updateItem = (entityName) => ({
        ...acc,
        [entityName]: {
          entity_uuid: prop('entity_uuid', currentItem) || entityUUID,
          data: [
            ...acc[entityName].data,
            {
              actor: currentItem.actor_uuid,
              ...pick([
                'create',
                'read',
                'update',
                'delete',
                'list',
                'set',
              ], currentItem),
            },
          ],
        },
      });

      if (prop('entity_uuid', currentItem) === entityUUID) {
        return updateItem('currentEntity');
      }
      return updateItem('otherEntity');
    }, initialDataReducer);

    if (filteredData.currentEntity.data.length !== 0) {
      await updatePermissions(filteredData.currentEntity);
    }

    if (filteredData.otherEntity.data.length !== 0) {
      await updatePermissions(filteredData.otherEntity);
    }

    // await getEntityPerms();
  };

  const checkNotSavedPermissions = (currentCard) => {
    const name = ['entityCard', 'infoCard'].find((item) => item !== currentCard);
    if (notSavedPermissions[name]) {
      // confirmNotSaved();
      return true;
    }
    return false;
  };

  const getDefaultGroupsPerms = () => {
    getEntityPerms({
      params: {
        actor_type: ['group'],
        uinfo: {
          group_name: ['ADMIN', 'BAN', 'DEFAULT'],
        },
      },
      type: 'defaultGroups',
      withClear: true,
    });
  };

  const initFunc = async () => {
    // const noGetBanedUsers = false;
    // dispatch(clearActorsFetching);
    getDefaultGroupsPerms();
    // await getEntityPerms({ uuid: entityUUID });
    // dispatch(getMyInfoAndListOfActors(noGetBanedUsers));
  };

  useEffect(() => {
    initFunc();
    return () => {
      // dispatch(clearEntityPermissions);
      // dispatch(clearActorsFetching);
      // dispatch(clearActorsWithBanedUsers);
    };
  }, []);

  useEffect(() => {
    if (resetFromMainContainer) {
      getDefaultGroupsPerms();
      setResetFromMainContainer(false);
    }
  }, [resetFromMainContainer]);

  return (
    <PermissionsContext.Provider
      value={{
        entityUUID,
        partitionType,
        resetFromMainContainer,
        defaultPerms,
        getName,
        notSavedDataCallback: setNotSavedDataCallback,
        updateSwitchDataCallback,
        checkNotSavedPermissions,
        getEntityPerms,
        getActorTreePermissions,
        saveTreePermissions,
        checkIsUserType,
        viewingAlert,
        updatePermissions,
      }}
    >
      <Row className={`!w-full !h-full ${className}`}>
        <Col span={isModalMode ? 24 : 12}>
          <CreatorPermsCard
            nameSectionPermissions={nameSectionPermissions}
            notSavedPermissions={notSavedPermissions}
          />
          <ViewingPermsCard />
        </Col>

        {activeActorPerm && !isModalMode && (
        <Col span={12}>
          <InfoAndSetPermissionsCard
            className={`mr-6 ${personalCardClassName}`}
            notSavedPermissions={notSavedPermissions}
          />
        </Col>
        )}

        {activeActorPerm && isModalMode && (
        <Modal
          open={activeActorPerm}
          width={900}
          onCancel={() => clearSearchParams(['activeActorPerm', 'typeActor', 'expandedActorPerm'])}
          footer={null}
          centered
          destroyOnClose
        >
          <InfoAndSetPermissionsCard
            setResetFromMainContainer={setResetFromMainContainer}
            className={`mr-6 ${personalCardClassName}`}
            notSavedPermissions={notSavedPermissions}
          />
        </Modal>
        )}
      </Row>
    </PermissionsContext.Provider>
  );
}

PermissionsStartPage.propTypes = {
  nameSectionPermissions: PropTypes.string,
  partitionType: PropTypes.string,
  entityUUID: PropTypes.string,
  options: PropTypes.object,
};

export default PermissionsStartPage;
