/* eslint-disable import/prefer-default-export */

export const allEventsEntityTypes = ['metaeventCreateMonthlyReport'];

export const eventSettings = [{
  title: 'Создание месячного отчета подрядчика',
  value: 'create_monthly_report',
  entity_type: 'metaeventCreateMonthlyReport',
  steps: 3,
}, {
  title: 'Утверждение отпуска',
  value: 'vacation',
  steps: 6,
}, {
  title: 'Отключение подрядчика',
  value: 'disconnecting',
  steps: 4,
}, {
  title: 'Онбординг подрядчика',
  value: 'onboarding',
  steps: 7,
}, {
  title: 'Создание больничного',
  value: 'sickday',
  steps: 5,
}, {
  title: 'Утверждение отгула',
  value: 'dayoff',
  steps: 4,
}, {
  title: 'Создание отчета отдела',
  value: 'departmentReport',
  steps: 3,
}, {
  title: 'Перевод подрядчика в другой отдел',
  value: 'transfer',
  steps: 2,
}, {
  title: 'Создание заявки на подрядчика',
  value: 'employeeRequest',
  steps: 2,
}];
