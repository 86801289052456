/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { isArray, isString } from 'lodash';

const initialState = {};

const resetSlice = createSlice({
  name: 'resetSlice',
  initialState,
  reducers: {
    ENABLE_RESET_PROPERTY(state, action) {
      const key = action?.payload;
      if (isArray(key)) {
        key.forEach((element) => {
          state[element] = true;
        });
      }
      if (isString(key)) {
        state[key] = true;
      }
    },
    DISABLE_RESET_PROPERTY(state, action) {
      const key = action?.payload;
      if (isArray(key)) {
        key.forEach((element) => {
          state[element] = false;
        });
      }
      if (isString(key)) {
        state[key] = false;
      }
    },
  },
});

export const {
  ENABLE_RESET_PROPERTY,
  DISABLE_RESET_PROPERTY,
} = resetSlice.actions;

export default resetSlice.reducer;

/*
    KEY - COMPONENT (description)

    department_list - DepartmentList

    artifact_list - ArifactsList

    artifact_list_without_department - ArifactsList (без отдела)

    employees_list_for_department - EmployeesForDepartment
*/
