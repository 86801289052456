import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card, Flex, Tag, Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { get, omit } from 'lodash';
import Icon from '@mdi/react';
import { mdiArrowRightThin, mdiMagnify } from '@mdi/js';
import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';
import humanizeDuration from 'humanize-duration';
import useLogsAPI from '../../../api/useLogsAPI';
import BaseTableWithPagination from '../../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getFullNameOrInitials } from '../../../utils/utils54origins';
import DepartmentsList from './DepartmentsList';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import { allLogTypes, getLogNameByType, getLogTagByType } from '../../../constants/logConstantsAndUtils';
import { convertTimeFormat, convertTimeFormatToHours } from './department/utils';
import { isWorkingDay } from '../../user/dayOff/utils';
import { translateTypeDay } from '../../../constants/dayConstantsAndUtils';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import { daysToMilliseconds, diffDays } from '../../../utils/dateTimeUtils';
import { maxCountOfVacationDays } from '../../user/vacationsRequests/utils';

export default function DepartmentLogsPage() {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const { getListOfLogs } = useLogsAPI();

  const { readListOfDepartments } = useDepartmentsAPI();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 50,
    offset: 0,
  });

  const renderPeriod = (row) => {
    const startDate = get(row, 'start_date');
    const endDate = get(row, 'end_date');
    const status = get(row, 'status');
    const days = diffDays(startDate, endDate);
    const diffDate = daysToMilliseconds(days);
    return (
      <>
        {/* <div className={`mr-4 ${days > maxCountOfVacationDays && status !== 'approved' ? 'text-red-600' : 'text-green-600'} font-medium`}>
          {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
          {' '}
        </div> */}

        <div className="flex items-center text-black">
          {dayjs(startDate).locale('ru').format('ddd DD-MM-YYYY')}
          <Icon path={mdiArrowRightThin} className="mx-3" size={1} />
          {dayjs(endDate).locale('ru').format('ddd DD-MM-YYYY')}
        </div>
      </>
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: ['params', 'type'],
      width: '10%',
      render: (cell, row) => (
        <Flex vertical>
          {cell === 'CREATE' && <span className="text-green-500">Создано</span>}
          {cell === 'UPDATE' && <span className="text-blue-500">Изменено</span>}
        </Flex>
      ),
    },
    {
      title: 'Тип',
      dataIndex: ['affix_type'],
      width: '5%',
      render: (cell, row) => getLogTagByType(cell),
    },
    {
      title: 'Автор',
      width: '20%',
      dataIndex: ['uinfo'],
      render: (cell, row) => {
        const fullname = getFullNameOrInitials(row, 'fullname');

        return (
          <Flex vertical>
            {fullname?.length > 40 ? (
              <Tooltip title={fullname}>
                {fullname?.slice(0, 37)}
                ...
              </Tooltip>
            ) : fullname}
          </Flex>
        );
      },
    },
    {
      title: 'Краткий обзор',
      dataIndex: ['params', 'values'],
      width: '30%',
      render: (cell, row) => (
        <Flex>
          <Flex>
            {row?.affix_type === 'monthlyReportDayLog' && (
            <Flex
              vertical
              className="mr-6 text-base text-blue-500"
            >
              <span
                className={cn('', {
                  'text-red-500': !isWorkingDay(row?.params?.values?.type),
                })}
              >
                {translateTypeDay[row?.params?.values?.type]}
              </span>

              {(row?.params?.values?.startWork || row?.params?.values?.finishWork || row?.params?.values?.hoursWorked) && (
                <span>
                  {convertTimeFormat(row?.params?.values?.startWork)}
                  -
                  {convertTimeFormat(row?.params?.values?.finishWork)}
                  {' ('}
                  {convertTimeFormatToHours(row?.params?.values?.hoursWorked)}
                  {') '}
                </span>
              )}
            </Flex>
            )}
            {row?.affix_type === 'monthlyReportLog' && (
            <Flex
              vertical
              className="mr-6 text-base text-blue-500"
            >
              <span>
                Всего рабочих часов:
                {' '}
                {cell?.statistics?.totalWorks}
              </span>
              <span
                className={cn('', {
                  'text-red-500': cell?.statistics?.totalDayOff > 0,
                })}
              >
                Всего пропущенных часов:
                {' '}
                {cell?.statistics?.totalDayOff}
              </span>
            </Flex>
            )}
            {row?.affix_type === 'vacationLog' && (
            <Flex
              vertical
              className="mr-6 text-base text-blue-500"
            >
              {cell?.status === 'created' && ('Добавлена в черновик')}
              {cell?.status === 'pending' && ('Отправлена на рассмотрение')}
              {cell?.status === 'rejected' && ('Отклонена')}
              {cell?.status === 'pre-agreed' && ('Предварительно согласована')}
              {cell?.status === 'approved' && ('Утверждена')}
              {renderPeriod(cell)}
            </Flex>
            )}
          </Flex>
        </Flex>
      ),
    },
    {
      title: 'Дата',
      width: '20%',
      dataIndex: ['created'],
      render: (cell, row) => dayjs(cell).format('DD MMM YYYY, HH:mm:ss'),
    }, {
      title: '',
      width: '15%',
      dataIndex: ['uuid'],
      render: (cell, row) => (
        <Flex
          style={{
            width: '27px !important',
            height: '27px !important',
            border: '1px solid blue',
          }}
          className="w-max h-max p-0.5 !rounded-full cursor-pointer !border !border-blue-500 !text-blue-500"
        >
          <Icon path={mdiMagnify} size={1} />
        </Flex>
      ),

    }];

  const initFunc = async () => {
    if (partitionUUID || activeDepartment) {
      setIsFetching(true);

      const res = await getListOfLogs({
        parent: activeDepartment || partitionUUID,
        affixTypes: allLogTypes,
        ...config,
      });

      setLogs(res?.data ?? []);
      setTotal(res?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    initFunc();
  }, [
    activeDepartment,
    partitionUUID,
    JSON.stringify(config),
  ]);

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <Flex
      className="w-full h-full"
    >
      <Flex vertical className="w-1/3">
        <DepartmentsList
          hideAddButton
          hideKPIButton
          hideExpand
          hiddenColumns={['kpi', 'created']}
          name="department_list"
          currentDepartment={department}
        />
      </Flex>
      <Card
        className="w-2/3 ml-6 p-0 pb-3 h-max orange-base-card"
        title={(
          <Flex
            align="center"
          >
            <Flex
              className="text-xl"
            >
              {department && activeDepartment ? `Логи ${department?.params?.title}` : 'Все логи'}
            </Flex>
          </Flex>
)}
      >
        <BaseTableWithPagination
          columns={columns}
          data={logs}
          rowKey="uuid"
          size="small"
          className="w-full h-full !mb-3"
    //   onRow={onRow}
          pageSizeOptions={[20, 50, 100]}
    //   rowClassName={rowClassName}
          useCustomPagination
          disablePagination={false}
          total={total}
          pageLimit={get(config, 'limit', 10)}
          changePageLimit={(newLimit) => {
            setCurrentPage(1);
            setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
          }}
          newCurrentPage={currentPage}
          onChangePageCallback={(newPage) => {
            setCurrentPage(newPage);
            setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
          }}
          getPaginationOptions={(newPart) => {
            const valueSearch = get(newPart, 'search', '');

            if (valueSearch) {
              setConfig((prev) => ({
                ...prev,
                ...omit(newPart, ['search']),
                searchData: {
                  value: valueSearch,
                  ignore_case: true,
                  fields: { params: ['title', 'id'] },
                },
              }));
            } else {
              setConfig((prev) => ({ ...omit(prev, 'searchData'), ...newPart }));
            }
          }}
        />
      </Card>

    </Flex>
  );
}
