import React, { useState } from 'react';
import { bool, string } from 'prop-types';

import { Button, Modal, Typography } from 'antd';
import { mdiCancel } from '@mdi/js';
import Icon from '@mdi/react';
import useEmployeeRequestAPI from '../../../api/useEmployeeRequestAPI';

const { Text } = Typography;

export default function CancelRequest({ uuid, requestStatus, isDepartmentRoutes }) {
  const { editEmployeeRequest } = useEmployeeRequestAPI();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const handleOk = () => {
    const data = {
      entity_type: 'employeeRequest',
      params: {
        status: 'rejected',
      },
    };
    editEmployeeRequest(uuid, data);
    closeModal();
  };

  return (
    <>
      <Button
        onClick={openModal}
        className="flex items-center"
        danger
        ghost
        disabled={requestStatus !== 'pending' || isDepartmentRoutes}
      >
        <Icon path={mdiCancel} className="mr-2" size={0.8} />
        Отклонить
      </Button>
      <Modal
        title="Отклонить заявку на нового сотрудника?"
        open={isModalOpen}
        onCancel={closeModal}
        destroyOnClose
        width={750}
        onOk={handleOk}
      >
        <Text>Вы уверены, что хотите отклонить заявку?</Text>
      </Modal>
    </>
  );
}

CancelRequest.propTypes = {
  uuid: string.isRequired,
  requestStatus: string.isRequired,
  isDepartmentRoutes: bool.isRequired,
};
