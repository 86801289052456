import React from 'react';
import { Card, Flex } from 'antd';
import { bool } from 'prop-types';
import EmployeeRequestsTable from './EmployeeRequestsTable';

function EmployeeRequestsPage({ isDepartmentRoutes = false }) {
  return (
    <Card
      className="w-full h-max p-0 pb-2 blue-base-card"
      title={(
        <Flex
          className="text-xl"
        >
          Заявки на сотрудников
        </Flex>
      )}
    >
      <EmployeeRequestsTable isDepartmentRoutes={isDepartmentRoutes} />
    </Card>
  );
}

export default EmployeeRequestsPage;

EmployeeRequestsPage.propTypes = {
  isDepartmentRoutes: bool,
};

EmployeeRequestsPage.defaultProps = {
  isDepartmentRoutes: false,
};
