import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import {
  Flex, Spin, Typography,
} from 'antd';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';
import CreateEmployeeRequestButton from './CreateEmployeeRequestButton';
import { getDepartmentEmployeeRequestsList, getIsFetchingEmployeeRequest } from '../../../../redux/employeeRequest/employeeRequestSelector';

import { formatDate } from '../../../../utils/dateTimeUtils';
import EmployeeRequestDetails from './EmployeeRequestDetails';
import EmployeeRequestStatus from '../common/EmployeeRequestStatus';
import BaseTable from '../../../../w54_components/BaseTable/BaseTable';
import useEmployeeRequestAPI from '../../../../api/useEmployeeRequestAPI';

const { Text } = Typography;

export default function EmployeeRequestsPanel({ departmentUUID }) {
  const { getEmployeeRequestsList } = useEmployeeRequestAPI();

  const [selectedRequest, setSelectedRequest] = useState({});

  const employeeRequestsList = useSelector(getDepartmentEmployeeRequestsList);
  const isFetching = useSelector(getIsFetchingEmployeeRequest);

  const onRow = (request) => ({
    onClick: () => {
      setSelectedRequest({ ...request });
    },
  });

  const columns = [
    {
      title: 'Создал',
      key: 'uinfo',
      dataIndex: ['uinfo'],
      width: '30%',
      render: (cell) => (
        <Text>
          {`${cell.first_name} ${cell.last_name}`}
        </Text>
      ),
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: ['params', 'status'],
      width: '20%',
      render: (status) => (
        <EmployeeRequestStatus status={status} />
      ),
    },
    {
      title: 'Создана',
      key: 'date',
      dataIndex: ['created'],
      width: '30%',
      render: (cell) => (
        <Text>
          { formatDate(cell) }
        </Text>
      ),
    },
  ];

  useEffect(() => {
    if (departmentUUID) {
      getEmployeeRequestsList(departmentUUID);
    }
  }, [departmentUUID]);

  return (
    <Flex
      vertical
      className="w-full h-100"
    >
      <Flex
        className="my-2"
      >
        <CreateEmployeeRequestButton
          departmentUUID={departmentUUID}
        />
      </Flex>
      <Spin
        className="w-full h-100"
        spinning={isFetching}
      >
        <BaseTable
          data={employeeRequestsList}
          columns={columns}
          rowClassName="cursor-pointer"
          size="small"
          rowKey="uuid"
          onRow={onRow}
          className={cn('w-full h-full !mb-3')}
          useCustomPagination
          disablePagination
        />
      </Spin>
      { !isEmpty(selectedRequest)
      && (
      <EmployeeRequestDetails
        isOpen={!isEmpty(selectedRequest)}
        onCloseModal={() => setSelectedRequest({})}
        request={selectedRequest}
      />
      )}
    </Flex>
  );
}

EmployeeRequestsPanel.propTypes = {
  departmentUUID: string.isRequired,
};
