import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { head } from 'lodash';
import useAPI from '../api/useAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import { getProfileUUID } from '../redux/profile/selectors';
import { SET_ROOT_USER } from '../redux/storages/storagesSlice';

export default function useUserRootEntity(isUpdateRequired = true, {
  defaultDepartmentUUID,
} = {}) {
  const dispatch = useDispatch();

  const actor = useSelector(getProfileUUID);
  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    getRootUser,
    readRootUser,
  } = useAPI();

  const initFunc = async () => {
    const res = await readRootUser();
    if (res?.data?.length) {
      dispatch(SET_ROOT_USER(head(res?.data)));
      return;
    }
    getRootUser(actor, defaultDepartmentUUID);
  };

  useEffect(() => {
    if (partitionUUID && actor && defaultDepartmentUUID && isUpdateRequired) {
      initFunc();
    }
  }, [partitionUUID, JSON.stringify(actor), defaultDepartmentUUID, isUpdateRequired]);

  return null;
}
