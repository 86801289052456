import React from 'react';
import { Flex } from 'antd';

import {
  AutoSizer, List,
} from 'react-virtualized';
import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { array, bool, func } from 'prop-types';
import cn from 'classnames';

export default function ListOfCurrentUsers({
  users = [],
  onUserChange,
}) {
  const rowRenderer = ({ index, style, isScrolling }) => {
    if (users[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      label, actor,
    } = users[index];

    return (
      <Flex
        key={actor}
        className={cn('py-3 mr-2 w-full')}
        onClick={() => onUserChange(users[index])}
        style={{
          ...style,
          width: 397,
          borderBottom: '1px solid #D3D3D3',
        }}
      >
        <Flex
          vertical
        >
          <Flex
            className="text-red-600 bg-white h-full hover:text-white hover:bg-red-600 rounded-full !p-0 mr-3 ml-3 cursor-pointer"
            style={{
              border: '1px solid red',
              height: 22,
              width: 22,
            }}
          >
            <Icon path={mdiChevronLeft} size={1} />
          </Flex>
        </Flex>
        <Flex
          style={{
            maxWidth: 380,
          }}
        >
          {label.length > 30 ? `${label.slice(0, 30)}...` : label}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      vertical
      className="rounded"
      style={{
        height: 420,
        width: 400,
        border: '1px dashed gray',
      }}
    >
      <AutoSizer
        className="w-100 h-full mb-2 pb-6"
      >
        {({ width, height }) => (
          <List
            height={405}
            rowHeight={45}
            width={width}
            rowCount={users?.length || 0}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Flex>
  );
}

ListOfCurrentUsers.propTypes = {
  users: array,
  onUserChange: func,
};
