import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Col, Flex, Row } from 'antd';
import cn from 'classnames';
import { isWorkingDay, translateTypeDay } from '../../../user/dayOff/utils';
import { checkIsDayOff } from '../../../../utils/commonUtils';
import { convertTimeFormatToHours } from '../department/utils';

const isDayOfMonth = (dateStr, month) => {
  const date = dayjs(dateStr);
  const startOfMonth = dayjs(month).startOf('month');
  const endOfMonth = dayjs(month).endOf('month');

  return date.isBetween(startOfMonth, endOfMonth, null, '[]');
};

const isWeekend = (dateStr) => {
  const date = dayjs(dateStr);
  const dayOfWeek = date.day();
  return dayOfWeek === 0 || dayOfWeek === 6;
};

function DaysCalendar({ data = [], month }) {
  const startOfMonth = dayjs(month).startOf('month');
  const endOfMonth = dayjs(month).endOf('month');

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const weeksCount = endOfMonth.week() - startOfMonth.week() + 1;
  console.log(data);
  const weeks = Array.from({ length: weeksCount }, (_, weekIndex) => {
    const startOfWeek = startOfMonth.add(weekIndex, 'week').startOf('week');

    return {
      week: weekIndex + 1,
      days: Array.from({ length: 7 }, (a, dayIndex) => {
        if (isDayOfMonth(startOfWeek.add(dayIndex, 'day').format('YYYY-MM-DD'), month)) {
          return startOfWeek.add(dayIndex, 'day').format('YYYY-MM-DD');
        }
        return null;
      }),
    };
  });

  return (
    <Flex vertical className="w-full -mt-4">
      <Row gutter={0} className="w-full -mb-3">
        {daysOfWeek.map((day, index) => (
          <Col className="ml-2 mb-2" key={index} span={3} style={{ textAlign: 'center', padding: '5px' }}>
            <div
              style={{
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {day}
            </div>
          </Col>
        ))}
      </Row>
      {weeks.map((week, weekIndex) => (
        <Row className="w-full" key={weekIndex} gutter={[16, 16]}>
          {week.days.map((day, dayIndex) => {
            const currentDay = data?.find?.((el) => el?.rangeDates?.[0] === day);
            const type = currentDay?.type;

            const isWorkDay = isWorkingDay(type);
            const isDayOff = type === 'dayoff'
            || type === 'localHoliday'
            || type === 'projectDayOff'
            || type === 'sickday'
            || type === 'holidays'
            || type === 'vacation';
            const isBench = type === 'bench_1_month'
            || type === 'bench_over_1_month'
            || type === 'bench'
            || type === 'bench_over_3_month';

            return (
              <Col
                key={dayIndex}
                className={cn(
                  'h-full flex mr-2 mb-2',
                )}
                span={3}
                style={{ padding: '0' }}
              >
                <Flex
                  className={cn(
                    'w-full border border-gray-500',
                    {
                      'bg-gray-200': type === 'report_not_sent' || !type || isWeekend(day),
                      'bg-red-200': isDayOff,
                      'bg-orange-200': isBench,
                      'bg-sky-200': isWorkDay,
                      '!bg-white': !day,
                    },
                  )}
                  style={{
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  {day ? (
                    <div className="inline day_calendar z-20 text-xs mt-1">
                      {dayjs(day).format('DD')}
                    </div>
                  ) : null}
                  <Flex
                    vertical
                    className={cn('text-xs px-1', {
                      'text-gray-500': type === 'report_not_sent' || !type || isWeekend(day),
                      'text-red-500': isDayOff,
                      'text-orange-500': isBench,
                      'text-sky-500': isWorkDay,
                    })}
                  >
                    <span>
                      {checkIsDayOff(day) && (type === 'report_not_sent' || !type) ? 'Выходной' : (day && translateTypeDay[type || 'report_not_sent'])}
                    </span>
                    <span>
                      {isWorkDay && convertTimeFormatToHours(currentDay?.hoursWorked)}
                    </span>
                  </Flex>
                </Flex>
              </Col>
            );
          })}
        </Row>
      ))}
    </Flex>
  );
}

export default DaysCalendar;
