import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import {
  Button, Row, Space, Table, Tag, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import { mdiChartGantt, mdiCheckbook } from '@mdi/js';
import dayjs from 'dayjs';
import {
  array, bool, func, object,
} from 'prop-types';
import { getFetchingPublicUsersEntities } from '../../../../redux/publicUserEntity/selectors';
import UserAvatar from '../../../../components/UserAvatar';
import { getInterfaceWms } from '../../../../redux/publicInterfaces/selectors';
import AccessSwith from './AccessSwith';
import { getInitialsNameOfActor, getNameForActor } from '../../../../54origins/utils54origins';

export default function UsersTable({
  customFetching,
  dataSource,
  listPerms,
  updatePermCallback,
}) {
  const history = useHistory();

  const fetching = useSelector(getFetchingPublicUsersEntities);

  const renderActions = (cell, row) => (
    <Space>
      <Button
        className="flex items-center"
        onClick={() => history.push(`/userdashboard/dashboard?actor=${cell}`)}
      >
        <Icon path={mdiChartGantt} className="mr-2" size={0.8} />
        {capitalize('Reports дашборд')}
      </Button>
    </Space>
  );

  const columns = [
    {
      dataIndex: ['actor'],
      render: (cell, row) => (
        <UserAvatar
          customInitials={getInitialsNameOfActor(row)}
          className="avatar-my"
          userUUID={cell}
          size={35}
        />
      ),
    },
    {
      title: 'Имя',
      dataIndex: ['uinfo'],
      width: 200,
      render: (cell, row) => {
        const fullName = getNameForActor(row);
        return (fullName?.length >= 60 ? (
          <Tooltip
            style={{ width: 200 }}
            title={fullName}
          >
            {fullName.slice(0, 57)}
            ...
          </Tooltip>
        ) : fullName);
      },
    },
    {
      dataIndex: 'actor_type',
      title: 'Тип',
      render: (cell, row) => {
        const actorPerm = listPerms.get(row?.actor);
        // console.log('row', row);
        return (
          <div className="d-flex align-items-center">
            <Tag
              color="blue"
              className="fontSize10 mr-1"
            >
              {capitalize(row?.entity_type === 'classic_user' ? 'classic' : row?.entity_type)}
            </Tag>

            {actorPerm && actorPerm?.is_admin && (
            <Tag
              color="red"
              className="fontSize10"
            >
              ADMIN
            </Tag>
            )}
            {actorPerm && actorPerm?.is_root && (
            <Tag
              color="purple"
              className="fontSize10"
            >
              ROOT
            </Tag>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: 'initials',
      title: 'Доступ к статистике всех пользователей',
      render: (cell, row) => {
        const actorPerm = listPerms.get(row?.actor);
        // console.log('row', actorPerm);
        return (
          <AccessSwith
            actorPerm={actorPerm}
            fullName={getNameForActor(row)}
            actor={row?.actor}
            updateCallback={updatePermCallback}
          />
        );
      },
    },
    {
      title: 'Действия',
      dataIndex: ['actor'],
      render: renderActions,
    },
  ];

  // console.log('listPerms',listPerms)

  return (
    <Table
      rowKey={(row) => row.actorUuid}
      columns={columns}
      loading={fetching || customFetching}
      dataSource={dataSource}
      size="small"
      pagination={false}
    />
  );
}

UsersTable.propTypes = {
  dataSource: array,
  listPerms: object,
  customFetching: bool,
  updatePermCallback: func,
};
