import React, { useEffect, useState } from 'react';

import { trim } from 'lodash';
import { bool, func, string } from 'prop-types';

import {
  Button, Flex, Input, Typography,
} from 'antd';

import EditButton from './EditButton';

const { TextArea } = Input;

const { Text } = Typography;

export default function EntityDescriptionForCard({
  title = 'Описание',
  useEllipsis = false,
  className = '',
  hideEdit = false,
  isGlobalEdit,
  onChangeValue,
  field = 'description',
  description = '',
  onEditCallback,
}) {
  const [isEdit, setIsEdit] = useState(false);

  const [descriptionValue, setDescriptionValue] = useState(description);

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const onChangeDesc = (e) => {
    setDescriptionValue(e.target.value);
    if (onChangeValue) {
      onChangeValue?.(e.target.value);
    }
  };

  const toggleBtn = (e, closeEdit) => {
    if (closeEdit || !isEdit) {
      setDescriptionValue(description);
    }
    if (isEdit && trim(descriptionValue) !== description && !closeEdit) {
      onEditCallback({ [field]: descriptionValue });
    }
    setIsEdit((prev) => !prev);
  };

  useEffect(() => {
    setDescriptionValue(description);
  }, [isGlobalEdit, description]);

  return (
    <Flex
      vertical
      className={className}
    >
      <Flex>
        <span
          className="mr-2 text-base"
        >
          {title}
          :
        </span>
        {!hideEdit && (
        <EditButton
          id="editDescriptionButton"
          isEditFlag={isEdit}
          onClick={toggleBtn}
        />
        )}
      </Flex>
      {(isEdit && !hideEdit) || isGlobalEdit ? (
        <TextArea
          style={{ height: '250px' }}
          id="department_input_description"
          className="text-base mt-1"
          placeholder="Enter description"
          maxLength={5000}
          onChange={onChangeDesc}
          value={descriptionValue}
        />
      ) : (
        <Text
            // className="text-blue-500"
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {description?.length > 160 && !expanded && useEllipsis
            ? `${description?.slice(0, 160)}...` : description}
          {useEllipsis && description?.length > 150 && (
          <Text
            className="ml-2 text-blue-500 cursor-pointer"
            onClick={toggleExpand}
          >
            {expanded ? 'Скрыть' : 'Показать больше'}
          </Text>
          )}
        </Text>
      )}
    </Flex>
  );
}

EntityDescriptionForCard.propTypes = {
  title: string,
  field: string,
  hideEdit: bool,
  className: string,
  description: string,
  onEditCallback: func,
};
