/* eslint-disable default-param-last */

import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import useLogsAPI from './useLogsAPI';
import antNotification from '../utils/antNotification';

export default function useArtifactsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfArtifacts = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_uuid: entityUUIDs,
            entity_type: ['instruction', 'file', 'schedule', 'user'],
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_ARTIFACTS_REQUEST',
            'READ_LIST_OF_ARTIFACTS_SUCCESS',
            'READ_LIST_OF_ARTIFACTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список артефактов!');
      return null;
    }
  };

  const getListOfArtifacts = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: ['instruction', 'file', 'schedule', 'user'],
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_ARTIFACTS_REQUEST',
            'GET_LIST_OF_ARTIFACTS_SUCCESS',
            'GET_LIST_OF_ARTIFACTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список артефактов!');
      return null;
    }
  };

  const createArtifact = async (parent, data, constants) => {
    try {
      const newArtifactConfig = {
        parent,
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(newArtifactConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const newArtifact = await dispatch(
        entityCreate({
          data: newFormData,
          constants: constants || [
            'CREATE_ARTIFACT_REQUEST',
            'CREATE_ARTIFACT_SUCCESS',
            'CREATE_ARTIFACT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newArtifact, '[0].uuid', '')) {
        createLog({
          parent: get(newArtifact, '[0].uuid', ''),
          type: 'CREATE',
          entityType: data?.entity_type,
          values: get(newArtifact, '[0].params', ''),
          files: data?.files || [],
        });
      }

      return newArtifact;
    } catch (e) {
      antNotification.error('Не удалось создать артефакт!');
      return null;
    }
  };

  const updateArtifact = async (entityUUID, data, constants) => {
    try {
      const updateArtifactConfig = {
        entity_uuid: entityUUID,
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(updateArtifactConfig));

      if (data?.files?.length !== 0) {
        for (let i = 0; i < data?.files?.length; i += 1) {
          newFormData.append('file', data?.files?.[i]);
        }
      }

      const updatedArtifact = await dispatch(
        entityUpdate({
          data: newFormData,
          constants: constants || [
            'UPDATE_ARTIFACT_REQUEST',
            'UPDATE_ARTIFACT_SUCCESS',
            'UPDATE_ARTIFACT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
      if (get(updatedArtifact, '[0].uuid', '')) {
        createLog({
          parent: get(updatedArtifact, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: data?.entity_type,
          values: get(data, 'params', {}),
          files: data?.files || [],
        });
      }

      return updatedArtifact;
    } catch (e) {
      antNotification.error('Не удалось изменить артефакт!');
      return null;
    }
  };

  const deleteArtifact = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_type: ['instruction', 'file', 'schedule', 'user'],
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'DELETE_ARTIFACT_REQUEST',
            'DELETE_ARTIFACT_SUCCESS',
            'DELETE_ARTIFACT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить артефакт!');
      return null;
    }
  };

  return {
    createArtifact,
    getListOfArtifacts,
    updateArtifact,
    deleteArtifact,
    readListOfArtifacts,
  };
}
