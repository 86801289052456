import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'prop-types';

import {
  Button, Card, Col, Flex, Form, Input, Modal, Row, Spin,
  Table,
} from 'antd';

import cn from 'classnames';
import { capitalize, get, head } from 'lodash';
import dayjs from 'dayjs';

import TextArea from 'antd/es/input/TextArea';
import { mdiArrowLeftBoldBox, mdiArrowRightBoldBox, mdiArrowRightBoldCircle } from '@mdi/js';
import Icon from '@mdi/react';
import useAPI from '../../../api/useAPI';

import {
  entityCreate, entityUpdate, getListAndReadEntities, getListOfEntities, getOrCreateSingle,
} from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { compareActorUUIDs } from './department/utils';
import { columnsForContractorTable, columnsForDepartmentReportTable } from './utils';
import { getMD5HashFromString } from '../../../utils';
import useLogsAPI from '../../../api/useLogsAPI';
import { getProfileUUID } from '../../../redux/profile/selectors';
import DepartmentReportLogsModal from '../../../components/logs/MonthlyReportLogsModal';

export default function DepartmentReports({
  department = null,
  departmentUUID = '',
  className = 'mt-2',
}) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    getMonthlyReport,
  } = useAPI();

  const {
    createLog,
  } = useLogsAPI();

  const [dataTable, setDataTable] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [usersWithReports, setUsersWithReports] = useState([]);
  const [isFetchingUserReports, setIsFetchingUserReports] = useState(true);

  const [currentMonth, setCurrentMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));

  const [selectedRowActors, setSelectedRowActors] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const [historyUUID, setHistoryUUID] = useState('');

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => {
    setCurrentMonth(dayjs().subtract(1, 'month').format('YYYY-MM'));
    setSelectedRowActors([]);
    form.resetFields();
    setIsOpen(false);
  };

  const handlePrevMonth = () => {
    setCurrentMonth(dayjs(currentMonth).subtract(1, 'month').format('YYYY-MM'));
  };

  const handleNextMonth = () => {
    setCurrentMonth(dayjs(currentMonth).add(1, 'month').format('YYYY-MM'));
  };

  const onRow = (record) => ({
    onClick: () => {
      const actor = get(record, 'actor', '');

      if (selectedRowActors?.includes(actor)) {
        setSelectedRowActors((prev) => prev?.filter((el) => el !== actor));
      } else {
        setSelectedRowActors((prev) => ([...prev, actor]));
      }
    },
  });

  const onReportRow = (record) => ({
    onClick: () => {
      setHistoryUUID(record?.uuid);
    },
  });

  const initFunc = async () => {
    setIsFetching(true);

    const res = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'departmentReport',
          parent: departmentUUID,
          depth: 0,
        },
        constants: [
          'GET_LIST_OF_REPORTS_REQUEST',
          'GET_LIST_OF_REPORTS_SUCCESS',
          'GET_LIST_OF_REPORTS_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    setDataTable(res?.data ?? []);

    setIsFetching(false);
  };

  const initUsersAndReports = async () => {
    setIsFetchingUserReports(true);

    const resUsers = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    const users = resUsers?.data ?? [];

    if (!users?.length) {
      setUsersWithReports([]);
      setIsFetchingUserReports(false);
      return;
    }

    const res = await getMonthlyReport(departmentUUID, {
      depth: 0,
      params: {
        status: ['auto', 'submitted'],
        date: [currentMonth],
      },
    });

    const reports = res?.data ?? [];

    setUsersWithReports(users.map((user, index) => {
      const report = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === currentMonth);

      return report ? {
        // ...user,
        key: `${index + 1}`,
        hasReport: true,
        user,
        ...report,
      } : {
        hasReport: false,
        actor: user?.actor,
        user,
      };
    }));

    setIsFetchingUserReports(false);
  };

  const reportData = useMemo(() => {
    const findUserReport = (uuid) => usersWithReports
      ?.find((el) => el?.actor === uuid && el?.hasReport) || null;

    const getCount = (type) => selectedRowActors
      ?.reduce((acc, uuid) => acc + +(findUserReport(uuid)?.params?.statistics?.[type] || 0), 0);

    return {
      totalWorks: getCount('totalWorks'),
      clientWork: getCount('clientWork'),
      internalWork: getCount('internalWork'),
      totalBenches: getCount('totalBenches'),
      totalDayOff: getCount('totalDayOff'),
      dayoff: getCount('dayoff'),
      sickday: getCount('sickday'),
      vacation: getCount('vacation'),
    };
  }, [
    JSON.stringify(selectedRowActors),
    JSON.stringify(usersWithReports),
  ]);

  const onCreateReport = async (values) => {
    const res = await dispatch(
      getOrCreateSingle({
        data: {
          entity_type: 'departmentReport',
          parent: departmentUUID,
          key: getMD5HashFromString(`departmentReport${departmentUUID}${currentMonth}`),
        },
        constants: [
          'CREATE_DEPARTMENT_REPORT_REQUEST',
          'CREATE_DEPARTMENT_REPORT_SUCCESS',
          'CREATE_DEPARTMENT_REPORT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    if (res?.uuid) {
      await dispatch(
        entityUpdate({
          data: {
            entity_type: 'departmentReport',
            entity_uuid: res?.uuid,
            parent: departmentUUID,
            params: {
              wasCreated: true,
              statistics: values,
              department: departmentUUID,
              // contractors: selectedRowActors,
              date: currentMonth,
            },
          },
          constants: [
            'UPDATE_DEPARTMENT_REPORT_REQUEST',
            'UPDATE_DEPARTMENT_REPORT_SUCCESS',
            'UPDATE_DEPARTMENT_REPORT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    }

    createLog({
      parent: res?.uuid,
      type: res?.params?.wasCreated ? 'UPDATE' : 'CREATE',
      entityType: 'departmentReport',
      values,
    });
    initFunc();
    onCloseModal();
  };

  useEffect(() => {
    if (isOpen && departmentUUID && currentMonth) {
      initUsersAndReports();
    }
  }, [isOpen, departmentUUID, currentMonth]);

  useEffect(() => {
    if (departmentUUID) {
      initFunc();
    }
  }, [departmentUUID]);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        totalWorks: reportData.totalWorks,
        clientWork: reportData.clientWork,
        internalWork: reportData.internalWork,
        totalBenches: reportData.totalBenches,
        totalDayOff: reportData.totalDayOff,
        dayoff: reportData.dayoff,
        sickday: reportData.sickday,
        vacation: reportData.vacation,
        comment: '',
      });
    }
  }, [isOpen, reportData, form]);

  return (
    <>
      <Card
        title={(
          <Flex
            className="text-xl"
            align="center"
          >
            Отчеты
            <Button
              size="small"
              onClick={onOpenModal}
              className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
            >
              Сформировать отчет
            </Button>
          </Flex>
      )}
        className={cn(`w-full h-max p-0 ${className}`, {
          'blue-base-card': true,
        })}
      >
        <Spin
          spinning={isFetching}
          className="w-full"
        >
          <Table
            dataSource={dataTable}
            columns={columnsForDepartmentReportTable}
            size="small"
            onRow={onReportRow}
            rowKey="uuid"
            rowClassName="cursor-pointer"
            className={cn('w-full h-full !mb-3')}
            pagination={false}
          />
        </Spin>
      </Card>

      {historyUUID && (
      <DepartmentReportLogsModal
        entityUUID={historyUUID}
        isOpen={historyUUID}
        onClose={() => setHistoryUUID('')}
      />
      )}

      {isOpen && (
      <Modal
        open={isOpen}
        title={(
          <Flex
            align="center"
            className="text-xl"
          >
            <span>
              Формирование отчета за
            </span>
            <Icon
              className="mx-2 cursor-pointer text-blue-500"
              onClick={handlePrevMonth}
              path={mdiArrowLeftBoldBox}
              size={1.2}
            />
            <Flex
              justify="center"
              className="text-xl text-blue-500"
              style={{
                width: 130,
              }}
            >
              {capitalize(dayjs(currentMonth).format('MMMM YYYY'))}
            </Flex>
            <Icon
              className="ml-2 cursor-pointer text-blue-500"
              onClick={handleNextMonth}
              path={mdiArrowRightBoldBox}
              size={1.2}
            />
          </Flex>
)}
        onCancel={onCloseModal}
        footer={null}
        centered
        width={1200}
        destroyOnClose
      >
        <Form
          preserve={false}
          form={form}
          name="report"
          autoComplete="off"
          layout="vertical"
          onFinish={onCreateReport}
        >
          <Flex
            vertical
          >
            <Row gutter={[24, 0]}>
              <Col span={8}>
                <Form.Item
                  name="totalWorks"
                  label={<span className="text-lg">Общие проектные часы</span>}
                  rules={[{
                    required: true,
                    pattern: /^\.?(?!-)\d+(?:\.\d{1,2})?$/,
                    message: 'Поле является обязательным. Значение может быть целым или дробным (от одного до двух символов после точки).',
                    warningOnly: false,
                  }]}
                  normalize={(value) => value.replace(/[^\d\.]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="clientWork"
                  label={<span className="text-lg">Часы на клиентском проекте:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="internalWork"
                  label={<span className="text-lg">Часы на внутреннем проекте:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="dayoff"
                  label={<span className="text-lg">Количество часов отгула:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="sickday"
                  label={<span className="text-lg">Количество больничных часов:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="vacation"
                  label={<span className="text-lg">Количество часов отпуска:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="totalBenches"
                  label={<span className="text-lg">Количество часов на бенче:</span>}
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col span={24} className="-mt-2">
              <Form.Item
                name="comment"
                label={<span className="text-lg">Комментарий:</span>}
                style={{ marginBottom: 12 }}
              >
                <TextArea
                  maxLength={5000}
                  placeholder="Введите комментарий"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Flex
              align="center"
              className="mb-2 mt-2"
            >
              <span className="text-lg -mt-1">
                Отчеты подрядчиков
              </span>
              <Button
                size="small"
                onClick={() => setSelectedRowActors(usersWithReports?.map((el) => el?.actor))}
                disabled={usersWithReports?.length === selectedRowActors?.length}
                className={cn('!border-blue-500 ml-2 text-blue-500 hover:bg-blue-500 hover:!text-white', {
                  '!border-gray-200 text-gray-200 hover:bg-gray-200 hover:!text-white': usersWithReports?.length === selectedRowActors?.length,
                })}
              >
                Выбрать всех
              </Button>
              <Button
                size="small"
                onClick={() => setSelectedRowActors([])}
                disabled={selectedRowActors?.length === 0}
                className={cn('!border-blue-500 ml-2 text-blue-500 hover:bg-blue-500 hover:!text-white', {
                  '!border-gray-200 text-gray-200 hover:bg-gray-200 hover:!text-white': selectedRowActors?.length === 0,
                })}
              >
                Очистить выбранных
              </Button>
            </Flex>
            <Spin
              spinning={isFetchingUserReports}
            >
              <Table
                dataSource={usersWithReports}
                columns={columnsForContractorTable}
                scroll={{ y: 550 }}
                onRow={onRow}
                size="small"
                rowKey="actor"
                rowClassName={(row) => cn('cursor-pointer !hover:bg-sky-100', {
                  '!bg-sky-100': selectedRowActors?.includes(row?.actor),
                  'bg-red-50': !row?.hasReport,
                })}
                className={cn('w-full h-full !mb-3')}
                style={{
                  border: '1px solid #F5F5F5',
                }}
                pagination={false}
              />
            </Spin>
          </Flex>
          <Flex
            justify="end"
          >
            <Form.Item
              shouldUpdate
              className="mt-3 !mb-0"
              style={{
                display: 'flex', width: '100%', justifyContent: 'flex-end',
              }}
            >
              <Button
                className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
                onClick={onCloseModal}
              >
                Отмена
              </Button>
              <Button
                htmlType="submit"
                className={cn('!border-green-500 text-green-500 hover:bg-green-500 hover:!text-white', {
                  // 'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white': !trim(title)?.length,
                })}
            // disabled={!trim(title)?.length}
                // onClick={onCreateReport}
              >
                Создать
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Modal>
      )}
    </>
  );
}

DepartmentReports.propTypes = {
  department: object,
  departmentUUID: string,
  className: string,
};
