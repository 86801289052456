import React, {
  useEffect,
  useState,
} from 'react';
import {
  Flex,
  Tabs,
} from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  array, func, object, string,
} from 'prop-types';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import PageWrapper from '../../../components/PageWrapper';
import ArtifactCard from './artifacts/ArtifactCard';
import DepartmentsList from './DepartmentsList';
import DepartmentCard from './DepartmentCard';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT, SET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import {
  GET_SINGLE_ARTIFACT_FAILURE, GET_SINGLE_ARTIFACT_REQUEST, GET_SINGLE_ARTIFACT_SUCCESS, RESET_SINGLE_ARTIFACT,
} from '../../../redux/departments/slices/artifactsSlice';
import ArtifactsList from './artifacts/ArtifactsList';
import PermissionsStartPage from '../../../components/permissions/PermissionsStartPage';
import useArifactsStorage from '../../../redux/departments/hooks/useArifactsStorage';
import { ENABLE_RESET_PROPERTY } from '../../../redux/reset/resetSlice';
import useURLParams from '../../../hooks/useURLParams';
import { getArtifact, getArtifactUUID } from '../../../redux/departments/selectors/artifactsSelector';
import DepartmentReports from './DepartmentReports';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useArtifactsAPI from '../../../api/useArtifactsAPI';

export default function DepartmentRoot() {
  const dispatch = useDispatch();

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const { readListOfArtifacts } = useArtifactsAPI();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  const {
    readListOfDepartments,
  } = useDepartmentsAPI();

  const { uuid: artifactsStorageUUID } = useArifactsStorage(false);

  const [activeKey, setActiveKey] = useState('1');

  const onEditDepCallback = (data) => {
    if (data) {
      dispatch({
        type: SET_SINGLE_DEPARTMENT.type,
        payload: data,
      });
    } else if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    }
  };

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  return (
    <Flex
      justify="space-between"
      className="w-full"
    >
      <Flex
        vertical
        className="w-1/2"
      >
        <DepartmentsList
          name="department_list"
          currentDepartment={department}
        />
        {activeDepartment && (
          <ArtifactsList
            name="artifact_list"
            parent={activeDepartment}
            title="Артефакты"
            showUserCreateButton
            department={department}
            departmentUUID={activeDepartment}
            onCreateArtifactCallback={(uuid) => {
              setSearchParams({ artifact: uuid });
              dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'employees_list_for_department']));
            }}
          />
        )}
        <ArtifactsList
          name="artifact_list_without_department"
          parent={artifactsStorageUUID}
          title="Нет отдела (артефакты)"
          cardClassName="orange-base-card mb-4"
          onCreateArtifactCallback={(uuid) => {
            setSearchParams({ artifact: uuid });
            dispatch(ENABLE_RESET_PROPERTY([
              'artifact_list_without_department',
              'employees_list_for_department',
            ]));
          }}
        />
      </Flex>
      <Flex
        vertical
        className="w-1/2 ml-6 mb-4"
      >
        {activeDepartment && (
          <Flex
            className="mb-6"
            vertical
          >
            <Tabs
              type="card"
              defaultActiveKey="1"
              activeKey={activeKey}
              items={[
                {
                  key: '1',
                  label: 'Отдел',
                },
                {
                  key: '2',
                  label: 'Отчеты',
                },
                {
                  key: '3',
                  label: 'Perms',
                },
              ]}
              onChange={setActiveKey}
              tabBarStyle={{ marginBottom: 0 }}
            />
            {activeKey === '1' && (
              <DepartmentCard
                department={department}
                departmentUUID={departmentUUID}
                className="!mt-2"
                onEditDepartmentCallback={onEditDepCallback}
                onBackDepartmentCallback={() => {
                  clearSearchParams(['department']);
                }}
                onDeleteDepartmentCallback={() => {
                  clearSearchParams(['department']);
                  dispatch(ENABLE_RESET_PROPERTY('department_list'));
                }}
              />
            )}
            {activeKey === '2' && (
              <DepartmentReports
                department={department}
                departmentUUID={departmentUUID}
                className="mt-2"
              />
            )}
            {activeKey === '3' && (
              <PermissionsStartPage
                isModalMode
                entityUUID={departmentUUID}
                partitionType="DELIVERY"
                className="mt-2"
                nameSectionPermissions={`${department?.params?.title} department permissions`}
              />
            )}
          </Flex>
        )}
        {activeArtifact && (
          <ArtifactCard
            artifact={artifact}
            artifactUUID={artifactUUID}
            onBackArtCallback={() => {
              clearSearchParams(['artifact']);
            }}
            onUpdateArtCallback={() => {
              dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
            }}
            onDeleteArtCallback={() => {
              clearSearchParams(['artifact']);
              dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}

DepartmentRoot.propTypes = {
  storageUUID: string,
  departments: array,
  depPaginationOpt: object,
  onReloadDepartments: func,
};
