import React from 'react';

import { Flex, Tag } from 'antd';
import { string } from 'prop-types';

import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline, mdiReload } from '@mdi/js';

export default function LogTagByTypeOfChange({
  type = '',
  className = '',
}) {
  const tagLabels = {
    UPDATE: 'Обновление',
    CREATE: 'Создание',
  };

  const tagIcons = {
    UPDATE: mdiReload,
    CREATE: mdiCheckboxMarkedCircleOutline,
  };

  const tagColors = {
    UPDATE: 'blue',
    CREATE: 'green',
  };

  return type ? (
    <Tag
      className={className}
      bordered={false}
      color={tagColors?.[type]}
    >
      <Flex
        className="h-full"
        align="center"
      >
        <Icon path={tagIcons?.[type]} size={0.6} />
        <span
          className="ml-0.5 text-sm"
        >
          {tagLabels?.[type]}
        </span>
      </Flex>
    </Tag>
  ) : null;
}

LogTagByTypeOfChange.propTypes = {
  className: string,
  type: string,
};
