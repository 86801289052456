import {
  Card, Col, Descriptions, Divider, Flex, Timeline,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  assign, isArray, isBoolean, isObject, reduce, toPairs,
  toString,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { object } from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { allLogTypes, entityNamesForLogs } from './utils/logConstants';
import { getFullNameOrInitials } from '../../utils/utils54origins';
import LogTagByTypeOfChange from './commonComponents/LogTagByTypeOfChange';
import { getDeliveryPartition } from '../../redux/config/selectors';
import useLogsAPI from '../../api/useLogsAPI';

export default function CardOfEntityLogs({
  currentLog = {},
  onSelectLog,
  currentEntity = {},
}) {
  const { getListOfLogs } = useLogsAPI();

  const [currentLogs, setCurrentLogs] = useState([]);

  const getDataFromLog = (obj, prefix = '') => reduce(obj, (acc, value, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (isObject(value) && !isArray(value)) {
      assign(acc, getDataFromLog(value, newKey));
    } else {
      acc[newKey] = value;
    }
    return acc;
  }, {});

  const prepareValueForLog = (value) => {
    if (isArray(value)) {
      return value?.join(', ');
    }

    return toString(value);
  };

  const initCurrentLogs = async () => {
    if (currentEntity?.uuid) {
      const res = await getListOfLogs({
        parent: currentEntity?.uuid,
        affixTypes: allLogTypes,
      });
      setCurrentLogs(res?.data ?? []);
    }
  };

  useEffect(() => {
    initCurrentLogs();
  }, [
    JSON.stringify(currentEntity),
  ]);

  return (
    <Card
      className="w-2/5 ml-6 px-6 h-max"
      title={(
        <Flex
          className="my-2"
          justify="space-between"
          align="center"
        >
          <Flex
            vertical
            className="!font-base text-neutral-600 text-2xl"
          >
            <span>
              {entityNamesForLogs?.[currentLog?.affix_type]}
            </span>
            <Flex
              className="text-neutral-600 text-base"
            >
              Владелец объекта:
              {' '}
              {getFullNameOrInitials(currentEntity, 'fullname')}
            </Flex>
          </Flex>
          <Icon path={mdiClose} size={1} className="cursor-pointer" onClick={() => onSelectLog(null)} />
        </Flex>
        )}
    >
      <Timeline
        className="mt-2"
        items={currentLogs?.map((el) => ({
          ...(el?.uuid === currentLog?.uuid ? ({ dot: <ClockCircleOutlined className="timeline-clock-icon" /> }) : {}),
          color: el?.uuid === currentLog?.uuid ? 'red' : 'blue',
          children: (
            <Flex vertical>
              <Flex align="center">
                <span className="bg-gray-100 text-neutral-600 text-sm !rounded px-2 py-1 mr-4">{dayjs(el?.created).format('YYYY-MM-DD HH:mm:ss')}</span>
                <LogTagByTypeOfChange type={el?.params?.type} />
                <span className="text-neutral-600">
                  Автор:
                  {' '}
                  {getFullNameOrInitials(el, 'fullname')}
                </span>
              </Flex>
              <Descriptions
                className="mt-2"
                column={1}
                size="small"
                bordered
                items={toPairs(getDataFromLog(el?.params?.values)).map(([key, value], index) => ({
                  key: `${index}`,
                  label: key,
                  // children: isObject(value) ? toString(value) : value,
                  children: prepareValueForLog(value),
                })).filter(({ label, children }) => label && children)}
              />
            </Flex>
          ),
        }))}
      />
    </Card>
  );
}

CardOfEntityLogs.propTypes = {
  currentLog: object,
  currentEntity: object,
};
