import React, {
  useCallback, useEffect, useState,
} from 'react';
import { get, isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { array, func, string } from 'prop-types';
import {
  Button, Col, Table, Tooltip,
} from 'antd';
import useAPI from '../../../api/useAPI';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getArrByType, getHours } from '../../user/dayOff/utils';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import useVacationsAPI from '../../../api/useVacationsAPI';
import VacationConstants from '../../../redux/vacations/constants';

export default function StatisticsCard({
  startMonth,
  endMonth,
  setDataTable,
  dataTable,
}) {
  const {
    getListOfMonthlyReports,
  } = useAPI();

  const {
    getListOfVacations,
  } = useVacationsAPI();

  const rootPartitionUuid = useSelector(getDeliveryPartition);

  const [config, setConfig] = useState({
    depth: 0,
  });

  const [fetching, setFetching] = useState(false);

  const [allowGet, setAllowGet] = useState(0);

  const getListAgreedVacations = () => {
    const configRequest = {
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
      ...config,
    };

    return getListOfVacations(rootPartitionUuid, configRequest, [
      VacationConstants.GET_VACATIONS_REQUEST,
      VacationConstants.GET_VACATIONS_SUCCESS,
      VacationConstants.GET_VACATIONS_FAILURE,
    ]);
  };

  const getListOfUserReports = async () => {
    const configRequest = {
      params: {
        date: dayjs(startMonth).format('YYYY-MM'),
      },
      params_fields: {
        days: 'days',
      },
      ...config,
    };

    return getListOfMonthlyReports(
      rootPartitionUuid,
      configRequest,
    );
  };

  const getWorkingDays = ({
    data,
    ...element
  }) => {
    const stat = {
      office_work: 0,
      remote_work: 0,
      work_on_day_off: 0,
      totalWorks: 0,
      sickday: 0,
      holidays: 0,
      vacation: 0,
      dayoff: 0,
      bench_1_month: 0,
      bench_over_1_month: 0,
      bench_over_3_month: 0,
      totalDayOff: 0,
      totalBenches: 0,
    };

    const allDaysWithType = [...getArrByType(data, enumerateDaysBetweenDates(startMonth, endMonth))]
      .map(([key, value]) => ({ ...value }))
      .filter((el) => !dayjs(el?.date).isAfter(dayjs()));

    stat.office_work = getHours(allDaysWithType.filter((item) => item.type === 'office_work'));
    stat.remote_work = getHours(allDaysWithType.filter((item) => item.type === 'remote_work'));
    stat.work_on_day_off = getHours(allDaysWithType.filter((item) => item.type === 'work_on_day_off'));

    stat.totalWorks = stat.office_work + stat.remote_work + stat.work_on_day_off;

    stat.bench_1_month = (allDaysWithType.filter((item) => item.type === 'bench_1_month').length || 0) * 8;
    stat.bench_over_1_month = (allDaysWithType.filter((item) => item.type === 'bench_over_1_month').length || 0) * 8;
    stat.bench_over_3_month = (allDaysWithType.filter((item) => item.type === 'bench_over_3_month').length || 0) * 8;

    stat.totalBenches = stat.bench_1_month + stat.bench_over_1_month + stat.bench_over_3_month;

    stat.sickday = (allDaysWithType.filter((item) => item.type === 'sickday').length || 0) * 8;
    stat.holidays = (allDaysWithType.filter((item) => item.type === 'holidays').length || 0) * 8;
    stat.vacation = (allDaysWithType.filter((item) => item.type === 'vacation').length || 0) * 8;
    stat.dayoff = (allDaysWithType.filter((item) => item.type === 'dayoff').length || 0) * 8;

    stat.totalDayOff = stat.sickday + stat.holidays + stat.vacation
    + stat.dayoff
    + stat.bench_1_month + stat.bench_over_1_month + stat.bench_over_3_month;

    return {
      ...element,
      ...stat,
    };
  };

  const getData = async () => {
    try {
      setFetching(true);
      const data = new Map();
      const result = await Promise.allSettled([
        getListAgreedVacations(),
        getListOfUserReports(),
      ]);

      let currentData = [];

      if (result[0].status === 'fulfilled') {
        currentData = [...currentData, ...get(result, '[0].value.data', [])];
      }

      if (result[1].status === 'fulfilled') {
        const usersDays = get(result, '[1].value.data', []).map((report) => ({
          days: report?.params?.days || [],
          actor: report?.actor,
          uinfo: report?.uinfo,
          type: 'days',
          uuid: report?.uuid,
        }));
        currentData = [...currentData, ...usersDays];
      }

      currentData.forEach((item) => {
        if (!data.has(item.actor)) {
          data.set(
            item.actor,
            {
              actor: item.actor,
              fullName: `${get(item, 'uinfo.first_name')} ${get(item, 'uinfo.last_name')}`,
              uinfo: item.uinfo,
              data: item?.type === 'days' ? item.days : [item],
            },
          );
        } else {
          const userWithData = data.get(item.actor);
          if (item?.type === 'days') {
            item?.days?.forEach((day) => userWithData?.data?.push?.(day));
          } else {
            userWithData?.data?.push?.(item);
          }
        }
      });

      const preparedData = Array.from(data.values()).map(getWorkingDays);
      setDataTable(preparedData);
    } catch (e) {
      console.log('Ошибка!');
    }
    setFetching(false);
  };

  const searchInTitle = (
    <Col className="d-flex align-item-end" span={24}>
      <AntDesignSearchBox54origins
        onSearch={(value) => setConfig((prev) => ({
          ...prev,
          search_data: {
            fields: {
              uinfo: {
                first_name: 'first_name',
                last_name: 'last_name',
              },
            },
            value,
          },
        }))}
        maxWidth={200}
      />
    </Col>
  );

  const getName = (fullName) => {
    if (fullName.length > 22) {
      return (
        <Tooltip
          title={fullName}
        >
          {fullName.slice(0, 20)}
          ...
        </Tooltip>
      );
    }
    return fullName;
  };

  const columns = [
    {
      title: searchInTitle,
      dataIndex: ['uinfo'],
      width: 200,
      render: (uinfo) => getName(`${uinfo?.first_name} ${uinfo?.last_name}`),
    },
    {
      title: 'Проектных часов',
      dataIndex: ['totalWorks'],
      className: '!bg-green-200',
    },
    {
      title: 'Бенчи',
      dataIndex: ['totalBenches'],
      className: '!bg-orange-200',
    },
    {
      title: 'Нерабочих часов',
      dataIndex: ['totalDayOff'],
      className: '!bg-red-200',
    },
    // {
    //   title: 'Работа в офисе',
    //   dataIndex: ['office_work'],
    // },
    // {
    //   title: 'Работа удаленно',
    //   dataIndex: ['remote_work'],
    //   className: hiddenColumn(),
    // },
    // {
    //   title: 'Работа в выходной',
    //   dataIndex: ['work_on_day_off'],
    //   className: hiddenColumn(),
    // },
    {
      title: 'Отгул',
      dataIndex: ['dayoff'],
      // className: hiddenColumn(),
    },
    {
      title: 'Больничный',
      dataIndex: ['sickday'],
      // className: hiddenColumn(),
    },
    {
      title: 'Бенч 1 месяц',
      dataIndex: ['bench_1_month'],
      // className: hiddenColumn(),
    },
    {
      title: 'Бенч больше месяца',
      dataIndex: ['bench_over_1_month'],
      // className: hiddenColumn(),
    },
    {
      title: 'Бенч больше 3-х месяцев',
      dataIndex: ['bench_over_3_month'],
      // className: hiddenColumn(),
    },
    {
      title: 'Отпуск',
      dataIndex: ['vacation'],
    },
    // {
    //   title: 'Каникулы',
    //   dataIndex: ['holidays'],
    //   className: hiddenColumn(),
    // },
  ];

  const onClickGet = () => {
    setAllowGet((prev) => prev + 1);
    getData();
  };

  useEffect(() => {
    if (!isEmpty(dataTable)) {
      setDataTable([]);
    }
  }, [startMonth, endMonth]);

  useEffect(() => {
    if (allowGet) {
      getData();
    }
  }, [allowGet, JSON.stringify(config)]);

  return (
    <>
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <b className="mr-2">
            Для получение промежуточной статистики нажмите:
          </b>
          <Button
            loading={fetching}
            type="primary"
            onClick={onClickGet}
          >
            Получить
          </Button>
        </div>
      </div>
      <Table
        fetching={fetching}
        rowKey={(row) => row.uuid}
        columns={columns}
        dataSource={dataTable}
        size="small"
        pagination={false}
      />
    </>
  );
}

StatisticsCard.propTypes = {
  startMonth: string,
  endMonth: string,
  setDataTable: func,
  dataTable: array,
};
