import React from 'react';

import { Flex } from 'antd';

import AdminPermissions from './adminPermissions/AdminPermissions';
import PageWrapper from '../../../components/PageWrapper';

export default function AdminDashboard() {
  return (
    <PageWrapper
      title="Админ дашборд"
    >
      <Flex
        className="-mt-4 w-full"
      >
        <AdminPermissions />
      </Flex>
    </PageWrapper>
  );
}
