import React, { useEffect, useMemo, useState } from 'react';

import { Menu, theme } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import {
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiAnimationOutline,
  mdiChartGantt,
  mdiFileDocumentArrowRightOutline,
  mdiMonitorDashboard,
  mdiShieldKeyOutline,
  mdiTextBoxMultipleOutline,
  mdiTuneVerticalVariant,
  mdiViewDashboardVariant,
  mdiCalculator,
  mdiGrid,
  mdiPuzzle,
  mdiOfficeBuilding,
  mdiAccountMultiple,
  mdiCalendarAccount,
  mdiChartPie,
  mdiClipboardText,
  mdiHistory,
} from '@mdi/js';
import Icon from '@mdi/react';
import Sider from 'antd/es/layout/Sider';
import { bool, func } from 'prop-types';
import classNames from 'classnames';
import Logo from '../logo/Logo';

import { isRootOrAdmin } from '../../redux/profile/selectors';
import useAPI from '../../api/useAPI';
import { getDeliveryPartition } from '../../redux/config/selectors';

export default function AppSidebar({ isSidebarCollapsed, setSidebarCollapsed }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { pathname } = useLocation();
  const { checkEntityPerm } = useAPI();

  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    if (pathname === '/departments/departmentsInfo' || pathname === '/departments/requests') {
      setSelectedKeys(['/departments']);
    } else {
      setSelectedKeys([pathname]);
    }
  }, [pathname]);

  const deliveryPartition = useSelector(getDeliveryPartition);
  const isUserRootOrAdmin = useSelector(isRootOrAdmin);

  const [displayFlag, changeDisplayFlag] = useState(null);

  function getItem(label, key, icon, children, type, className) {
    return {
      key,
      icon,
      children,
      label,
      type,
      className,
    };
  }

  const checkPerms = () => {
    const data = {
      entity_uuid: deliveryPartition,
      perm: ['set'],
    };

    checkEntityPerm(data).then((res) => {
      changeDisplayFlag(res);
    });
  };

  const tabsForAdmin = useMemo(() => {
    const data = [
      // getItem(
      //   <NavLink to="/statistics/contractors">
      //     Отчетная статистика
      //   </NavLink>,
      //   '/statistics/contractors',
      //   <Icon path={mdiTextBoxMultipleOutline} size={1} />,
      // ),
    ];

    if (isUserRootOrAdmin) {
      data.push(
        // getItem(
        //   <NavLink to="/reportStatistics/contractors">
        //     Отчетная статистика
        //   </NavLink>,
        //   '/reportStatistics/contractors',
        //   <Icon path={mdiTextBoxMultipleOutline} size={1} />,
        // ),
        getItem(
          <NavLink to="/admin/statistics">
            Промежуточная статистика
          </NavLink>,
          '/admin/statistics',
          <Icon path={mdiTextBoxMultipleOutline} size={1} />,
        ),
        getItem(
          <NavLink to="/admin/vacations">
            Дашборд отпусков
          </NavLink>,
          '/admin/vacations',
          <Icon path={mdiTuneVerticalVariant} size={1} />,
        ),
        // getItem(
        //   <NavLink to="/admin/holidays">
        //     Дашборд каникул
        //   </NavLink>,
        //   '/admin/holidays',
        //   <Icon path={mdiTuneVerticalVariant} size={1} />,
        // ),
        // getItem(
        //   <NavLink to="/admin/dayoffs">
        //     Дэй оффы
        //   </NavLink>,
        //   '/admin/dayoffs',
        //   <Icon path={mdiSortReverseVariant} size={1} />,
        // ),
        getItem(
          <NavLink to="/userdashboard">
            Юзер дашборд
          </NavLink>,
          '/userdashboard',
          <Icon path={mdiAccountGroup} size={1.15} />,
        ),
        getItem(
          <NavLink to="/logs">
            Логи
          </NavLink>,
          '/logs',
          <Icon path={mdiHistory} size={1.15} />,
        ),
        getItem(
          <NavLink to="/departments/departmentsInfo">
            Отделы
          </NavLink>,
          '/departments',
          <Icon path={mdiAnimationOutline} size={1.15} />,
        ),
        getItem(
          <NavLink to="/dayuserreport">
            Дневная отчетность
          </NavLink>,
          '/dayuserreport',
          <Icon path={mdiMonitorDashboard} size={1.15} />,
        ),
        getItem(
          <NavLink to="/admin/dashboard">
            Админ дашборд
          </NavLink>,
          '/admin/dashboard',
          <Icon path={mdiShieldKeyOutline} size={1.15} />,
        ),
      );
    }
    return data;
  }, [isUserRootOrAdmin]);

  const tabsForHR = useMemo(() => {
    const data = [];

    if (isUserRootOrAdmin) {
      data.push(
        getItem(
          <NavLink to="/statistics/contractors">
            Отчетная статистика
          </NavLink>,
          '/statistics/contractors',
          <Icon path={mdiTextBoxMultipleOutline} size={1} />,
        ),
        getItem(
          <NavLink to="/events">
            Панель событий
          </NavLink>,
          '/events',
          <Icon path={mdiPuzzle} size={1.15} />,
        ),
      );
    }
    return data;
  }, [isUserRootOrAdmin]);

  const tabsForDepartment = useMemo(() => {
    const data = [];

    if (isUserRootOrAdmin) {
      data.push(
        getItem(
          <NavLink to="/departmentManagement">
            Отдел
          </NavLink>,
          '/departmentManagement',
          <Icon path={mdiOfficeBuilding} size={1} />,
        ),
        getItem(
          <NavLink to="/departmentContractors">
            Подрядчики
          </NavLink>,
          '/departmentContractors',
          <Icon path={mdiAccountMultiple} size={1.15} />,
        ),
        getItem(
          <NavLink to="/departmentVacations">
            Отпуска подрядчиков
          </NavLink>,
          '/departmentVacations',
          <Icon path={mdiCalendarAccount} size={1.15} />,
        ),
        getItem(
          <NavLink to="/departmentReports">
            Отчеты отдела
          </NavLink>,
          '/departmentReports',
          <Icon path={mdiChartPie} size={1.15} />,
        ),
        getItem(
          <NavLink to="/requests">
            Заявки
          </NavLink>,
          '/requests',
          <Icon path={mdiClipboardText} size={1.15} />,
        ),
      );
    }
    return data;
  }, [isUserRootOrAdmin]);

  useEffect(() => {
    if (deliveryPartition && !isUserRootOrAdmin) {
      checkPerms();
    }
    if (isUserRootOrAdmin) {
      changeDisplayFlag(true);
    }
  }, [deliveryPartition, isUserRootOrAdmin]);

  const items = [
    getItem(
      'Панель сотрудника',
      '1',
      null,
      [
        getItem(
          <NavLink to="/deliverydashboard">
            Дашборд
          </NavLink>,
          '/deliverydashboard',
          <Icon path={mdiViewDashboardVariant} size={1.15} />,
        ),
        getItem(
          <NavLink to="/reports">
            Мои отчеты
          </NavLink>,
          '/reports',
          <Icon path={mdiFileDocumentArrowRightOutline} size={1.15} />,
        ),
        getItem(
          <NavLink to="/vacations">
            Мои отпуска
          </NavLink>,
          '/vacations',
          <Icon path={mdiChartGantt} size={1.15} />,
        ),
        getItem(
          <NavLink to="/mydepartment">
            Мой отдел
          </NavLink>,
          '/mydepartment',
          <Icon path={mdiAccountGroupOutline} size={1.15} />,
        ),
      ],
      'group',
    ),
    getItem(
      'HR-панель',
      '2',
      null,
      tabsForHR,
      'group',
    ),
    getItem(
      'Панель отдела',
      '3',
      null,
      tabsForDepartment,
      'group',
    ),
    displayFlag && getItem(
      'Панель администратора',
      '4',
      null,
      tabsForAdmin,
      'group',
    ),
  ];

  return (
    <Sider
      collapsible
      collapsed={isSidebarCollapsed}
      onCollapse={(value) => setSidebarCollapsed(value)}
      style={{
        background: colorBgContainer,
      }}
      width={230}
      breakpoint="md"
    >
      <div className={
        classNames(
          'flex items-center px-4 h-[54px] logo-wrapper',
          { 'justify-start': !isSidebarCollapsed, 'justify-center': isSidebarCollapsed },
        )
      }
      >
        <Logo isSidebarCollapsed={isSidebarCollapsed} />
      </div>
      <Menu
        className="mt-5"
        mode="inline"
        items={items}
        selectedKeys={selectedKeys}
        style={{ maxHeight: 'calc(100vh - 130px)', overflowY: 'auto' }}
      />
    </Sider>
  );
}
AppSidebar.propTypes = {
  isSidebarCollapsed: bool,
  setSidebarCollapsed: func,
};
