import React from 'react';
import {
  Space,
} from 'antd';
import { capitalize } from 'lodash';

import { element, string } from 'prop-types';
import { useSelector } from 'react-redux';
import PageWrapper from '../../components/PageWrapper';
import RequestVacationsCard from './vacationsRequests/RequestVacationsCard';
import { getProfileInfo } from '../../redux/profile/selectors';

export default function VacationPage({ customTitle, actorUUID }) {
  const myProfileData = useSelector(getProfileInfo);

  const actor = actorUUID || myProfileData?.uuid;

  return (
    <PageWrapper
      title={customTitle || capitalize('Отпуска')}
    >
      <Space direction="vertical" size="middle">
        <RequestVacationsCard actor={actor} />
      </Space>
    </PageWrapper>
  );
}

VacationPage.propTypes = {
  customTitle: element,
  actorUUID: string,
};
