import { get } from 'lodash';

export const getDepartmentEmployeeRequestsList = (state) => get(state, 'employeeRequest.departmentEmployeeRequestsList', []);

export const getAllDepartmentsEmployeeRequestsList = (state) => get(state, 'employeeRequest.allDepartmentsEmployeeRequestsList', []);

export const getDepartments = (state) => get(state, 'employeeRequest.departmentsList', []);

export const getIsFetchingEmployeeRequest = (state) => get(state, 'employeeRequest.fetching', false);

export const getTotalRequests = (state) => get(state, 'employeeRequest.totalRequests', 0);
