import { useEffect, useState } from 'react';
import { capitalize, omit } from 'lodash';
import { useSelector } from 'react-redux';
import useAPI from '../../api/useAPI';
import {
  actorIsRootOrAdmin, firstName, getUuid, lastName,
} from '../utils';
import { getDeliveryPartition } from '../../redux/config/selectors';

export default function useActorsR54WithPagination({
  defaultConfig = {},
}) {
  const {
    getDeliveryUsers,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [actors, setActors] = useState([]);
  const [total, setTotal] = useState(0);
  const [config, setConfig] = useState({
    actor_type: ['user', 'classic_user'],
    limit: 100,
    offset: 0,
    ...defaultConfig,
  });

  const onChangeSearch = (value) => {
    if (value === '') {
      setConfig((prev) => ({
        ...omit(prev, 'search_data'),
        limit: 100,
        offset: 0,
      }));
    } else {
      setConfig((prev) => ({
        ...prev,
        limit: 100,
        offset: 0,
        search_data: {
          fields: {
            uinfo: {
              first_name: 'first_name',
              last_name: 'last_name',
            },
          },
          value,
        },
      }));
    }
    setActors([]);
  };

  const getActorsR54 = async () => {
    const res = await getDeliveryUsers(config, [
      'FETCH_ACTORS_R54_REQUEST',
      'FETCH_ACTORS_R54_SUCCESS',
      'FETCH_ACTORS_R54_FAILURE',
    ]);
    if (res) {
      const newActors = res?.data?.map?.((item) => ({
        value: getUuid(item),
        actor: item?.actor ?? '',
        label: `${capitalize(firstName(item))} ${capitalize(lastName(item))}`,
        isWMSAdmin: actorIsRootOrAdmin(item),
      })) ?? [];
      setActors((prev) => [...prev, ...newActors]);
      setTotal(res?.total ?? 0);
    }
  };

  useEffect(() => {
    if (partitionUUID) {
      getActorsR54();
    }
  }, [partitionUUID, JSON.stringify(config)]);

  return {
    actors,
    total,
    config,
    setConfig,
    onChangeSearch,
    valueSearch: config?.search_data?.value ?? '',
  };
}
