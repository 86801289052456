import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { Flex } from 'antd';

import useURLParams from '../../../hooks/useURLParams';

import UsersStatistics from './UsersStatistics';
import PageWrapper from '../../../components/PageWrapper';
import UniGeneralButtons from '../../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import DepartmentsStatistics from './DepartmentsStatistics';

const tabs = [
  { name: 'contractors', title: 'Подрядчики' },
  { name: 'departments', title: 'Отделы' },
];

export default function ReportStatisticsPage() {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('contractors');

  const switchViewFunc = (name) => {
    setActiveTab(name);
    history.push(`/statistics/${name}`);
  };

  return (
    <PageWrapper
      title="Отчетная статистика"
      needWrapperClassName={false}
    >
      <Flex
        className="-mt-5 mb-3"
      >
        <UniGeneralButtons
          viewMode={activeTab}
          switchViewFunc={switchViewFunc}
          tabs={tabs}
        />
      </Flex>

      <Switch>
        <Route
          path="/statistics/contractors"
          render={() => (
            <UsersStatistics />
          )}
        />
        <Route
          path="/statistics/departments"
          render={() => (
            <DepartmentsStatistics />
          )}
        />
      </Switch>
    </PageWrapper>
  );
}

ReportStatisticsPage.propTypes = {};
