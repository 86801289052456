import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { string } from 'prop-types';
import dayjs from 'dayjs';
import {
  capitalize, get, head,
} from 'lodash';
import cn from 'classnames';

import { Flex, Spin } from 'antd';
import useAPI from '../../../../api/useAPI';

import { getDeliveryPartition } from '../../../../redux/config/selectors';

import { translateTypeDay } from '../../../user/dayOff/utils';
import { compareActorUUIDs, convertTimeFormat, convertTimeFormatToHours } from './utils';

import BaseTable from '../../../../w54_components/BaseTable/BaseTable';
import EmployeeNameForDepartment from './EmployeeNameForDepartment';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';
import { getListAndReadEntities } from '../../../../api/actions/entity';
import { DISABLE_RESET_PROPERTY, ENABLE_RESET_PROPERTY } from '../../../../redux/reset/resetSlice';
import { getResetValueForCurrentKey } from '../../../../redux/reset/resetSelector';
import useURLParams from '../../../../hooks/useURLParams';
import AddEmployeeButton from '../artifacts/AddEmployeeButton';
import useArifactsStorage from '../../../../redux/departments/hooks/useArifactsStorage';

export default function EmployeesForDepartment({
  name = 'employees_list_for_department',
  hideAddEmployee,
  departmentUUID = '',
  leader = '',
  deputy = '',
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);
  const needReload = useSelector(getResetValueForCurrentKey(name));

  const { getURLParams, setSearchParams } = useURLParams();

  const { artifact: activeArtifact } = getURLParams();

  const { uuid: artifactsStorageUUID } = useArifactsStorage(false);

  const {
    getMonthlyReport,
  } = useAPI();

  const [dataTable, setDataTable] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const today = dayjs().format('YYYY-MM-DD');

  const currentMonth = dayjs().format('YYYY-MM');
  const previousMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const columns = [
    {
      title: 'Имя',
      key: 'name',
      dataIndex: ['uinfo'],
      width: '40%',
      render: (cell, row) => (
        <EmployeeNameForDepartment
          user={row}
          deputy={deputy}
          leader={leader}
        />
      ),
    },
    {
      title: 'Cегодня',
      key: 'currentMonthRep',
      dataIndex: ['currentMonthRep'],
      width: '30%',
      render: (cell, row, index) => {
        const todayReport = cell?.params?.days?.find((el) => head(el?.rangeDates) === today);

        return todayReport ? (
          <Flex
            className="text-base"
          >
            {todayReport?.type === 'office_work' ? (
              <Flex
                vertical
                className="mr-6 text-blue-500"
              >
                <span>
                  {translateTypeDay[todayReport?.type]}
                  {': '}
                  {convertTimeFormat(todayReport?.startWork)}
                  -
                  {convertTimeFormat(todayReport?.finishWork)}
                </span>
                <span>
                  Длительность:
                  {' '}
                  {convertTimeFormatToHours(todayReport?.hoursWorked)}
                </span>
              </Flex>
            ) : (
              <span
                className="mr-6 text-orange-500"
              >
                {translateTypeDay[todayReport?.type]}
              </span>
            )}
          </Flex>
        ) : <span className="text-red-500">Нет отчета</span>;
      },
    },
    {
      title: `Отчет за ${capitalize(dayjs().subtract(1, 'month').format('MMMM'))}`,
      key: 'prevMonthRep',
      dataIndex: ['prevMonthRep'],
      width: '20%',
      render: (cell) => (cell?.params?.status ? (
        <Flex className="text-green-500">
          {cell?.params?.status === 'submitted' && 'Отправлен'}
          {cell?.params?.status === 'auto' && 'Автоматический'}
        </Flex>
      ) : <span className="text-red-500">Отсутствует</span>),
    },
  ];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');
      if (uuid) {
        setSearchParams({ artifact: uuid });
      }
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  const initFunc = async () => {
    setIsFetching(true);
    const resUsers = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    const users = resUsers?.data ?? [];

    if (!users?.length) {
      setDataTable([]);
      setIsFetching(false);
      return;
    }

    const res = await getMonthlyReport(partitionUUID, {
      depth: 0,
      params: {
        date: [currentMonth, previousMonth],
      },
    });

    const reports = res?.data ?? [];

    setDataTable(users.map((user) => {
      const previousRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === previousMonth);
      const currentRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === currentMonth);

      return {
        ...user,
        prevMonthRep: previousRep ?? null,
        currentMonthRep: currentRep ?? null,
      };
    }));

    setIsFetching(false);
  };

  const onCreateArtCallback = () => {
    initFunc();
    dispatch(ENABLE_RESET_PROPERTY('artifact_list'));
  };

  useEffect(() => {
    if (departmentUUID) {
      initFunc();
    }
  }, [departmentUUID]);

  useEffect(() => {
    if (needReload && departmentUUID) {
      initFunc();
      dispatch(DISABLE_RESET_PROPERTY(name));
    }
  }, [needReload]);

  return (
    <Flex
      vertical
      className="w-full h-100"
    >
      {!hideAddEmployee && (
      <Flex
        className="my-2"
      >
        <AddEmployeeButton
          artifactsStorage={artifactsStorageUUID}
          defaultDepartmentUUID={departmentUUID}
          onCreateArtifactCallback={onCreateArtCallback}
        />
      </Flex>
      )}
      <Spin
        className="w-full h-100"
        spinning={isFetching}
      >
        <BaseTable
          data={dataTable}
          columns={columns}
          rowClassName={rowClassName}
          size="small"
          rowKey="uuid"
          onRow={onRow}
          className={cn('w-full h-full !mb-3')}
          useCustomPagination
          disablePagination
        />
      </Spin>
    </Flex>
  );
}

EmployeesForDepartment.propTypes = {
  name: string,
  departmentUUID: string,
  leader: string,
  deputy: string,
};
