import { isEmpty, prop } from 'ramda';

import axiosRequest from '../api';
import { getActionCreatorFromConstantWithEmptyPayload } from '../utils';
import { getMD5HashFromString } from '../../utils';

export function getListOfEntities({
  endpoint, data, constants, options,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    const onSuccessData = (res = []) => onSuccess({
      entity_type: prop('entity_type', data),
      ...res,
    });

    dispatch(
      axiosRequest.post(endpoint || 'entity/list', constants, data, {
        onSuccess: onSuccessData,
        onFailure,
        ...options,
      }),
    );
  });
}

export function entityRead({ data, constants, options }) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/read', constants, data, {
        onSuccess,
        onFailure,
        ...options,
      }),
    );
  });
}

export function entityPartialRead({ data, constants, options }) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/read/partial', constants, data, {
        onSuccess,
        onFailure,
        ...options,
      }),
    );
  });
}

export function entityUpdate({
  data, constants, partition, options,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/update', constants, data, {
        onSuccess,
        onFailure,
        ...options,
      }),
    );
  });
}

export function entitySet({ data, constants, options }) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/set', constants, data, {
        onSuccess,
        onFailure,
        ...options,
      }),
    );
  });
}

export function entityCreate({ data, constants, options }) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/create', constants, data, {
        onSuccess,
        onFailure,
        ...options,
      }),
    );
  });
}

export const entityDelete =	({
  endpoint = 'entity/delete', data, constants, options,
}) => (dispatch) => new Promise((onSuccess, onFailure) => {
	      dispatch(
	        axiosRequest.post(endpoint, constants, data, {
	          onSuccess,
	          onFailure,
	          onCancel: onFailure,
	          ...options,
	        }),
	      );
	    });

export function getListAndReadEntities({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition,
  doNotCheckError = false,
  requestOptions,
  url,
  with_files,
  signal,
}) {
  const configList = {
    endpoint: url,
    data,
    constants: [constants[0], '', ''],
    options: {
      partition,
      signal,
    },
  };

  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(getListOfEntities(configList))
      .then((listParams) => {
        if (isEmpty(listParams.entity_uuids)) {
          dispatch(
            getActionCreatorFromConstantWithEmptyPayload(
              constants[1],
            ),
          );

          onSuccess({ data: [], total: 0 });
        } else {
          const configRead = {
            data: {
              ...listParams,
              with_files,
            },
            constants: ['', constants[1], constants[2]],
            options: requestOptions || {
              partition,
              onSuccess: (res) => onSuccess({
                data: res,
                total: prop('total', listParams),
              }),
              doNotCheckError,
              signal,
            },
          };

          dispatch(entityRead(configRead));
        }
      })
      .catch((error) => {
        onFailure(error);
      });
  });
}

export const getRequestEntity = ({ data, partition }) => {
  const config = {
    partition,
    data: { ...data },
    constants: [],
  };
  return (dispatch) => dispatch(getListAndReadEntities(config));
};

export const getRequestReadEntity = ({ data, partition }) => {
  const constants = [];

  const options = {
    partition,
  };

  return entityRead({
    data,
    constants,
    options,
  });
};

export const getOrCreateSingle = ({
  constants,
  data,
  doNotCheckError,
  partition = 'PM',
}) => {
  const { key, entity_type, parent } = data || {};

  if (!key) {
    data.key = getMD5HashFromString(`${entity_type}${parent}`);
  }

  const types = constants || [
    'GET_OR_CREATE_SINGLE_REQUEST',
    'GET_OR_CREATE_SINGLE_SUCCESS',
    'GET_OR_CREATE_SINGLE_FAILURE',
  ];
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequest.post(
      'utility/entity/unique/check',
      types,
      data,
      {
        partition,
        doNotCheckError,
        onSuccess: resolve,
        onFailure: reject,
      },
    ));
  });
};

export const entityUniqClean = ({
  entityUUID,
  key,
}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequest.post(
    'utility/entity/unique/clean',
    [
      'ENTITY_UNIQUE_CLEAN_REQUEST',
      'ENTITY_UNIQUE_CLEAN_SUCCESS',
      'ENTITY_UNIQUE_CLEAN_FAILURE',
    ],
    {
      entity_uuid: entityUUID,
      key,
    },
    {
      onSuccess: resolve,
      onFailure: reject,
    },
  ));
});

export const removeFile = (uuid, filesUuid, type, constants) => {
  const data = {
    entity_type: type,
    entity_uuid: uuid,
    files: [filesUuid],
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'utility/entity/update/clean',
        constants || [
          'DELETE_FILE_REQUEST',
          'DELETE_FILE_SUCCESS',
          'DELETE_FILE_FAILURE',
        ],
        data,
        {
          onSuccess: (response) => resolve(response),
          onFailure: reject,
        },
      ),
    );
  });
};
