import { useDispatch, useSelector } from 'react-redux';
import { getOrCreateSingle } from './actions/entity';
import { getMD5HashFromString } from '../utils';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  GET_PERSONAL_USER_FAILURE,
  GET_PERSONAL_USER_REQUEST,
  GET_PERSONAL_USER_SUCCESS,
  GET_PERSONAL_USERS_STORAGE_REQUEST,
  GET_PERSONAL_USERS_STORAGE_SUCCESS,
  GET_PERSONAL_USERS_STORAGE_FAILURE,
} from '../redux/storages/storagesSlice';
import { getDeliveryPartition } from '../redux/config/selectors';
import { getPersonalUsersStorageUUID } from '../redux/storages/selectors';
import { getProfileUUID } from '../redux/profile/selectors';

const useStoragesAPI = () => {
  const partitionUUID = useSelector(getDeliveryPartition);
  const personalUsersStorageUUID = useSelector(getPersonalUsersStorageUUID);
  const myActorUUID = useSelector(getProfileUUID);

  const dispatch = useDispatch();

  const getOrCreatePersonalUsersStorage = (options, constants) => dispatch(
    getOrCreateSingle({
      data: {
        parent: partitionUUID,
        entity_type: 'personalUsersStorage',
        with_files: false,
        key: getMD5HashFromString(`personalUsersStorage${partitionUUID}`),
        ...options,
      },
      constants: constants || [
        GET_PERSONAL_USERS_STORAGE_REQUEST,
        GET_PERSONAL_USERS_STORAGE_SUCCESS,
        GET_PERSONAL_USERS_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getOrCreatePersonalUser = (options, constants) => dispatch(
    getOrCreateSingle({
      data: {
        parent: personalUsersStorageUUID,
        entity_type: 'personalUser',
        with_files: false,
        key: getMD5HashFromString(`personalUser${personalUsersStorageUUID}${myActorUUID}`),
        ...options,
      },
      constants: constants || [
        GET_PERSONAL_USER_REQUEST,
        GET_PERSONAL_USER_SUCCESS,
        GET_PERSONAL_USER_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  return {
    getOrCreatePersonalUsersStorage,
    getOrCreatePersonalUser,
  };
};

export default useStoragesAPI;
