/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isFetchingUser: true,
};

const masqueradingSlice = createSlice({
  name: 'masqueradingSlice',
  initialState,
  reducers: {
    GET_MASQUERADE_USER_REQUEST(state) {
      state.user = null;
      state.isFetchingUser = true;
    },
    GET_MASQUERADE_USER_SUCCESS(state, action) {
      state.user = action.payload;
      state.isFetchingUser = false;
    },
    GET_MASQUERADE_USER_FAILURE(state) {
      state.isFetchingUser = false;
    },
  },
});

export const {
  GET_MASQUERADE_USER_REQUEST,
  GET_MASQUERADE_USER_SUCCESS,
  GET_MASQUERADE_USER_FAILURE,
} = masqueradingSlice.actions;

export default masqueradingSlice.reducer;
