import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { capitalize } from 'lodash';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Modal,
  Row,
  Typography,
  Image,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { string } from 'prop-types';
import { pdfjs } from 'react-pdf';
import antNotification from '../../../../utils/antNotification';
import useAPI from '../../../../api/useAPI';
import { getProfileInfo } from '../../../../redux/profile/selectors';
import UserStatisticsForm from './UserStatisticsForm';
import { getMonthlyStorageInfo, getMonthlyStorageUUID } from '../../../../redux/storages/selectors';
import ModalReportImage from './sources/ModalReportImage.png';
import { getLastWorkingDayOfMonth, isBetweenDates } from '../../../../utils/dateTimeUtils';
import useLogsAPI from '../../../../api/useLogsAPI';

const { Paragraph } = Typography;

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

function UserPersonalStatisticsForMonth({
  actor,
}) {
  const { createLog } = useLogsAPI();

  const myProfileData = useSelector(getProfileInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const [isModalOpen, setModalOpen] = useState(false);
  const [monthlyReport, setMonthlyReport] = useState(null);
  const [isInstructionOpen, setInstructionOpen] = useState(false);

  const [title, setTitle] = useState('');

  const {
    updateMonthlyReport,
    getOrCreateMonthlyReport,
  } = useAPI();

  const location = useLocation();
  const currentMonth = qs.parse(location?.search)?.month;

  const onOpenModal = () => setModalOpen(true);
  const onCancelModal = () => setModalOpen(false);

  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const getListDayOffsAndReport = async () => {
    const configRequest = {
      actor,
      params: {
        date: dayjs(currentMonth).format('YYYY-MM'),
      },
      with_files: true,
    };
    const res = await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest);

    setMonthlyReport(res);
  };

  const onOk = async (values = {}, filesForReport = [], uuid = '', files = []) => {
    try {
      if (actor) {
        const config = {
          actor,
          entity_type: 'monthlyReport',
          entity_uuid: uuid || monthlyReport?.uuid,
          params: {
            status: 'submitted',
            statistics: {
              ...values,
              created: dayjs().toString(),
              totalWorks: Math.ceil(values?.totalWorks) ?? 0,
              fullTotalWorks: values?.totalWorks ?? 0,
              totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
              + +(values?.dayoff ?? 0),
            },
            // logs: monthlyReport?.params?.logs ?? [],
          },
        };

        // if (monthlyReport?.params?.statistics) {
        //   let logFiles = [];
        //   if (monthlyReport?.params?.logs?.length) {
        //     logFiles = monthlyReport?.params?.logs?.reduce?.((acc, log) => {
        //       if (log?.files?.length) {
        //         return [...acc, ...(log?.files ?? [])];
        //       }
        //       return acc;
        //     }, []);
        //   }
        //   const newLogFiles = files
        //     ?.filter?.((file) => !logFiles?.includes(file?.uuid))
        //     .map((el) => el?.uuid);
        //   config.params.logs.unshift({
        //     ...(monthlyReport?.params?.statistics ?? {}),
        //     status: monthlyReport?.params?.status,
        //     files: newLogFiles,
        //   });
        // }

        const newFormData = new FormData();

        newFormData.append('data', JSON.stringify(config));

        if (filesForReport.length !== 0) {
          for (let i = 0; i < filesForReport.length; i += 1) {
            newFormData.append('file', filesForReport[i]);
          }
        }

        await updateMonthlyReport(newFormData);
        createLog({
          parent: uuid || monthlyReport?.uuid,
          type: monthlyReport?.params?.statistics ? 'UPDATE' : 'CREATE',
          entityType: 'monthlyReport',
          values: {
            date: monthlyReport?.params?.date,
            status: 'submitted',
            statistics: {
              ...values,
              totalWorks: Math.ceil(values?.totalWorks) ?? 0,
              fullTotalWorks: values?.totalWorks ?? 0,
              totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
              + +(values?.dayoff ?? 0),
            },
          },
          files: filesForReport,
        });
        antNotification.success('Отчет оправлен.');
        await getListDayOffsAndReport();
        // const configForMonthReports = {
        //   order: 'desc',
        //   depth: 0,
        //   orderBy: 'created',
        //   params: {
        //     actorUuid: currentActor,
        //     date: dayjs(currentMonth).format('YYYY-MM-DD'),
        //   },
        // };
        // getMonthReports(yearReportUUID, configForMonthReports);
      }
    } catch (e) {
      antNotification.error('Ошибка!');
    }
    onCancelModal();
  };

  const ButtonForReport = useMemo(() => {
    if (currentMonth !== monthlyReport?.params?.date) return null;
    const lastWorkDay = getLastWorkingDayOfMonth(dayjs(currentMonth).month());
    const isEndOfMonth = isBetweenDates(lastWorkDay, dayjs(currentMonth)?.endOf?.('month'), dayjs());

    if (monthlyReport?.params?.status === 'submitted') {
      setTitle(`Корректировка отчета за ${capitalize(dayjs(currentMonth).format('MMMM, YYYY'))}`);
      return (
        <Button className="mr-2" type="primary" onClick={onOpenModal} style={{ background: 'rgb(82, 196, 26)' }}>
          Скорректировать отчет (
          {capitalize(dayjs(currentMonth).format('MMMM'))}
          )
        </Button>
      );
    }

    if (dayjs(currentMonth).isSame(lastMonth)) {
      setTitle(`Отправка отчета за ${capitalize(dayjs(currentMonth).format('MMMM, YYYY'))}`);
      return (
        <Button className="mr-2" type="primary" onClick={onOpenModal} style={{ background: '#ffa500' }}>
          Отправить отчет за прошлый месяц (
          {capitalize(dayjs(currentMonth).format('MMMM'))}
          )
        </Button>
      );
    }

    if (dayjs(currentMonth).isAfter(lastMonth) && !isEndOfMonth) {
      setTitle(`Досрочная отправка отчета за ${capitalize(dayjs(currentMonth).format('MMMM, YYYY'))}`);
      return (
        <Button className="mr-2" type="primary" onClick={onOpenModal} style={{ background: 'red' }}>
          Отправить отчет за
          {' '}
          {capitalize(dayjs(currentMonth).format('MMMM'))}
          {' '}
          досрочно
        </Button>
      );
    }
    setTitle(`Отправка отчета за ${capitalize(dayjs(currentMonth).format('MMMM, YYYY'))}`);
    return (
      <Button className="mr-2" type="primary" onClick={onOpenModal}>
        Отправить отчет за
        {' '}
        {capitalize(dayjs(currentMonth).format('MMMM'))}
      </Button>
    );
  }, [currentMonth, JSON.stringify(monthlyReport)]);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  // const handleUpload = async () => {
  //   const formData = new FormData();
  //   for (let i = 0; i < fileList.length; i += 1) {
  //     formData.append('file', fileList[i]);
  //   }
  //   const data = {
  //     entity_type: 'instruction',
  //     parent: instructionStorage?.uuid,
  //   };
  //   formData.append('data', JSON.stringify(data));

  //   const oldInstructions = await requestListInstructions(instructionStorage?.uuid);
  //   if (oldInstructions?.entity_uuids?.length) {
  //     await requestDeleteInstructions(oldInstructions?.entity_uuids);
  //   }
  //   await createInstruction(instructionStorage?.uuid, formData);
  //   antNotification.success('Инструкция оправлена.');
  //   setInstructionCreateOpen(false);
  //   setFileList([]);
  // };

  // const propsUpload = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);

  //     return false;
  //   },
  //   fileList,
  // };

  const onOpenInstruction = async () => {
    setInstructionOpen(true);
    // const res = await requestGetInstructions(instructionStorage?.uuid);
    // setFiles(res.data);
  };

  useEffect(() => {
    if (actor && monthlyStorageUUID && actor === monthlyStorageInfo?.actor) {
      getListDayOffsAndReport();
    }
  }, [monthlyStorageUUID, actor, isModalOpen, currentMonth]);

  return (
    <>
      <Row span={12} className="flex justify-end">
        <Button className="mr-2 mb-1" onClick={onOpenInstruction}>
          Инструкция
        </Button>
        {/* {isUserRootOrAdmin ? (
          <Button className="mr-2 mb-2"
          style={{ borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0, marginLeft: -7.5 }}
             onClick={() => setInstructionCreateOpen(true)}>
            <Icon path={mdiCog} size={1} />
          </Button>
        ) : null} */}
        {ButtonForReport}
      </Row>

      <UserStatisticsForm
        actor={actor}
        isModalOpen={isModalOpen}
        onCancelModal={onCancelModal}
        currentMonth={currentMonth}
        onFinish={onOk}
        formSettings={{
          title,
          totalWorksLabel: capitalize('Отработанные часы: '),
          commentLabel: capitalize('Комментарий (указать причину отправки отчета досрочно)'),
        }}
        isAheadOfTime={dayjs(currentMonth).isAfter(lastMonth) && !dayjs().isAfter(dayjs(currentMonth).endOf('month').subtract(2, 'day'))}
      />

      <Modal
        title="Инструкция"
        open={isInstructionOpen}
        onCancel={() => setInstructionOpen(false)}
        destroyOnClose
        centered
        footer={null}
        width={640}
      >
        <Paragraph>
          Инструкция для отправки отчетов на HRM54:
        </Paragraph>
        <Paragraph>
          1. Количество рабочих часов вы можете посмотреть через календарь или в форме отправки отчета.
        </Paragraph>
        <Paragraph>
          2. Вверху  в [Мои задачи] клик “Отправить отчет/Скорректировать отчет за прошлый месяц. Или выбрать нужный месяц и нажать кнопку “Отправить отчет”.
        </Paragraph>
        <Paragraph>
          3. Проверить количество отработанных часов, если нужно скорректировать часы/пропущенные рабочие дни/отпуск и указать причину в комментариях/прикрепить файл.
        </Paragraph>
        <Paragraph>
          4. Часы на клиентском/внутреннем проекте указывать примерно.
        </Paragraph>
        <Paragraph>
          5. Клик “Отправить отчет”
        </Paragraph>
        <Image src={ModalReportImage} preview={false} />
      </Modal>
      {/* <Modal
        title="Инструкция"
        open={isInstructionOpen}
        onCancel={() => setInstructionOpen(false)}
        destroyOnClose
        centered
        footer={null}
        width={640}
      >
        {files.map((item, i) => {
          const {
            content_type: contentType,
            file,
          } = item?.files[0] || {};

          if (contentType === 'application/pdf') {
            return (
              <Document
                file={`data:${contentType};base64, ${file}`}
                width={640}
                height={700}
                onLoadSuccess={onDocumentLoadSuccess}
                key={i}
              >
                <Col>
                  {Array.from(new Array(numPages), (el, index) => {
                    if (index > 0) {
                      return (
                        <Row style={{ marginTop: -105 }}>
                          <Page
                            className="mt-n10"
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </Row>
                      );
                    }
                    return (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    );
                  })}
                </Col>
              </Document>
            );
          }
          return null;
        })}
      </Modal> */}

      {/* <Modal
        title="Загрузить инструкцию"
        open={isInstructionCreateOpen}
        onCancel={() => {
          setFileList([]);
          setInstructionCreateOpen(false);
        }}
        destroyOnClose
        centered
        footer={null}
        width={640}
      >
        <Alert
          style={{
            marginTop: 4, marginBottom: 8, paddingInline: '16px', paddingBlock: '16px',
          }}
          message="Информация"
          type="info"
          showIcon
          description={(
            <div>
              <Paragraph style={{ marginBottom: 2 }}>
                1. К загрузке принимаются файлы формата pdf.
              </Paragraph>
              <Paragraph style={{ marginBottom: 2 }}>
                2. Загрузка новой инструкции удаляет старую.
              </Paragraph>
              <Paragraph style={{ marginBottom: 2 }}>
                3. Для просмотра инструкции обычным пользователям требуется
                {' '}
                <b>permissions</b>
                {' '}
                на
                {' '}
                <b>instructionStorage</b>
                .
              </Paragraph>
              <Paragraph style={{ marginBottom: 2 }}>
                4.
                {' '}
                <b>Permissions</b>
                {' '}
                выдаются в
                {' '}
                <b>Entity viewer.</b>
              </Paragraph>
            </div>
            )}
        />
        <Row>
          <Upload {...propsUpload}>
            <Button disabled={fileList.length === 1} icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            style={{ marginLeft: 8 }}
          >
            Start Upload
          </Button>
        </Row>
      </Modal> */}
    </>
  );
}

export default UserPersonalStatisticsForMonth;

UserPersonalStatisticsForMonth.propTypes = {
  actor: string,
};
