import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Flex, Tabs } from 'antd';
import DepartmentsList from '../../admin/departments/DepartmentsList';
import { getUserRootActorUUID } from '../../../redux/storages/selectors';
import useURLParams from '../../../hooks/useURLParams';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import DepartmentCard from '../../admin/departments/DepartmentCard';
import { ENABLE_RESET_PROPERTY } from '../../../redux/reset/resetSlice';
import ArtifactsList from '../../admin/departments/artifacts/ArtifactsList';
import ArtifactCard from '../../admin/departments/artifacts/ArtifactCard';
import { getArtifact, getArtifactUUID } from '../../../redux/departments/selectors/artifactsSelector';
import {
  GET_SINGLE_ARTIFACT_FAILURE, GET_SINGLE_ARTIFACT_REQUEST, GET_SINGLE_ARTIFACT_SUCCESS, RESET_SINGLE_ARTIFACT,
} from '../../../redux/departments/slices/artifactsSlice';
import DepartmentReports from '../../admin/departments/DepartmentReports';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useArtifactsAPI from '../../../api/useArtifactsAPI';

export default function DepartmentManagement() {
  const dispatch = useDispatch();

  const userActorUUID = useSelector(getUserRootActorUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const {
    readListOfDepartments,
  } = useDepartmentsAPI();
  const { readListOfArtifacts } = useArtifactsAPI();

  const [activeKey, setActiveKey] = useState('1');

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  return (
    <Flex
      justify="space-between"
      className="w-full"
    >
      <Flex
        className="w-1/2"
        vertical
      >
        <DepartmentsList
          hideAddButton
          hideKPIButton
          hideExpand
          defaultConfig={{
            params: {
              usersSearch: [userActorUUID],
            },
          }}
          name="department_list"
          currentDepartment={department}
        />
        {activeDepartment && (
          <ArtifactsList
            name="artifact_list"
            hideAddEmployee
            parent={activeDepartment}
            title="Артефакты"
            showUserCreateButton
            department={department}
            departmentUUID={activeDepartment}
            onCreateArtifactCallback={(uuid) => {
              setSearchParams({ artifact: uuid });
              dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'employees_list_for_department']));
            }}
          />
        )}
      </Flex>
      <Flex
        vertical
        className="w-1/2 ml-6"
      >
        {activeDepartment && (
          <Flex
            vertical
            className="mb-6"
          >
            <Tabs
              type="card"
              defaultActiveKey="1"
              className="mb-2"
              activeKey={activeKey}
              items={[
                {
                  key: '1',
                  label: 'Отдел',
                },
                {
                  key: '2',
                  label: 'Отчеты',
                },
              ]}
              onChange={setActiveKey}
              tabBarStyle={{ marginBottom: 0 }}
            />
            {activeKey === '1' && (
            <DepartmentCard
              hideAddEmployee
              hideDeleteButton
              hideCopyURLButton
              department={department}
              departmentUUID={departmentUUID}
              hideEdit
              onBackDepartmentCallback={() => {
                clearSearchParams(['department']);
              }}
              onDeleteDepartmentCallback={() => {
                clearSearchParams(['department']);
                dispatch(ENABLE_RESET_PROPERTY('department_list'));
              }}
            />
            )}
            {activeKey === '2' && (
              <DepartmentReports
                department={department}
                departmentUUID={departmentUUID}
                className="mt-2"
              />
            )}
          </Flex>
        )}

        {activeArtifact && (
        <ArtifactCard
          artifact={artifact}
          artifactUUID={artifactUUID}
          onBackArtCallback={() => {
            clearSearchParams(['artifact']);
          }}
          onUpdateArtCallback={() => {
            dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
          }}
          onDeleteArtCallback={() => {
            clearSearchParams(['artifact']);
            dispatch(ENABLE_RESET_PROPERTY(['artifact_list', 'artifact_list_without_department']));
          }}
        />
        )}
      </Flex>
    </Flex>
  );
}
