import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { Button, Modal } from 'antd';
import { mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import useEmployeeRequestAPI from '../../../api/useEmployeeRequestAPI';

export default function ReviewRequest({ uuid, requestStatus, isDepartmentRoutes }) {
  const { editEmployeeRequest } = useEmployeeRequestAPI();
  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => setModalOpen(true);

  const onCancelModal = () => setModalOpen(false);

  const handleOk = () => {
    const data = {
      entity_type: 'employeeRequest',
      params: {
        status: 'approved',
      },
    };
    editEmployeeRequest(uuid, data);
    onCancelModal();
  };

  return (
    <>
      <Button
        onClick={() => onOpenModal()}
        className="flex items-center border !border-green-500 !bg-green-500 !text-white hover:!text-green-500 hover:!bg-white disabled:!border-gray-300 disabled:!text-black/25 disabled:!bg-black/5"
        ghost
        disabled={requestStatus !== 'pending' || isDepartmentRoutes}
      >
        <Icon path={mdiCheck} className="mr-2" size={0.8} />
        Рассмотреть заявку
      </Button>
      <Modal
        title="Рассмотреть заявку на работкика"
        open={isModalOpen}
        onCancel={() => onCancelModal()}
        destroyOnClose
        width={750}
        onOk={() => handleOk()}
      >
        <span>
          При рассмотрении заявки можно сделать возможность выбрать сотрудников из списка не закрепленных по оделам.
        </span>
      </Modal>
    </>
  );
}

ReviewRequest.propTypes = {
  uuid: string.isRequired,
  requestStatus: string.isRequired,
  isDepartmentRoutes: bool.isRequired,
};
