import {
  Button,
  Card, Flex, Spin, Tag, Tooltip,
} from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize, get, omit, uniq,
} from 'lodash';
import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiArrowRightThin, mdiChevronRight } from '@mdi/js';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useLogsAPI from '../../api/useLogsAPI';
import { getDeliveryPartition } from '../../redux/config/selectors';
import { daysToMilliseconds, diffDays } from '../../utils/dateTimeUtils';
import { maxCountOfVacationDays } from '../../pages/user/vacationsRequests/utils';
import LogTagByTypeOfChange from './commonComponents/LogTagByTypeOfChange';
import { getLogTagByType } from '../../constants/logConstantsAndUtils';
import { getFullNameOrInitials } from '../../utils/utils54origins';
import { allLogTypes, entityNamesForLogs } from './utils/logConstants';
import { SERVICE_PARTITION_NAME } from '../../api/app_config';
import { entityRead } from '../../api/actions/entity';
import CircleActorAvatar54origins from '../../54origins/components/CircleActorAvatar54origins';

export default function ListOfLogs({
  hiddenColumns = [],
  onSelectLog,
  cardClassName = '',
}) {
  const dispatch = useDispatch();

  const { getListOfLogs } = useLogsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [logs, setLogs] = useState([]);
  const [entities, setEntities] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 50,
    offset: 0,
  });

  const renderPeriod = (row) => {
    const startDate = get(row, 'start_date', '');
    const endDate = get(row, 'end_date', '');
    const status = get(row, 'status', '');
    const days = diffDays(startDate, endDate);
    const diffDate = daysToMilliseconds(days);
    return (
      <div className="flex items-center text-xs text-black">
        <div className={`mr-2 ${days > maxCountOfVacationDays && status !== 'approved' ? 'text-red-600' : 'text-green-600'} font-medium`}>
          {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
        </div>
        {dayjs(startDate).locale('ru').format('ddd DD-MM-YYYY')}
        <Icon path={mdiArrowRightThin} className="mx-1" size={0.6} />
        {dayjs(endDate).locale('ru').format('ddd DD-MM-YYYY')}
      </div>
    );
  };

  const columns = [{
    title: 'Время',
    width: '10%',
    dataIndex: ['created'],
    className: 'text-sm text-gray-500',
    render: (cell) => dayjs(cell).format('YYYY-MM-DD HH:mm:ss'),
  }, {
    title: 'Тип действия',
    width: '10%',
    dataIndex: ['params', 'type'],
    render: (cell) => <LogTagByTypeOfChange type={cell} />,
  }, {
    title: 'Тип объекта',
    width: '10%',
    dataIndex: ['affix_type'],
    render: (cell) => getLogTagByType(cell),
  }, {
    key: 'owner',
    title: 'Владелец',
    width: '10%',
    hidden: hiddenColumns?.includes('owner'),
    dataIndex: ['uinfo'],
    render: (cell, row) => {
      const parentEntity = entities?.find((el) => row?.entity_uuid === el?.uuid);
      if (!parentEntity) return null;
      const fullname = getFullNameOrInitials(parentEntity, 'fullname');
      const initials = getFullNameOrInitials(parentEntity, 'initials');

      return (
        <Tooltip
          title={fullname}
        >
          <Flex
            justify="center"
            align="center"
            style={{
              width: 25,
              height: 25,
              border: '1px solid black',
            }}
            className="!border-gray-500 !text-gray-500 text-sm !rounded-full"
          >
            {initials}
            {/* {fullname?.length > 40 ? (
            <Tooltip title={fullname}>
              {fullname?.slice(0, 37)}
              ...
            </Tooltip>
          ) : fullname} */}
          </Flex>
        </Tooltip>
      );
    },
  }, {
    title: 'Объект',
    width: '30%',
    dataIndex: ['name'],
    render: (cell, row) => {
      const parentEntity = entities?.find((el) => row?.entity_uuid === el?.uuid);
      const label = entityNamesForLogs?.[row?.affix_type];
      if (label === 'Заявка на сотрудника') {
        return label;
      }
      if (label === 'Отчет') {
        return (
          <Flex
            vertical
          >
            <span>
              Месячный отчет
            </span>
            <span className="text-xs">
              {capitalize(dayjs(parentEntity?.params?.date).format('MMMM YYYY'))}
            </span>
          </Flex>
        );
      }
      if (label === 'День') {
        return (
          <Flex
            vertical
          >
            <span>
              Дневной отчет
            </span>
            <span className="text-xs">
              {capitalize(dayjs(row?.params?.values?.rangeDates?.[0]).format('dddd, DD MMMM YYYY'))}
            </span>
          </Flex>
        );
      }
      if (label === 'Отпуск') {
        return (
          <Flex
            vertical
          >
            <span>
              Отпуск
              {renderPeriod(parentEntity?.params)}
            </span>
          </Flex>
        );
      }
      return parentEntity?.params?.title || 'Нет названия';
    },
  }, {
    title: 'Автор',
    width: '10%',
    dataIndex: ['owner'],
    render: (cell, row) => {
      const fullname = getFullNameOrInitials(row, 'fullname');
      const initials = getFullNameOrInitials(row, 'initials');

      return (
        <Tooltip
          title={fullname}
        >
          <Flex
            justify="center"
            align="center"
            style={{
              width: 25,
              height: 25,
              border: '1px solid black',
            }}
            className="!border-gray-500 !text-gray-500 text-sm !rounded-full"
          >
            {initials}
            {/* {fullname?.length > 40 ? (
            <Tooltip title={fullname} className="!text-gray-500">
              {fullname?.slice(0, 37)}
              ...
            </Tooltip>
          ) : fullname} */}
          </Flex>
        </Tooltip>
      );
    },
  }, {
    title: '',
    width: '10%',
    dataIndex: ['entity_uuid'],
    render: (cell, row) => (
      <Flex
        className="w-full"
        justify="end"
      >
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => {
            const parentEntity = entities?.find((el) => cell === el?.uuid);
            console.log(row, parentEntity);
            onSelectLog({
              log: row,
              entity: parentEntity,
            });
          }}
          className="!flex !items-center border-blue-500 text-blue-500 hover:!bg-sky-50"
        >
          <span className="mr-1 text-sm">
            Детали
          </span>
          <Icon path={mdiChevronRight} size={0.8} />
        </Button>
      </Flex>
    ),
  }].filter((el) => !el?.hidden);

  const initFunc = async () => {
    if (partitionUUID) {
      setIsFetching(true);

      const res = await getListOfLogs({
        parent: partitionUUID,
        affixTypes: allLogTypes,
        ...config,
      });

      setLogs(res?.data ?? []);
      setTotal(res?.total ?? 0);

      const logsEntityUUIDs = uniq(res?.data?.map((el) => el?.entity_uuid));
      if (logsEntityUUIDs?.length) {
        const listOfEntities = await dispatch(entityRead({
          data: {
            parent: partitionUUID,
            depth: 0,
            entity_uuids: logsEntityUUIDs,
          },
          constants: [
            'READ_LIST_OF_ENTITIES_REQUEST',
            'READ_LIST_OF_ENTITIES_SUCCESS',
            'READ_LIST_OF_ENTITIES_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }));
        console.log(listOfEntities);
        setEntities(listOfEntities ?? []);
      }
    }
    setIsFetching(false);
  };

  useEffect(() => {
    initFunc();
  }, [
    partitionUUID,
    JSON.stringify(config),
  ]);

  return (
    <Card
      className={cn(`h-max ${cardClassName}`)}
    >
      <Spin
        className="w-full h-max"
        spinning={isFetching}
      >
        <BaseTableWithPagination
          columns={columns}
          data={logs}
          rowKey="uuid"
          size="small"
          useCustomPagination
          disablePagination={false}
          rowClassName={() => 'cursor-pointer'}
          hideListSizeOption={false}
          pageSizeOptions={[10, 25, 50]}
          pageLimit={get(config, 'limit', 5)}
          total={total}
          changePageLimit={(newLimit) => {
            setCurrentPage(1);
            setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
          }}
          newCurrentPage={currentPage}
          onChangePageCallback={(newPage) => {
            setCurrentPage(newPage);
            setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
          }}
          getPaginationOptions={(newPart) => {
            const valueSearch = get(newPart, 'search', '');

            if (valueSearch) {
              setConfig((prev) => ({
                ...prev,
                ...omit(newPart, ['search']),
                searchData: {
                  value: valueSearch,
                  ignore_case: true,
                  fields: { params: ['title', 'id'] },
                },
              }));
            } else {
              setConfig((prev) => ({ ...omit(prev, 'searchData'), newPart }));
            }
          }}
        />
      </Spin>
    </Card>
  );
}
