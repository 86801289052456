import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { get } from 'lodash';
import {
  mdiCircle, mdiPencil,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Col, Flex, Input, Modal, Row, Tooltip,
} from 'antd';
import isoWeek from 'dayjs/plugin/isoWeek';
import {
  any, array, func, object, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { stopPropagation } from '../../../../54origins/utils54origins';
import { checkIsDayOff } from '../../../../utils/commonUtils';
import { isWorkingDay, translateTypeDay } from '../utils';
import { capitalizeFirstChar } from '../../../../utils';
import StartWorkCard from '../../../../components/layout/startWorkRecord/StartWorkCard';
import { getShowLunchBreak } from '../../../../redux/storages/selectors';
import useResize from '../../../../hooks/useResize';

const { TextArea } = Input;

const dayJs = dayjs.extend(isoWeek);

const workDays = ['office_work', 'remote_work', 'work_on_day_off'];

export default function ViewDay({
  updateDayCallback,
  currentDay,
  dataSource,
  defaultDate,
  getPrevDayCallback,
}) {
  const showLunchBreak = useSelector(getShowLunchBreak);

  const { isLess1400 } = useResize();

  const [isModalOpen, setModalOpen] = useState(false);

  const [modalDayOff, setModalDayOff] = useState(null);
  const [dayOffComment, setDayOffComment] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const startMonth = dayjs(defaultDate).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(defaultDate).endOf('month').format('YYYY-MM-DD');
  const dataFormat = dayJs(currentDay).format('YYYY-MM-DD');

  const data = useMemo(() => (
    dataSource.get(dataFormat)
  ), [dataSource]);

  const {
    start_date = dataFormat,
    end_date = dataFormat,
    type = '',
    comment = '',
    overtime = false,
  } = data || {};

  const defaultType = checkIsDayOff(currentDay) ? '' : 'report_not_sent';
  const isToDay = dataFormat === dayJs().format('YYYY-MM-DD');

  const isDayOfNotCurrentMonth = dayjs(start_date)
    .isBefore(dayjs(startMonth)) || dayjs(start_date).isAfter(endMonth);

  const getDayName = () => {
    const currentType = get(data, 'type', defaultType);
    if (checkIsDayOff(currentDay) && currentType === 'report_not_sent') {
      return 'Выходной';
    }
    if (workDays.includes(currentType)) {
      return (
        <>
          <Flex>{translateTypeDay[get(data, 'type', defaultType)]}</Flex>
          <Flex justify="center">
            (
            {get(data, 'hoursWorked', '0h00m')}
            )
          </Flex>
        </>
      );
    }
    return translateTypeDay[get(data, 'type', defaultType)];
  };

  const updateWorkRecord = (workData = {}) => {
    if (updateDayCallback) {
      updateDayCallback?.(workData?.kindOfDay, start_date, {
        ...workData?.timeState,
        ...workData?.extraOptions,
        comment: workData?.dailyPlanningText,
        wasChoiceOfDay: true,
      });
    }
    setModalOpen(false);
  };

  const showDeleteWarning = (okCallback) => {
    Modal.warning({
      title: 'Внимание!',
      content: `Все данные о дне ${dataFormat} будут удалены. Вы уверены, что хотите продолжить?`,
      onOk: () => {
        updateDayCallback('report_not_sent', start_date);
        if (okCallback) {
          okCallback?.();
        }
      },
      maskClosable: true,
      closable: true,
      okText: 'Продолжить',
      centered: true,
    });
  };

  const showChangeDayModal = (dayType = '') => {
    switch (dayType) {
      case 'dayoff': {
        setModalDayOff({
          title: 'Создание отгула',
          textButtonOk: 'Создать отгул',
          type: dayType,
        });
        break;
      }
      case 'dayoff_update': {
        setModalDayOff({
          title: 'Редактирование отгула',
          textButtonOk: 'Сохранить',
          type: 'dayoff',
          showDelete: true,
        });
        setDayOffComment(comment);
        break;
      }
      default:
    }
  };

  const onCancelDayOff = () => {
    setModalDayOff(null);
    setDayOffComment('');
  };

  const onCreateDayOff = () => {
    if (updateDayCallback && modalDayOff) {
      updateDayCallback?.(modalDayOff?.type, start_date, {
        comment: dayOffComment,
      });
    }
    onCancelDayOff();
  };

  const onDeleteDayOff = () => {
    showDeleteWarning(onCancelDayOff);
  };

  const getTitleForDayOff = () => {
    if (type === 'dayoff') {
      return (
        <>
          {comment?.length ? (
            <>
              <Flex className="w-100">Комментарий:</Flex>
              <span
                style={{
                  textWrap: 'balance',
                }}
              >
                {comment}
              </span>
            </>
          )
            : null}
          <p>
            * Повтороное нажатие откроет окно редактирования
          </p>
        </>

      );
    }
    return 'Добавить отгул';
  };

  return (
    <>
      <Row
        className={cn(
          'wrapper_day_calendar h-full flex',
          {
            isReportNotSent: type === 'report_not_sent' || !type || isDayOfNotCurrentMonth,
            isDayoff: type === 'dayoff'
            || type === 'localHoliday'
            || type === 'projectDayOff'
            || type === 'sickday'
            || type === 'holidays',
            isBench: type === 'bench_1_month'
            || type === 'bench_over_1_month'
            || type === 'bench'
            || type === 'bench_over_3_month',
            isVacation: type === 'vacation',
            customWorkTime: overtime && isWorkingDay(type),
            isToDay,
            oldDay: dataFormat < dayJs().format('YYYY-MM-DD'),
          },
        )}
        role="button"
        tabIndex={0}
        onClick={stopPropagation}
        style={{ height: '130px' }}
      >
        <div className="inline day_calendar z-20">
          {isToDay ? (
            <div className="relative">
              <Icon size={1.5} path={mdiCircle} className="absolute top-[-5px] left-[-10px]" />
              <span className="absolute top-[-1px] left-[-3px] font-medium text-white">
                {dayJs(currentDay).format('DD')}
              </span>
            </div>
          )
            : dayJs(currentDay).format('DD')}
        </div>
        {!isDayOfNotCurrentMonth && (
        <div className="absolute z-20 top-[4px] right-[4px]">
          <Row className="d-flex justify-content-end">
            <Tooltip
              title="Добавить больничный"
              color="#803721"
            >
              <Col
                className={`ml-1 day-off-button sickday-button${type === 'sickday' ? '-selected' : ''}`}
                onClick={() => {
                  if (type !== 'sickday') {
                    updateDayCallback('sickday', start_date);
                  } else {
                    showDeleteWarning();
                  }
                }}
              >
                Б
              </Col>
            </Tooltip>
            <Tooltip
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              title={getTitleForDayOff()}
              placement="right"
              overlayInnerStyle={{
                width: 'fit-content',
                maxWidth: 450,
              }}
              color="#ff0000"
            >
              <Col
                className={`ml-1 day-off-button dayoff-button${type === 'dayoff' ? '-selected' : ''}`}
                onClick={() => {
                  if (type !== 'dayoff') {
                    showChangeDayModal('dayoff');
                    // updateDayCallback('dayoff', start_date);
                  } else {
                    showChangeDayModal('dayoff_update');
                    // showDeleteWarning();
                  }
                }}
              >
                {isHovered && type === 'dayoff' ? <Icon path={mdiPencil} size={0.8} /> : 'О'}
              </Col>
            </Tooltip>
          </Row>
        </div>
        )}
        {overtime && (
        <div className="absolute bottom-[-2px] right-[4px] text-sm z-0">Overtime</div>
        )}

        {!isDayOfNotCurrentMonth && (
        <div className="flex items-center justify-center flex-grow">
          <span className="text-center leading-tight">
            {getDayName()}
          </span>
        </div>
        )}
        {!isDayOfNotCurrentMonth && (
        <div className="absolute z-20 bottom-[4px] left-[4px]">
          <Row className="d-flex justify-content-end">
            <Tooltip
              title="Рабочая запись"
              color="#038fde"
            >
              <Col
                className="work-button day-off-button"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                <Icon path={mdiPencil} size={0.8} />
              </Col>
            </Tooltip>
          </Row>
        </div>
        )}
        {!isDayOfNotCurrentMonth && !isLess1400 && (
        <div className="absolute z-20 bottom-[4px] right-[4px]">
          <Row className="d-flex justify-content-end">
            <Tooltip
              title="Бенч до 1-го месяца"
              color="#FF8C00"
            >
              <Col
                className={`bench-button${type === 'bench_1_month' ? '-selected' : ''}`}
                onClick={() => {
                  if (type !== 'bench_1_month') {
                    updateDayCallback('bench_1_month', start_date);
                  } else {
                    showDeleteWarning();
                  }
                }}
              >
                1Б
              </Col>
            </Tooltip>
            <Tooltip
              title="Бенч больше 1-го месяца"
              color="#FF8C00"
            >
              <Col
                className={`ml-1 bench-button${type === 'bench_over_1_month' ? '-selected' : ''}`}
                onClick={() => {
                  if (type !== 'bench_over_1_month') {
                    updateDayCallback('bench_over_1_month', start_date);
                  } else {
                    showDeleteWarning();
                  }
                }}
              >
                +1Б
              </Col>
            </Tooltip>
            <Tooltip
              title="Бенч больше 3-х месяцев"
              color="#FF8C00"
            >
              <Col
                className={`ml-1 bench-button${type === 'bench_over_3_month' ? '-selected' : ''}`}
                onClick={() => {
                  if (type !== 'bench_over_3_month') {
                    updateDayCallback('bench_over_3_month', start_date);
                  } else {
                    showDeleteWarning();
                  }
                }}
              >
                +3Б
              </Col>
            </Tooltip>
          </Row>
        </div>
        )}
      </Row>

      <Modal
        title={capitalizeFirstChar(dayjs(currentDay).format('dddd, DD MMMM YYYY'))}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={() => setModalOpen(false)}
        width={950}
        destroyOnClose
      >
        <div className="w-100">
          <StartWorkCard
            getPrevDayCallback={getPrevDayCallback}
            showDeleteButton={type !== 'report_not_sent'}
            showLunchBreak={showLunchBreak}
            defaultData={data}
            isStartWorkRecord={false}
            currentDay={dayjs(currentDay).format('YYYY-MM-DD')}
            titleSaveBtn="Сохранить"
            updateDayCallback={updateWorkRecord}
            deleteCallback={() => {
              updateDayCallback('report_not_sent', start_date);
              setModalOpen(false);
            }}
          />
        </div>
      </Modal>

      <Modal
        title={modalDayOff?.title}
        open={modalDayOff}
        destroyOnClose
        footer={null}
        onCancel={onCancelDayOff}
        centered
      >
        <span className="text-secondary">Комментарий:</span>
        <TextArea
          value={dayOffComment}
          autoSize={{ minRows: 4 }}
          maxLength={1000}
          onChange={(e) => setDayOffComment(e.target.value)}
        />
        <Flex
          justify="flex-end"
          className="mt-2"
        >
          {modalDayOff?.showDelete ? (
            <Button
              className="btnDanger mr-3"
              onClick={onDeleteDayOff}
            >
              Удалить день
            </Button>
          ) : null}
          <Button
            className="mr-3"
            onClick={onCancelDayOff}
          >
            Отмена
          </Button>
          <Button
            className="btnGreen mr-1"
            onClick={onCreateDayOff}
          >
            {modalDayOff?.textButtonOk}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

ViewDay.propTypes = {
  getPrevDayCallback: func,
  updateDayCallback: func,
  currentDay: string,
  dataSource: object,
  defaultDate: any,
};
