import i18next from 'i18next';
import _ from 'lodash';
import Cookies from 'universal-cookie';

import i18n from '../i18n/i18n';
import { capitalizeFirstChar } from '../utils';
import antNotification from '../utils/antNotification';

export const getActionCreatorFromConstantWithEmptyPayload = (constant) => (typeof constant === 'object'
  ? constant
  : {
    type: typeof constant === 'function' ? constant?.type : constant,
    payload: [],
  });

export const getToken = (service = 'Entity') => {
  const cookies = new Cookies();

  return cookies.get(service);
};

const parseError = (error) => {
  const status = _.get(error, 'response.status', 500);
  const message = _.get(error, 'response.data.error_message', '');
  const errorContent = _.get(error, 'response.data.error_content', []);

  return {
    message,
    status,
    errorContent,
  };
};

export function logoutUserAndClearAllCookies(callback) {
  try {
    const cookies = new Cookies();
    const allCookies = cookies.getAll();
    _.map(allCookies, (value, key) => {
      cookies.remove(key);
    });
    callback();
  } catch (e) {
    console.log('Something wrong with cookies', e);
  }
}

export const handleError = (error) => {
  const { status, message, errorContent } = parseError(error);

  switch (status) {
    case 400:
      if (message) {
        antNotification.error(message);
      }
      if (errorContent.length > 0) {
        const messages = errorContent
          .map(({ field, message: msg }) => capitalizeFirstChar(
            `${i18next.t(
              `app.headers.${field}`,
              field,
            )}: ${msg}`,
          ))
          .join('\t');
        antNotification.error(messages);
      }
      break;
    case 401:
      if (message) {
        antNotification.error(message);
      }
      logoutUserAndClearAllCookies(() => {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      });
      break;
    case 403:
      antNotification.error(
        capitalizeFirstChar(
          i18n.t(
            'app.notifications.forbidden',
            'access rights denied',
          ),
        ),
      );
      break;
    case 430:
      break;

    default:
      break;
  }
};
