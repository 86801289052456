import { Tag } from 'antd';
import React from 'react';

export const allLogTypes = [
  'monthlyReportLog',
  'monthlyReportDayLog',
  'vacationLog',
  'departmentLog',
  'instructionLog',
  'fileLog',
  'userLog',
];

export const getLogNameByType = (type) => {
  switch (type) {
    case 'monthlyReportLog': return 'Отчет';
    case 'monthlyReportDayLog': return 'День';
    case 'vacationLog': return 'Отпуск';
    case 'departmentLog': return 'Отдел';
    case 'instructionLog': return 'Инструкция';
    case 'fileLog': return 'Файл';
    case 'userLog': return 'Подрядчик';
    default: return '';
  }
};

export const getLogTagByType = (type) => {
  switch (type) {
    case 'monthlyReportLog': return (
      <Tag
        color="#108ee9"
        style={{
          boxShadow: '0 10px 15px -3px #1890ff33,0 4px 6px -2px #1890ff1a !important',
        }}
        // bordered={false}
      >
        Отчет
      </Tag>
    );
    case 'monthlyReportDayLog': return (
      <Tag
        color="#2db7f5"
        className="!shadow"
        // bordered={false}
      >
        День
      </Tag>
    );
    case 'vacationLog': return (
      <Tag
        color="#f50"
        className="!shadow"
        // bordered={false}
      >
        Отпуск
      </Tag>
    );
    case 'departmentLog': return (
      <Tag
        color="#87d068"
        className="!shadow"
        // bordered={false}
      >
        Отдел
      </Tag>
    );
    case 'fileLog': return (
      <Tag
        color="#87d068"
        className="!shadow"
        // bordered={false}
      >
        Файл
      </Tag>
    );
    case 'instructionLog': return (
      <Tag
        color="#87d068"
        className="!shadow"
        // bordered={false}
      >
        Инструкция
      </Tag>
    );
    case 'employeeRequestLog': return (
      <Tag
        color="#87d068"
        className="!shadow"
        // bordered={false}
      >
        Заявка
      </Tag>
    );
    default: return '';
  }
};
