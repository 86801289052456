import {
  Card, Empty, Spin, Tabs,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, concat } from 'lodash';
import { useSelector } from 'react-redux';
import PermissionsContext from '../../context/PermissionsContext';
import { getFetchingForPSP, getPermsForPSP, getTotalForPSP } from '../../redux/permissions/permissionsSelector';
import useURLParams from '../../hooks/useURLParams';
import ListPermissions from './ListPermissions';

const { TabPane } = Tabs;

const hiddenDataViewingListPerm = ['columnEntityName', 'columnDeletePerm'];

const templateUsersWithoutGroup = {
  disabled: true,
  actorName: 'Users without group',
  actor_type: 'group',
  actor_uuid: 'USER_WITHOUT_GROUP',
  entity_uuid: '',
  perm_uuid: '',
  uinfo: { group_name: 'USER_WITHOUT_GROUP', weight: 0 },
  create: false,
  delete: false,
  list: false,
  read: false,
  set: false,
  update: false,
};

function ViewingPermsCard() {
  const { t } = useTranslation();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();
  const { activeActorPerm, activeProject } = getURLParams();

  const {
    entityUUID,
    getEntityPerms,
    updatePermissions,
    resetFromMainContainer,
  } = useContext(PermissionsContext);

  const {
    isFetchingDefaultGroups,
    isFetchingOtherGroups,
    isFetchingPersonalUsers,
  } = useSelector(getFetchingForPSP);
  const {
    defaultGroups,
    otherGroups,
    personalUsers,
  } = useSelector(getPermsForPSP);
  const {
    otherGroupsTotal,
    personalUsersTotal,
  } = useSelector(getTotalForPSP);

  const allDefaultGroups = defaultGroups?.map((el) => {
    if (el?.uinfo?.group_name === 'ADMIN') {
      return {
        ...el,
        create: true,
        delete: true,
        list: true,
        read: true,
        set: true,
        update: true,
      };
    }
    return el;
  });

  const [selectedTab, setSelectedTab] = useState('entity');

  const [otherGroupsConfig, setOtherGroupsConfig] = useState({
    actor_type: ['group'],
    limit: 15,
    offset: 0,
  });
  const [personalUsersConfig, setPersonalUsersConfig] = useState({
    actor_type: ['classic_user', 'user'],
    limit: 20,
    offset: 0,
  });

  const onSelectRow = (row) => {
    const params = {
      activeActorPerm: row.actor_uuid,
      typeActor: row.actor_type,
      activeCard: 'permissions',
    };

    if (activeProject) {
      params.activeProject = activeProject;
    }

    setSearchParams(params);
  };

  const selectRowTable = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectColumn: true,
    classes: activeActorPerm && 'row-perm-selected',
    selectedRowKeys: [activeActorPerm],
  };

  const changeSwitchCallback = (defaultItem, currentItem, field) => {
    updatePermissions({
      data: [{
        actor: currentItem.actor_uuid,
        [field]: currentItem[field],
      }],
      entity_uuid: entityUUID,
    });
  };

  const showListPermissions = (dataTable = [], title, {
    showButtonShowMore = false,
    onShowMoreCallback,
    showSearch = false,
    onSearchCallback,
  }) => (
    <ListPermissions
      globalDisabled
      selectRow={selectRowTable}
      onSearchCallback={onSearchCallback}
      onShowMoreCallback={onShowMoreCallback}
      showSearch={showSearch}
      showButtonShowMore={showButtonShowMore}
      onSelectRowCallback={onSelectRow}
      changeSwitchCallback={changeSwitchCallback}
      hiddenData={hiddenDataViewingListPerm}
      dataTable={dataTable}
      cardName="entityCard"
      title={title}
    />
  );

  const getPersonalUsersPerms = ({
    withClear = false,
    params = {},
  }) => {
    getEntityPerms({
      params: {
        ...personalUsersConfig,
        ...params,
      },
      type: 'personalUsers',
      withClear,
    });
    setPersonalUsersConfig((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const getOtherGroupsPerms = ({
    withClear = false,
    params = {},
  }) => {
    getEntityPerms({
      params: {
        ...otherGroupsConfig,
        ...params,
      },
      type: 'otherGroups',
      withClear,
    });
    setOtherGroupsConfig((prev) => ({
      ...prev,
      ...params,
    }));
  };

  const resetAll = () => {
    getOtherGroupsPerms({
      withClear: true,
      params: {
        limit: 15,
        offset: 0,
      },
    });
    getPersonalUsersPerms({
      withClear: true,
      params: {
        limit: 20,
        offset: 0,
      },
    });
  };

  const setSelectedTabCallback = (tab) => {
    if (activeActorPerm) {
      clearSearchParams(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
    }
    setSelectedTab(tab);
    resetAll();
  };

  const listPermissions = (
    <div className="mt-4">
      <Spin spinning={isFetchingDefaultGroups}>
        {showListPermissions(
          selectedTab === 'entity' ? allDefaultGroups : concat(allDefaultGroups, [templateUsersWithoutGroup]),
          capitalize('Built-in groups'),
          {
            showButtonShowMore: false,
          },
        )}
      </Spin>

      <Spin spinning={isFetchingOtherGroups}>
        {showListPermissions(
          otherGroups?.filter((el) => !['ADMIN', 'BAN', 'DEFAULT'].includes(el?.uinfo?.group_name)),
          capitalize(t('wms.permissions.headers.Groups', 'Groups')),
          {
            showButtonShowMore: otherGroupsTotal > otherGroups?.length,
            onShowMoreCallback: () => getOtherGroupsPerms({
              params: {
                limit: 50,
                offset: otherGroupsConfig.offset + otherGroupsConfig.limit,
              },
            }),
            showSearch: true,
            onSearchCallback: (value) => {
              if (value) {
                getOtherGroupsPerms({
                  withClear: true,
                  params: {
                    limit: 15,
                    offset: 0,
                    searchData: {
                      value,
                      ignore_case: true,
                      fields: { params: ['title', 'id'] },
                    },
                  },
                });
              } else {
                getOtherGroupsPerms({
                  withClear: true,
                  params: {
                    limit: 15,
                    offset: 0,
                  },
                });
              }
            },
          },
        )}
      </Spin>

      <Spin spinning={isFetchingPersonalUsers}>
        {showListPermissions(
          personalUsers,
          capitalize('Users with personal permissions'),
          {
            showButtonShowMore: personalUsersTotal > personalUsers?.length,
            onShowMoreCallback: () => getPersonalUsersPerms({
              params: {
                limit: 50,
                offset: personalUsersConfig.offset + personalUsersConfig.limit,
              },
            }),
          },
        )}
      </Spin>

      {(
        selectedTab === 'entity'
        && !(allDefaultGroups?.length + otherGroups?.length + personalUsers?.length)
      ) && (
      <Empty
        description={t('wms.permissions.body.no_entity_perm', 'No permissions set for this entity')}
      />
      )}
    </div>
  );

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (resetFromMainContainer) {
      getOtherGroupsPerms({
        withClear: true,
      });
      getPersonalUsersPerms({
        withClear: true,
      });
    }
  }, [resetFromMainContainer]);

  return (
    <Card className="primaryCard !w-full mt-3">
      <span className="text-2xl -mt-3 font-medium">Entity permissions</span>
      <Tabs
        className="mt-3"
        onChange={setSelectedTabCallback}
        type="card"
        activeKey={selectedTab}
      >
        <TabPane tab="Personal permissions" key="entity">
          {listPermissions}
        </TabPane>
        <TabPane tab="All permissions" key="all">
          {listPermissions}
        </TabPane>
      </Tabs>

    </Card>
  );
}

export default ViewingPermsCard;
