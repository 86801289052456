import React, { useState } from 'react';

import {
  Alert,
  Badge,
  Button,
  Col,
  Descriptions,
  Modal,
  Row,
  Typography,
} from 'antd';
import { capitalize } from 'lodash';

import { SendOutlined } from '@ant-design/icons';
import { func, object } from 'prop-types';
import antNotification from '../../../../utils/antNotification';
import { diffDays, formatDate } from '../../../../utils/dateTimeUtils';

import useAPI from '../../../../api/useAPI';
import useVacationsAPI from '../../../../api/useVacationsAPI';

const { Text } = Typography;

export default function RequestVacation({ row, buttonProps, updateCallback }) {
  const { updateVacation } = useVacationsAPI();

  const [isModalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => setModalOpen(true);

  const onCancelModal = () => setModalOpen(false);

  const handleOk = async () => {
    await updateVacation(row.uuid, { params: { status: 'pending' } });

    antNotification.success('Запрос на отпуск успешно отправлен.');
    updateCallback();
    onCancelModal();
  };

  return (
    <>
      <Button
        onClick={() => onOpenModal()}
        icon={<SendOutlined />}
        {...buttonProps}
      >
        {capitalize('отправить на рассмотрение')}
      </Button>
      <Modal
        title={capitalize('отправить заявку на рассмотрение отпуска?')}
        open={isModalOpen}
        onCancel={() => onCancelModal()}
        destroyOnClose
        width={750}
        onOk={() => handleOk()}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert
              message={(
                <Text>
                  Срок рассмотрения и согласования заявок на
                  отпуск осуществляется администратором и
                  {' '}
                  <b>занимает до 14 дней</b>
                  .
                </Text>
              )}
              type="warning"
              showIcon
            />
          </Col>
          <Col span={24}>
            <Descriptions bordered>
              <Descriptions.Item label="Дата начала:" span={12}>
                {formatDate(row.params.start_date)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Дата окончания:"
                span={12}
              >
                {formatDate(row.params.end_date)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Количество дней:"
                span={12}
              >
                <Badge
                  count={diffDays(
                    row.params.start_date,
                    row.params.end_date,
                  )}
                />
              </Descriptions.Item>

              <Descriptions.Item
                label="Комментарий:"
                span={12}
              >
                {row.params.comment || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

RequestVacation.propTypes = {
  row: object.isRequired,
  buttonProps: object,
  updateCallback: func,
};
