import React, { useEffect, useState } from 'react';
import { Divider, Flex, Table } from 'antd';
import { object } from 'prop-types';
import { capitalize, head } from 'lodash';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeftBold, mdiArrowRightBold } from '@mdi/js';
import useAPI from '../../../../api/useAPI';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import { isWorkingDay, translateTypeDay } from '../../../user/dayOff/utils';
import { convertTimeFormatToHours } from '../department/utils';
import DaysCalendar from './DaysCalendar';

function convertTimeFormat(input) {
  const regex = /(\d+)h(\d+)m/;
  const match = input?.match?.(regex);

  if (match) {
    const hours = match[1].padStart(2, '0');
    const minutes = match[2].padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return '';
}

export default function UserArtifactView({
  artifact,
}) {
  const dispatch = useDispatch();

  const {
    getMonthlyReport,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    uuid,
    actor,
  } = artifact || {};

  const [currentMonth, setCurrentMonth] = useState({});
  const [reports, setReports] = useState([]);
  const [today, setToday] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));

  const handlePrevMonth = () => {
    setSelectedMonth(dayjs(selectedMonth).subtract(1, 'month').format('YYYY-MM'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(dayjs(selectedMonth).add(1, 'month').format('YYYY-MM'));
  };

  const columns = [
    {
      title: 'Месяц',
      dataIndex: ['params', 'date'],
      render: (cell) => capitalize(dayjs(cell).format('MMMM')),
    },
    {
      title: 'Проектных часов',
      dataIndex: ['params', 'statistics', 'totalWorks'],
      className: '!bg-green-100',
    },
    {
      title: 'Клиентский пр.',
      dataIndex: ['params', 'statistics', 'clientWork'],
    },
    {
      title: 'Внутренний пр.',
      dataIndex: ['params', 'statistics', 'internalWork'],
    },
    {
      title: 'Бенч',
      className: '!bg-orange-100',
      dataIndex: ['params', 'statistics', 'totalBenches'],
      render: (cell) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{cell}</span>,
    },
    {
      className: '!bg-red-100',
      title: 'Нерабочих часов',
      dataIndex: ['params', 'statistics', 'totalDayOff'],
      render: (cell) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{cell}</span>,
    },
    {
      title: 'Отгул',
      dataIndex: ['params', 'statistics', 'dayoff'],
      render: (cell) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{cell}</span>,
    },
    {
      title: 'Больничный',
      dataIndex: ['params', 'statistics', 'sickday'],
      render: (cell) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{cell}</span>,
    },
    {
      title: 'Отпуск',
      dataIndex: ['params', 'statistics', 'vacation'],
      render: (cell) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{cell}</span>,
    },
  ];

  const initFuncAllReports = async () => {
    const res = await getMonthlyReport(uuid, {
      actor,
      depth: 0,
      orderBy: 'params.date',
      order: 'asc',
      params: {
        date: [
          dayjs().format('YYYY-MM'),
          dayjs().subtract(1, 'month').format('YYYY-MM'),
          dayjs().subtract(2, 'month').format('YYYY-MM'),
          dayjs().subtract(3, 'month').format('YYYY-MM'),
          dayjs().subtract(4, 'month').format('YYYY-MM'),
          dayjs().subtract(5, 'month').format('YYYY-MM'),
          dayjs().subtract(6, 'month').format('YYYY-MM'),
        ],
      },
    });

    const newCurrentMonth = res?.data?.find((el) => el?.params?.date === dayjs().format('YYYY-MM'));
    const todayReport = newCurrentMonth?.params?.days?.find((el) => head(el?.rangeDates) === dayjs().format('YYYY-MM-DD'));

    setToday(todayReport);
    setReports(res?.data?.filter((el) => el?.params?.date !== dayjs().format('YYYY-MM')));
  };

  const initFuncCalendar = async () => {
    const res = await getMonthlyReport(uuid, {
      actor,
      depth: 0,
      orderBy: 'params.date',
      order: 'asc',
      params: {
        date: selectedMonth,
      },
    });

    const newCurrentMonth = res?.data?.find((el) => el?.params?.date === selectedMonth);

    setCurrentMonth(newCurrentMonth);
  };

  useEffect(() => {
    if (partitionUUID && actor) {
      initFuncAllReports();
    }
  }, [partitionUUID, actor]);

  useEffect(() => {
    if (partitionUUID && actor && selectedMonth) {
      initFuncCalendar();
    }
  }, [partitionUUID, actor, selectedMonth]);

  return (
    <Flex
      vertical
      className="w-full h-full"
    >
      <Flex>
        <Flex
          align="center"
          className="mx-12"
        >
          <span
            className="mr-2 text-base"
          >
            Имя:
          </span>
          <span
            className="text-blue-500"
          >
            {artifact?.uinfo?.first_name}
            {' '}
            {artifact?.uinfo?.last_name}
          </span>
        </Flex>
      </Flex>
      <Divider className="my-2" />
      <Flex
        className="mx-12"
        align="start"
      >
        <span
          className="mr-2 text-base"
        >
          Дневной отчет (сегодня):
        </span>
        {today
          ? (
            <Flex
              vertical
              className="mr-6 text-base text-blue-500"
            >
              <span
                className={cn('', {
                  'text-red-500': !isWorkingDay(today?.type),
                })}
              >
                {translateTypeDay[today?.type]}
                {(today?.startWork || today?.finishWork || today?.hoursWorked) && (
                <>
                  {' '}
                  {': '}
                  {convertTimeFormat(today?.startWork)}
                  -
                  {convertTimeFormat(today?.finishWork)}
                  {' ('}
                  {convertTimeFormatToHours(today?.hoursWorked)}
                  {') '}
                </>
                )}
              </span>
            </Flex>
          )
          : (
            <span
              className="mr-2 text-base text-red-500"
            >
              Нет отчета
            </span>
          )}
      </Flex>
      <Divider className="my-2" />
      <Flex
        className="mx-12 w-full"
        align="start"
        vertical
      >
        <Flex
          className="mb-3 text-base"
          align="center"
        >
          Календарь за
          {' '}

          <Flex
            align="center"
            className="mt-0 text-xl"
          >
            <Icon
              className="mx-2 cursor-pointer text-blue-500 hover:bg-sky-100"
              onClick={handlePrevMonth}
              style={{
                // border: '1px solid white',
              }}
              path={mdiArrowLeftBold}
              size={1.2}
            />
            <Flex
              justify="center"
              className="text-xl text-blue-500"
              style={{
                width: 130,
              }}
            >
              {capitalize(dayjs(selectedMonth).format('MMMM YYYY'))}
            </Flex>
            <Icon
              className="ml-2 cursor-pointer text-blue-500 hover:bg-sky-100"
              onClick={handleNextMonth}
              path={mdiArrowRightBold}
              size={1.2}
            />
          </Flex>
          {/* {capitalize(dayjs().format('MMMM, YYYY'))}
          : */}
        </Flex>
        <Flex className="w-full">
          <DaysCalendar
            data={currentMonth?.params?.days}
            month={selectedMonth}
          />
        </Flex>
      </Flex>
      <Divider className="my-2" />
      <Flex
        className="w-full"
        align="start"
        vertical
      >
        <span
          className="mx-12 mb-3 text-base"
        >
          Месячные отчеты за 6 месяцев:
        </span>
        <Table
          rowKey={(row) => row?.actor?.uuid || row}
          columns={columns}
          className="w-full max-w"
          dataSource={reports}
          size="small"
          pagination={false}
        />
      </Flex>
    </Flex>
  );
}

UserArtifactView.propTypes = {
  artifact: object,
};
