import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { capitalize } from 'lodash';
import UsersCard from './usercCard/UsersCard';
import UserDashboard from './UserDashboard';
import PageWrapper from '../../../components/PageWrapper';

export default function UserDashboardRoot() {
  return (
    <PageWrapper
      title={capitalize('Юзер дашборд')}
    >
      <Switch>
        <Route
          path="/userdashboard/dashboard"
          render={() => <UserDashboard />}
        />
        <Route
          path="/userdashboard"
          render={() => (<UsersCard />)}
        />
      </Switch>
    </PageWrapper>
  );
}
