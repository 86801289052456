import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';

import {
  Button,
  Card, Col, Flex, Modal, Segmented, Space, Spin, Tooltip,
} from 'antd';
import {
  capitalize, get, isEqual, omit,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getFetchingVacationList } from '../../../redux/userVacation/selectors';
import useAPI from '../../../api/useAPI';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { getAllVacations, getAllVacationsCount, getIsFetchingVacating } from '../../../redux/vacations/selectors';
import { VACATION_STATUSES, requestOptionsByModified } from '../../user/vacationsRequests/utils';
import TableVacations from '../../user/vacationsRequests/TableVacations';
import { hideColumnsVacations } from './utils';
import { globalGetDataKey } from '../../../redux/globalUpdate/selectors';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import DateSwitcher from '../../../components/dateSwitcher/DateSwitcher';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import { actorsMap } from '../../../redux/actors/selectors';
import CopyURL from '../../../components/commonComponents/CopyURL';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import RejectedUserVacation from './modals/RejectedUserVacation';
import ApprovedUserVacation from './modals/ApprovedUserVacation';
import { getFullNameOrInitials, stopPropagation } from '../../../utils/utils54origins';
import { diffDays } from '../../../utils/dateTimeUtils';
import VacationTimeStatus from './VacationTimeStatus';
import antNotification from '../../../utils/antNotification';
import UserDayOffHistory from './modals/UserDayOffHistory';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import EditVacation from '../../user/vacationsRequests/components/EditVacation';
import useVacationsAPI from '../../../api/useVacationsAPI';
import VacationConstants from '../../../redux/vacations/constants';

function UsersAgreedVacationsRequestCard() {
  const dispatch = useDispatch();

  const { getListOfVacations } = useVacationsAPI();

  const {
    deleteVacation,
  } = useVacationsAPI();

  const isFetching = useSelector(getIsFetchingVacating);
  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const myProfileData = useSelector(getProfileInfo);
  const getGlobalGetDataKey = useSelector(globalGetDataKey);
  const actors = useSelector(actorsMap);
  const vacations = useSelector(getAllVacations);
  const vacationsTotal = useSelector(getAllVacationsCount);

  const [selectedUser, setSelectedUser] = useState('');
  const [dates, setDates] = useState({
    startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
    endDate: dayjs().endOf('year').format('YYYY-MM-DD'),
  });
  const [config, setConfig] = useState({
    limit: 25,
    offset: 0,
    order: 'desc',
    order_by_params: 'start_date',
  });
  const [selectedStatuses, setStatuses] = useState(['approved']);
  const [timeStatus, setTimeStatus] = useState('');
  const [needResetPagination, setNeedResetPagination] = useState(false);

  const optionsFilter = useMemo(() => [
    'all',
    ...VACATION_STATUSES.filter((i) => i !== 'created' && i !== 'pending'),
  ].map((v) => ({
    label: capitalize(v),
    value: v === 'all' ? '' : v,
  })), []);

  const getAgreedList = () => {
    const configRequest = {
      params: {
        rangeDates: enumerateDaysBetweenDates(
          dayjs(dates.startDate).format('YYYY-MM-DD'),
          dayjs(dates.endDate).format('YYYY-MM-DD'),
        ),
        status: selectedStatuses,
      },
      ...config,
    };

    if (timeStatus === 'current') {
      configRequest.params.rangeDates = [dayjs().format('YYYY-MM-DD')];
    }

    getListOfVacations(rootPartitionUuid, configRequest, [
      VacationConstants.GET_VACATIONS_REQUEST,
      VacationConstants.GET_VACATIONS_SUCCESS,
      VacationConstants.GET_VACATIONS_FAILURE,
    ]);
  };

  const savePaginationConfigCallback = (data) => {
    setConfig((prev) => ({ ...prev, ...data }));
  };

  const currentSortCallback = (data) => {
    setConfig((prev) => ({
      ...omit(prev, ['order_by', 'order_by_params']),
      order: data.order,
      [data.orderBy === 'created' ? 'order_by' : 'order_by_params']: data.orderBy,
    }));
  };

  const onChangeDate = (date) => {
    setDates({
      startDate: dayjs(date).startOf('year').format('YYYY-MM-DD'),
      endDate: dayjs(date).endOf('year').format('YYYY-MM-DD'),
    });
  };

  const rowClassName = (record) => {
    switch (record?.params?.status) {
      case 'approved': {
        const today = dayjs();
        const rangeDates = record?.params?.rangeDates;
        const start_date = record?.params?.start_date;
        if (rangeDates?.includes(today.format('YYYY-MM-DD'))) {
          return 'approved-background';
        } if (today.isSameOrBefore(dayjs(start_date))) {
          return 'upcoming-background';
        }
        break;
      }
      case 'rejected': {
        return 'rejected-background';
      }
      default: return '';
    }
  };

  const onDeleteVacation = (uuid) => {
    if (!uuid) return;
    Modal.confirm({
      title: 'Удаление отпуска',
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: 'Вы уверены, что хотите удалить этот отпуск?',
      okText: 'Да',
      cancelText: 'Нет',
      async onOk() {
        if (vacationsTotal - 1 === config.offset) {
          setConfig((prev) => ({ ...prev, offset: 0 }));
          dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: 'agreedVacationRequests' });
          return;
        }
        await deleteVacation(uuid);
        antNotification.success('Отпуск удален.');
        getAgreedList();
      },
      onCancel() { },
    });
  };

  const onRow = (record, rowIndex) => ({
    onClick: () => {
      setSelectedUser({
        uinfo: record.uinfo,
        actor: record.actor,
      });
    },
  });

  const getColumnsCallback = useCallback((defaultColumns) => {
    const data = defaultColumns.reduce(((acc, i) => {
      if (i?.key === 'status') {
        return [
          {
            dataIndex: 'uuid',
            width: 20,
            title: '',
            render: (uuid) => (
              <CopyURL
                entityType="vacation"
                entityUUID={uuid}
              />
            ),
          },
          {
            title: (
              <Col className="d-flex align-item-end minWidth130" span={24}>
                <AntDesignSearchBox54origins
                  onSearch={(value) => setConfig((prev) => ({
                    ...prev,
                    offset: 0,
                    search_data: {
                      fields: {
                        uinfo: {
                          first_name: 'first_name',
                          last_name: 'last_name',
                        },
                      },
                      value,
                    },
                  }))}
                />
              </Col>
            ),
            dataIndex: ['uinfo'],
            render: (uinfo, row) => {
              const fullName = getFullNameOrInitials({ uinfo }, 'fullname');
              const startDate = get(row, 'params.start_date');
              const endDate = get(row, 'params.end_date');
              const status = get(row, 'params.status');
              const days = diffDays(startDate, endDate);
              return (
                <Flex align="center">
                  {days > 15 && status !== 'approved' ? (
                    <Tooltip
                      title="Отпуск больше 15 дней!"
                      color="#DC2626"
                      className="mr-1"
                    >
                      <Icon
                        path={mdiAlertCircleOutline}
                        size={1}
                        color="#DC2626"
                      />
                    </Tooltip>
                  ) : null}
                  {fullName?.length >= 60 ? (
                    <Tooltip
                      style={{ width: 200 }}
                      title={fullName}
                    >
                      {fullName.slice(0, 57)}
                      ...
                    </Tooltip>
                  ) : fullName}
                </Flex>
              );
            },
          }, {
            ...i,
            defaultFilteredValue: ['approved'],
            filters: [
              {
                text: 'Approved',
                value: 'approved',
              },
              {
                text: 'Rejected',
                value: 'rejected',
              },
            ],
          }, ...acc];
      }

      if (i?.key === 'date') {
        return [
          ...acc,
          {
            key: 'timeStatus',
            title: 'Статус (время)',
            dataIndex: ['rangeDates'],
            filterMultiple: false,
            sorter: true,
            filters: [
              {
                text: 'Текущий',
                value: 'current',
              },
            ],
            render: (el, row) => (
              <VacationTimeStatus
                status={row?.params?.status}
                start_date={row?.params?.start_date}
                end_date={row?.params?.end_date}
                rangeDates={row?.params?.rangeDates}
              />
            ),
          },
          i,
        ];
      }

      if (i?.key === 'created') {
        return [
          ...acc, {
            title: 'Согласовал',
            dataIndex: ['params', 'agreed'],
            render: (agreed, row) => {
              if (!agreed) {
                return '-';
              }
              return `${get(agreed?.uinfo, 'first_name')} ${get(agreed?.uinfo, 'last_name')}`;
            },
          },
          {
            title: 'Утвердил',
            dataIndex: ['params', 'reviewed'],
            render: (reviewed, row) => {
              if (!reviewed) {
                return (
                  <Space>
                    <ApprovedUserVacation
                      row={row}
                      buttonProps={{
                        type: 'primary',
                        ghost: true,
                      }}
                      saveCallback={getAgreedList}
                    />
                    <RejectedUserVacation
                      row={row}
                      saveCallback={getAgreedList}
                    />
                  </Space>
                );
              }
              return `${get(reviewed?.uinfo, 'first_name')} ${get(reviewed?.uinfo, 'last_name')}`;
            },
          }, i, {
            title: 'Действия',
            dataIndex: ['actions'],
            render: (cell, row) => {
              const isUpcomingAndApproved = row?.params?.status === 'approved' && dayjs().isSameOrBefore(dayjs(row?.params?.start_date));
              return (
                <Flex onClick={(e) => stopPropagation(e)}>
                  <Button
                    danger
                    onClick={(e) => {
                      onDeleteVacation(row.uuid);
                    }}
                    className="mr-2"
                  >
                    Удалить
                  </Button>
                  {isUpcomingAndApproved && (
                    <EditVacation
                      data={row}
                      isAdminMode
                      currentActor={row?.actor}
                      type={row?.entity_type}
                      buttonProps={{ disabled: false }}
                      saveCallback={getAgreedList}
                    />
                  )}
                </Flex>
              );
            },
          }];
      }
      return [...acc, i];
    }), []);

    return data;
  }, [actors, vacations]);

  const handleFilterChange = (pagination, filters, sorter) => {
    if (!isEqual(selectedStatuses, filters.status)) {
      setStatuses(filters.status);
    }
    if (!isEqual(timeStatus, filters?.timeStatus?.[0])) {
      setTimeStatus(filters?.timeStatus?.[0] ?? '');
    }
    if (sorter.columnKey === 'timeStatus') {
      switch (sorter.order) {
        case 'ascend':
          setConfig((prev) => ({
            ...prev,
            order: 'asc',
            order_by_params: 'start_date',
          }));
          break;
        case 'descend':
          setConfig((prev) => ({
            ...prev,
            order: 'desc',
            order_by_params: 'start_date',
          }));
          break;
        default:
          setConfig((prev) => ({
            ...prev,
            order: 'desc',
            order_by_params: 'start_date',
          }));
      }
      setConfig((prev) => ({
        ...prev,
        offset: 0,
      }));
    }

    setConfig((prev) => ({
      ...prev,
      offset: 0,
    }));
  };

  useEffect(() => {
    if (myProfileData?.uuid && rootPartitionUuid) {
      getAgreedList();
    }
  }, [myProfileData?.uuid, rootPartitionUuid, config, dates]);

  useEffect(() => {
    if (getGlobalGetDataKey && getGlobalGetDataKey === 'getAgreedList') {
      getAgreedList();
      dispatch({ type: GlobalUpdateConstants.SET_GET_DATA, payload: '' });
    }
  }, [getGlobalGetDataKey]);

  useEffect(() => () => {
    dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: 'agreedVacationRequests' });
  }, [JSON.stringify(selectedStatuses), JSON.stringify(timeStatus)]);

  return (
    <Spin spinning={isFetching}>
      <Card title="Рассмотренные заявки" className="mt-2">
        <Space wrap className="mb-1">
          <DateSwitcher
            type="year"
            formatView="YYYY"
            currentDate={dates.startDate}
            onChangeHandler={onChangeDate}
          />
        </Space>

        <div className="overflow-auto">
          <Spin
            spinning={isFetching}
          >
            <TableVacations
              onRow={onRow}
              id="agreedVacationRequests"
              total={vacationsTotal}
              rowClassName={rowClassName}
              isSorterStatus={false}
              onChangeCallback={handleFilterChange}
              hide={hideColumnsVacations}
              needResetPagination={needResetPagination}
              vacations={vacations}
              savePaginationConfigCallback={savePaginationConfigCallback}
              // currentSortCallback={currentSortCallback}
              getColumnsCallback={getColumnsCallback}
              defaultPageSize={config?.limit}
            />
          </Spin>
        </div>
      </Card>
      {selectedUser && (
        <UserDayOffHistory
          isOpen={selectedUser}
          onClose={() => setSelectedUser('')}
          actor={selectedUser.actor}
          uinfo={selectedUser.uinfo}
        />
      )}
    </Spin>
  );
}

export default UsersAgreedVacationsRequestCard;
