import React from 'react';

import { Flex, Tooltip } from 'antd';
import { get } from 'lodash';
import { number, object, string } from 'prop-types';

import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';

export default function EmployeeLabel({
  user = {},
  leader,
  deputy,
  size = 128,
}) {
  const {
    actor,
  } = user;

  const label = `${get(user, 'uinfo.first_name', '')} ${get(user, 'uinfo.last_name', '')}`;

  return (
    <Flex
      align="center"
      style={{
        wordBreak: 'break-word',
      }}
    >
      {actor === leader
        && (
          <Tooltip
            title="Руководитель"
            color="red"
          >
            <Icon
              className="mr-1"
              path={mdiAlertCircleOutline}
              color="red"
              size={1}
            />
          </Tooltip>
        )}
      {actor === deputy
        && (
          <Tooltip
            title="Заместитель"
            color="orange"
          >
            <Icon
              className="mr-1"
              path={mdiAlertCircleOutline}
              color="orange"
              size={1}
            />
          </Tooltip>
        )}
      <Tooltip
        title={label}
      >
        {label?.length > size ? `${label?.slice(0, size)}...` : label}
      </Tooltip>
    </Flex>
  );
}

EmployeeLabel.propTypes = {
  user: object,
  leader: string,
  deputy: string,
  size: number,
};
