import { Button, Card, Col } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  head, isEmpty, omit, prop, propOr,
} from 'ramda';
import { capitalize, uniq } from 'lodash';

import ListPermissions from './ListPermissions';
import PermissionsContext from '../../context/PermissionsContext';
import { entityPartialRead, entitySet } from '../../api/actions/entity';
import SaveAndResetBtn from './SaveAndResetBtn';
import DescriptionPermissions from './DescriptionPermissions';

const hiddenTableData = ['columnType', 'columnName', 'columnEntityName', 'columnDeletePerm'];

function CreatorPermsCard({ nameSectionPermissions, notSavedPermissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    entityUUID,
    partitionType,
    notSavedDataCallback,
    updateSwitchDataCallback,
    resetFromMainContainer,
    defaultPerms,
  } = useContext(PermissionsContext);

  const [mainEntity, setMainEntity] = useState({});
  const [localReset, setLocalReset] = useState(false);
  const [actorWithNewPermsForSend, setActorWithNewPermForSend] = useState([]);
  const [changedField, setChangedField] = useState([]);

  const changeSwitch = (item, newPermsData, currentNameField) => {
    const newPerms = updateSwitchDataCallback(item, actorWithNewPermsForSend, newPermsData);

    notSavedDataCallback('creatorCard', !isEmpty(newPerms));
    setChangedField((prev) => uniq([...prev, currentNameField]));
    setActorWithNewPermForSend(newPerms);
  };

  const savePermissions = async () => {
    const data = {
      entity_uuid: entityUUID,
      creator_perms: {
        // actor_uuid: propOr('', 'uuid', actorWithNewPermsForSend[0]),
        create: propOr(false, 'create', actorWithNewPermsForSend[0]),
        read: propOr(false, 'read', actorWithNewPermsForSend[0]),
        update: propOr(false, 'update', actorWithNewPermsForSend[0]),
        delete: propOr(false, 'delete', actorWithNewPermsForSend[0]),
        list: propOr(false, 'list', actorWithNewPermsForSend[0]),
        set: propOr(false, 'set', actorWithNewPermsForSend[0]),
      },
    };

    const options = {
      partition: partitionType,
    };

    const constants = [
      'SAVE_PERMS_REQUEST',
      'SAVE_PERMS_SUCCESS',
      'SAVE_PERMS_FAILURE',
    ];

    if (changedField.includes('affix_creator_delete')) {
      const affixData = {
        entity_uuid: entityUUID,
        recursive: propOr(false, 'recursive', actorWithNewPermsForSend[0]),
        creator_perms: {
          affix_creator_delete: propOr(false, 'affix_creator_delete', actorWithNewPermsForSend[0]),
        },
      };

      dispatch(entitySet({
        data: affixData,
        options,
        constants,
      }));
    }

    const dataNewEntity = await dispatch(entitySet({
      data,
      options,
      constants,
    }));

    notSavedDataCallback('creatorCard', false);
    setActorWithNewPermForSend([]);
    setChangedField([]);

    setMainEntity([{
      ...omit(['creator_perms'], dataNewEntity),
      ...prop('creator_perms', dataNewEntity),
    }]);
  };

  const resetData = () => {
    setActorWithNewPermForSend([]);
    setChangedField([]);
    setLocalReset(true);

    notSavedDataCallback('creatorCard', false);
  };

  const getMainEntity = () => {
    const data = {
      entity_uuid: entityUUID,
      fields: {
        creator_perms: 'creator_perms',
      },
    };

    const constants = [
      'GET_MAIN_ENTITY_FOR_CREATOR_PERMS_NO_REDUX_REQUEST',
      'GET_MAIN_ENTITY_FOR_CREATOR_PERMS_NO_REDUX_SUCCESS',
      'GET_MAIN_ENTITY_FOR_CREATOR_PERMS_NO_REDUX_FAILURE',
    ];

    const options = {
      partition: partitionType,
    };

    return dispatch(entityPartialRead({
      data,
      constants,
      options,
    }));
  };

  const initFunc = async () => {
    const parentEntity = await getMainEntity();

    const { creator_perms } = head(parentEntity);

    setMainEntity([{
      ...omit(['creator_perms'], head(parentEntity)),
      ...creator_perms || defaultPerms,
    }]);
  };

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.creatorCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  useEffect(() => {
    initFunc();
  }, []);
  // console.log('changedField', changedField, 'actorWithNewPermsForSend', actorWithNewPermsForSend);
  return (
    <Card className="primaryCard w-full">
      <Col className="mb-3 -mt-1 flex justify-between">
        <span className="text-2xl font-medium">{nameSectionPermissions}</span>
        {/* <form action={configUrlAuth} target="_blank">
          <Button
            htmlType="submit"
            size="small"
            className="ml-1 flex flex-wrap style_link_auth customAntOutPrimaryBtn"
          >
            {capitalize(t('wms.buttons.go_to_auth', 'Need more functionality? Go to Auth service '))}
          </Button>
        </form> */}
      </Col>

      <DescriptionPermissions />

      <div className="header_table_title justify-between mt-2">
        {capitalize('Entity creator permissions')}
        <SaveAndResetBtn
          className="ml-1"
          disabledButtons={isEmpty(actorWithNewPermsForSend)}
          saveCallback={savePermissions}
          resetCallback={resetData}
        />
      </div>
      <ListPermissions
        dataTable={mainEntity}
        headerClasses="hidden"
        resetSwitchBool={localReset}
        cardName="creatorCard"
        hiddenData={hiddenTableData}
        // removePermCallback={removeCreatorPermCallback}
        changeSwitchCallback={changeSwitch}
      />
    </Card>
  );
}

CreatorPermsCard.propTypes = {
  nameSectionPermissions: PropTypes.string,
  notSavedPermissions: PropTypes.object,
};

export default CreatorPermsCard;
