/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { get, head } from 'lodash';

const initialState = {
  fetching: false,
  defaultDepartment: {},
};

const defaultDepartmentSlice = createSlice({
  name: 'defaultDepartmentStorage',
  initialState,
  reducers: {
    GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.defaultDepartment = action.payload;
    },
    GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    GET_LIST_OF_DEFAULT_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    GET_LIST_OF_DEFAULT_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.defaultDepartment = {
        uuid: head(get(action.payload, 'entity_uuids')),
      };
    },
    GET_LIST_OF_DEFAULT_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST,
  GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS,
  GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE,
  GET_LIST_OF_DEFAULT_DEPARTMENT_REQUEST,
  GET_LIST_OF_DEFAULT_DEPARTMENT_SUCCESS,
  GET_LIST_OF_DEFAULT_DEPARTMENT_FAILURE,
} = defaultDepartmentSlice.actions;

export default defaultDepartmentSlice.reducer;
