import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get, omit } from 'lodash';

import { getDeliveryPartition } from '../../config/selectors';
import { getDepartments, getIsFetchingDepartments, getTotalDepartments } from '../selectors/departmentsSelector';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import { GET_DEPARTMENTS_FAILURE, GET_DEPARTMENTS_REQUEST, GET_DEPARTMENTS_SUCCESS } from '../slices/departmentsSlice';

function useDepartments({
  defaultConfig = {},
} = {}) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const departments = useSelector(getDepartments);
  const total = useSelector(getTotalDepartments);
  const isFetching = useSelector(getIsFetchingDepartments);

  const {
    getListOfDepartments,
  } = useDepartmentsAPI();

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    offset: 0,
    limit: 10,
    ...defaultConfig,
  });

  const initFunc = () => {
    const newConfig = {
      ...config,
    };

    getListOfDepartments(partitionUuid, newConfig, [
      GET_DEPARTMENTS_REQUEST,
      GET_DEPARTMENTS_SUCCESS,
      GET_DEPARTMENTS_FAILURE,
    ]);
  };

  useEffect(() => {
    if (partitionUuid) {
      initFunc();
    }
  }, [partitionUuid, JSON.stringify(config)]);

  useEffect(() => {
    setConfig(defaultConfig);
  }, [JSON.stringify(defaultConfig)]);

  return {
    departments,
    onReloadDepartments: initFunc,
    isFetching,
    paginationOptions: {
      total,
      pageLimit: get(config, 'limit', 10),
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), ...newPart }));
        }
      },
    },
  };
}

export default useDepartments;
