/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { memo, useMemo, useState } from 'react';
import {
  arrayOf, bool, number, func,
} from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tag, Popover } from 'antd';

import './BaseTablePagination.scss';

function BaseTablePagination({
  total,
  pageLimit,
  currentPage,
  pageSizeOptions,
  hideListSizeOption,
  onChangePage,
  onShowSizeChange,
}) {
  const { t } = useTranslation();

  const [openPageLimit, setOpenPageLimit] = useState(false);

  const totalPages = useMemo(() => Math.ceil(total / pageLimit), [total, pageLimit]);
  const pagesList = useMemo(() => {
    const array = [];

    for (let i = 1; i <= totalPages; i++) array.push(i);
    return array;
  }, [totalPages]);

  const hasPagesList = pagesList.length > 0;
  const firstPage = 1;
  const lastPage = pagesList.length;

  // Change pagesNumberBeforeAndAfterCurrent if need another number of items to show
  const pagesNumberBeforeAndAfterCurrent = 1;
  const visiblePagesNumber = pagesNumberBeforeAndAfterCurrent * 2 + 1;
  const visiblePagesNumberLimit = pagesNumberBeforeAndAfterCurrent * 2 + 3;
  const isVisiblePagesLimitAchieved = lastPage > visiblePagesNumberLimit;

  const showPageSize = pageSizeOptions?.length > 0 && !hideListSizeOption;

  const isFirstPage = +currentPage === firstPage;
  const isLastPage = +currentPage === lastPage;
  const atBeginningOfList = +currentPage - firstPage <= pagesNumberBeforeAndAfterCurrent + 1;
  const atEndOfList = lastPage - +currentPage <= pagesNumberBeforeAndAfterCurrent + 1;
  const atMiddleOfList = !atBeginningOfList && !atEndOfList;

  const shownItemsRange = () => {
    const beginRange = currentPage === firstPage ? 0 : ((currentPage - 1) * pageLimit) + 1;
    const endRange = currentPage === lastPage ? total : (currentPage * pageLimit);
    return `from ${beginRange} to ${endRange}`;
  };

  const nextPage = () => onChangePage(currentPage + 1);
  const prevPage = () => onChangePage(currentPage - 1);

  const onChangePagination = (e) => {
    onChangePage(e);
  };

  const selectFirstPage = () => onChangePagination(firstPage);
  const selectLastPage = () => onChangePagination(lastPage);

  const dotsPlusClick = () => {
    if (atBeginningOfList) return onChangePage(visiblePagesNumberLimit);
    return onChangePage(+currentPage + pagesNumberBeforeAndAfterCurrent + 1);
  };
  const dotsMinusClick = () => {
    if (atEndOfList) return onChangePage(lastPage - visiblePagesNumberLimit + 1);
    return onChangePage(+currentPage - pagesNumberBeforeAndAfterCurrent - 1);
  };

  const renderFirstPageNumber = (
    <li
      key="first-page"
      aria-hidden
      className={`pagination-item pagination__list-item ${isFirstPage ? '--active' : ''}`}
      onClick={selectFirstPage}
    >
      {firstPage}
    </li>
  );

  const renderLastPageNumber = (
    <li
      key="last-page"
      aria-hidden
      className={`pagination-item pagination__list-item ${isLastPage ? '--active' : ''}`}
      onClick={selectLastPage}
    >
      {lastPage}
    </li>
  );

  const renderListOfPages = (page) => (
    <li
      id={`pageNumber${page}Button`}
      key={page}
      aria-hidden
      className={`pagination-item pagination__list-item ${currentPage === page ? '--active' : ''}`}
      onClick={() => onChangePagination(page)}
    >
      {page}
    </li>
  );

  const renderDotsPlus = (
    <li
      key="dots-plus"
      aria-hidden
      className="pagination-item pagination__list-item dots"
      onClick={dotsPlusClick}
    >
      ...
    </li>
  );

  const renderDotsMinus = (
    <li
      key="dots-minus"
      aria-hidden
      className="pagination-item pagination__list-item dots"
      onClick={dotsMinusClick}
    >
      ...
    </li>
  );

  const renderNextPageArrow = (
    <svg id="renderNextPageButton" viewBox="64 64 896 896" focusable="false" data-icon="right" width="0.9em" height="0.9em" fill="currentColor" aria-hidden="true">
      <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
    </svg>
  );
  const renderPrevPageArrow = (
    <svg id="renderPrevPageButton" viewBox="64 64 896 896" focusable="false" data-icon="left" width="0.9em" height="0.9em" fill="currentColor" aria-hidden="true">
      <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
    </svg>
  );

  const renderPagesList = () => {
    if (isVisiblePagesLimitAchieved) {
      // console.log('!! begin', atBeginningOfList);
      // console.log('!! middle', atMiddleOfList);
      // console.log('!! end', atEndOfList);

      return (
        <>
          {renderFirstPageNumber}
          {atBeginningOfList && (
            <>
              {pagesList
                .slice(firstPage, firstPage + visiblePagesNumber)
                .map((page) => renderListOfPages(page))}
              {renderDotsPlus}
            </>
          )}
          {atMiddleOfList && (
            <>
              {renderDotsMinus}
              {pagesList
                .slice(
                  +currentPage - pagesNumberBeforeAndAfterCurrent - 1,
                  +currentPage + pagesNumberBeforeAndAfterCurrent,
                )
                .map((page) => renderListOfPages(page))}
              {renderDotsPlus}
            </>
          )}
          {atEndOfList && (
            <>
              {renderDotsMinus}
              {pagesList
                .slice(lastPage - visiblePagesNumber - 1, lastPage - 1)
                .map((page) => renderListOfPages(page))}
            </>
          )}
          {renderLastPageNumber}
        </>
      );
    }

    return pagesList.map((page) => renderListOfPages(page));
  };

  return hasPagesList && (
    <div className="pagination">
      <div className="shown-items">
        {`Shown: ${shownItemsRange()} of ${total}`}
      </div>
      <ul className="pagination__list">
        <li className="pagination__list-item">
          <button
            type="button"
            disabled={isFirstPage}
            className={`svg-arrow ${isFirstPage ? '--disabled' : ''}`}
            onClick={prevPage}
          >
            {renderPrevPageArrow}
          </button>
        </li>
        {renderPagesList()}
        <li className="pagination__list-item">
          <button
            type="button"
            disabled={isLastPage}
            className={`svg-arrow ${isLastPage ? '--disabled' : ''}`}
            onClick={nextPage}
          >
            {renderNextPageArrow}
          </button>
        </li>
      </ul>

      {showPageSize
        && (
        <div className="pageLimit">
          <div className="pageLimit__title">Items per page:</div>
          <Popover
            open={openPageLimit}
            content={(
              <ul className="pageLimit__list">
                {pageSizeOptions?.filter((i) => i !== pageLimit).map((item, key) => (
                  <li
                    key={key + item}
                    aria-hidden
                    className="pagination-item pageLimit__item "
                    onClick={() => {
                      setOpenPageLimit(false);
                      onShowSizeChange(pageLimit, item);
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              )}
          >
            <button
              type="button"
              className="pagination-item pageLimit__item --active"
              onClick={() => setOpenPageLimit(!openPageLimit)}
            >
              {pageLimit}
            </button>
          </Popover>
        </div>
        )}
    </div>
  );
}

BaseTablePagination.propTypes = {
  total: number,
  pageLimit: number,
  currentPage: number,
  pageSizeOptions: arrayOf(number),
  hideListSizeOption: bool,
  onChangePage: func,
  onShowSizeChange: func,
};

export default memo(BaseTablePagination);
