import {
  Flex, Modal, Spin, Table,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import dayjs from 'dayjs';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import { getListAndReadAffix } from '../../api/actions/affix';
import { SERVICE_PARTITION_NAME } from '../../api/app_config';
import { getFullNameOrInitials } from '../../utils/utils54origins';

export default function MonthlyReportLogsModal({
  entityType,
  entityUUID,
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();

  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 50,
    offset: 0,
  });

  const columns = [
    {
      title: '',
      //   width: '16%',
      dataIndex: ['params', 'type'],
      render: (cell, row) => (
        <Flex vertical>
          {cell === 'CREATE' && <span className="text-green-500">Создано</span>}
          {cell === 'UPDATE' && <span className="text-blue-500">Изменено</span>}
        </Flex>
      ),
    },
    {
      title: 'Автор',
      width: '16%',
      dataIndex: ['uinfo'],
      render: (cell, row) => {
        const fullname = getFullNameOrInitials(row, 'fullname');

        return (
          <Flex vertical>
            {fullname?.length > 40 ? (
              <Tooltip title={fullname}>
                {fullname?.slice(0, 37)}
                ...
              </Tooltip>
            ) : fullname}
          </Flex>
        );
      },
    },
    {
      title: 'Дата',
      width: '12%',
      dataIndex: ['created'],
      render: (cell, row) => dayjs(cell).format('DD MMM YYYY, HH:mm:ss'),
    },
    {
      title: 'Проектных часов',
      dataIndex: ['params', 'values', 'totalWorks'],
      render: (cell, row) => (cell || 0),
    },
    {
      title: 'Клиентский пр.',
      dataIndex: ['params', 'values', 'clientWork'],
      render: (cell, row) => (cell || 0),
    },
    {
      title: 'Внутренний пр.',
      dataIndex: ['params', 'values', 'internalWork'],
      render: (cell, row) => (cell || 0),
    },
    {
      title: 'Бенч',
      dataIndex: ['params', 'values', 'totalBenches'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{(cell || 0)}</span>,
    },
    {
      title: 'Нерабочих часов',
      dataIndex: ['params', 'values', 'totalDayOff'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{(cell || 0)}</span>,
    },
    {
      title: 'Отгул',
      dataIndex: ['params', 'values', 'dayoff'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{(cell || 0)}</span>,
    },
    {
      title: 'Больничный',
      dataIndex: ['params', 'values', 'sickday'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{(cell || 0)}</span>,
    },
    {
      title: 'Отпуск',
      dataIndex: ['params', 'values', 'vacation'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{(cell || 0)}</span>,
    },
  ];

  const initFunc = async () => {
    setIsFetching(true);

    const res = await dispatch(getListAndReadAffix({
      data: {
        entity_uuid: entityUUID,
        entity_type: `${entityType}Log`,
        depth: 0,
        ...config,
      },
      constants: [
        'GET_LIST_OF_REPORT_LOGS_REQUEST',
        'GET_LIST_OF_REPORT_LOGS_SUCCESS',
        'GET_LIST_OF_REPORT_LOGS_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));

    setLogs(res?.data ?? []);
    setTotal(res?.total ?? 0);
    setIsFetching(false);
  };

  useEffect(() => {
    if (entityUUID) {
      initFunc();
    }
  }, [
    entityUUID,
    JSON.stringify(config),
  ]);

  return (
    <Modal
      open={isOpen}
      title="История изменений отчета"
      onCancel={onClose}
      centered
      width={1500}
      destroyOnClose
      footer={false}
    >
      <Spin
        className="w-full h-100"
        spinning={isFetching}
      >
        <BaseTableWithPagination
          columns={columns}
          data={logs}
          rowKey="uuid"
          size="small"
          rowClassName={() => 'cursor-pointer'}
          hideListSizeOption={false}
          useCustomPagination
          pageSizeOptions={[10, 25, 50]}
          disablePagination={false}
          pageLimit={get(config, 'limit', 5)}
          total={total}
          changePageLimit={(newLimit) => {
            setCurrentPage(1);
            setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
          }}
          newCurrentPage={currentPage}
          onChangePageCallback={(newPage) => {
            setCurrentPage(newPage);
            setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
          }}
          getPaginationOptions={(newPart) => {
            const valueSearch = get(newPart, 'search', '');

            if (valueSearch) {
              setConfig((prev) => ({
                ...prev,
                ...omit(newPart, ['search']),
                searchData: {
                  value: valueSearch,
                  ignore_case: true,
                  fields: { params: ['title', 'id'] },
                },
              }));
            } else {
              setConfig((prev) => ({ ...omit(prev, 'searchData'), newPart }));
            }
          }}
        />
      </Spin>
    </Modal>
  );
}

MonthlyReportLogsModal.propTypes = {
  entityUUID: string,
  entityType: string,
  isOpen: bool,
  onClose: func,
};
