import React from 'react';
import { string } from 'prop-types';
import { Tag } from 'antd';

const statusColors = {
  rejected: '#f50',
  approved: '#87d068',
  pending: '#108ee9',
};

export const getEmployeeRequestStatusColor = (status) => statusColors[status] ?? '#2db7f5';

const employeeRequestStatuses = {
  pending: 'На рассмотрении',
  approved: 'Утверждена',
  rejected: 'Отклонена',
};

export const getEmployeeRequestStatus = (status) => employeeRequestStatuses[status] ?? '';

export default function EmployeeRequestStatus({ status }) {
  return (
    <Tag color={getEmployeeRequestStatusColor(status)}>
      {getEmployeeRequestStatus(status)}
    </Tag>
  );
}

EmployeeRequestStatus.propTypes = {
  status: string.isRequired,
};
