import React, { useEffect, useState } from 'react';
import { array, func, string } from 'prop-types';
import { Flex } from 'antd';
import { cloneDeep } from 'lodash';
import AntUploaderFiles from '../../../../components/commonComponents/AntUploaderFiles';

export default function EntityFilesForCard({
  className = '',
  files = [],
  onEditCallback,
  onDeleteFile,
}) {
  const [filesList, setFilesList] = useState([]);

  const initFunc = (customFiles) => {
    const { files, audioFiles } = (customFiles || []).reduce((acc, curr) => {
      const newCurr = cloneDeep(curr);
      newCurr.name = newCurr.filename;
      if ((newCurr?.content_type || newCurr?.type) && !newCurr?.content_type.includes('audio')) {
        newCurr.url = `data:${newCurr?.content_type};base64, ${newCurr?.file}`;
        newCurr.status = 'done';
        return { ...acc, files: [...acc.files, newCurr] };
      }
      if ((newCurr?.content_type || newCurr?.type) && newCurr?.content_type.includes('audio')) {
        return { ...acc, audioFiles: [...acc.audioFiles, newCurr] };
      }
      return acc;
    }, { files: [], audioFiles: [] });

    setFilesList(files);
  };

  useEffect(() => {
    initFunc(files);
    // setFilesList(files.map((file) => ({
    //   ...file,
    //   url: `data:${file?.content_type};base64, ${file?.file}`,
    //   status: 'done',
    // })));
  }, [JSON.stringify(files)]);

  return (
    <Flex
      vertical
      className={className}
    >
      <span
        className="mr-2 mb-1 text-base"
      >
        Файлы
        :
      </span>
      <AntUploaderFiles
        hideTitle
        filesList={filesList}
        onDeleteFile={onDeleteFile}
        setFiles={onEditCallback}
      />
    </Flex>
  );
}

EntityFilesForCard.propTypes = {
  files: array,
  className: string,
  onEditCallback: func,
};
