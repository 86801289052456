import React from 'react';
import {
  Flex,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import Icon from '@mdi/react';
import { mdiCommentProcessing } from '@mdi/js';
import { bool } from 'prop-types';
import EmployeeRequestStatus from '../departments/common/EmployeeRequestStatus';
import { formatDate } from '../../../utils/dateTimeUtils';
import BaseTableWithPagination from '../../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useEmployeeRequests from '../../../redux/employeeRequest/hooks/useEmployeeRequests';
import ReviewRequest from './ReviewRequest';
import CancelRequest from './CancelRequest';
import EditRequest from './EditRequest';

const { Text } = Typography;

function EmployeeRequestsTable({ isDepartmentRoutes }) {
  const {
    allDepartmentsEmployeeRequestsList,
    departmentsList,
    isFetching,
    paginationOptions,
  } = useEmployeeRequests();

  const getDepartmentTitle = (id) => {
    const department = departmentsList.find((item) => item.uuid === id);
    return department ? department.params.title : '';
  };

  const columns = [
    {
      title: 'Отдел',
      key: 'parent',
      dataIndex: ['parent'],
      width: '15%',
      render: (cell) => (
        <Text>
          {getDepartmentTitle(cell)}
        </Text>
      ),
    },
    {
      title: 'Создал',
      key: 'uinfo',
      dataIndex: ['uinfo'],
      width: '15%',
      render: (cell) => (
        <Text>
          {`${cell.first_name} ${cell.last_name}`}
        </Text>
      ),
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: ['params', 'status'],
      width: '20%',
      render: (status) => (
        <EmployeeRequestStatus status={status} />
      ),
    },
    {
      title: 'Создана',
      key: 'date',
      dataIndex: ['created'],
      width: '15%',
      render: (cell) => (
        <Text>
          {formatDate(cell)}
        </Text>
      ),
    },
    {
      title: 'Описание заявки',
      dataIndex: ['params', 'description'],
      key: 'comment',
      width: '10%',
      render: (cell = '') => (cell ? (
        <div className="comment-wrapper">
          <Tooltip title={cell} color="#0958d9">
            <Icon path={mdiCommentProcessing} size={1.5} color="#0958d9" />
          </Tooltip>
        </div>
      ) : '-'),
    },
    {
      title: 'Действия',
      key: 'actions',
      dataIndex: ['uuid'],
      width: '35%',
      render: (uuid, row) => (
        <Space>
          <ReviewRequest
            uuid={uuid}
            requestStatus={row.params.status}
            isDepartmentRoutes={isDepartmentRoutes}
          />
          <CancelRequest
            uuid={uuid}
            requestStatus={row.params.status}
            isDepartmentRoutes={isDepartmentRoutes}
          />
          <EditRequest
            uuid={uuid}
            row={row}
            isDepartmentRoutes={isDepartmentRoutes}
          />
        </Space>
      ),
    },
  ];

  return (
    <Flex
      className="mx-2"
      vertical
    >
      <Spin spinning={isFetching}>
        <BaseTableWithPagination
          columns={columns}
          data={allDepartmentsEmployeeRequestsList}
          rowKey="uuid"
          size="small"
          className="transition-transform duration-300 ease-in-out"
          headerRowClassName="universal_header_table_row color-gray"
          hideListSizeOption={false}
          useCustomPagination
          pageSizeOptions={[10, 25, 50]}
          disablePagination={false}
          {...paginationOptions}
        />
      </Spin>
    </Flex>
  );
}

export default EmployeeRequestsTable;

EmployeeRequestsTable.propTypes = {
  isDepartmentRoutes: bool.isRequired,
};
