/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRoot: null,
  isFetchingUser: true,
  vacationStorage: null,
  isFetchingVacationSt: true,
  monthlyReportsStorage: null,
  isFetchingMonthlyReportsSt: true,
  personalUsersStorage: null,
  isFetchingPersonalUsersStorage: true,
  personalUser: null,
  isFetchingPersonalUser: true,
};

const storagesSlice = createSlice({
  name: 'storagesSlice',
  initialState,
  reducers: {
    RESET_STORAGE(state, action) {
      if (action?.payload === 'vacationStorage') {
        state.vacationStorage = null;
        state.isFetchingVacationSt = true;
      }
      if (action?.payload === 'monthlyReportsStorage') {
        state.monthlyReportsStorage = null;
        state.isFetchingMonthlyReportsSt = true;
      }
    },
    SET_ROOT_USER(state, action) {
      state.userRoot = action.payload;
      state.isFetchingUser = false;
    },
    GET_ROOT_USER_REQUEST(state) {
      state.userRoot = null;
      state.isFetchingUser = true;
    },
    GET_ROOT_USER_SUCCESS(state, action) {
      const { payload } = action;

      state.userRoot = payload;
      state.isFetchingUser = false;
    },
    GET_ROOT_USER_FAILURE(state) {
      state.isFetchingUser = false;
    },
    GET_VACATION_STORAGE_REQUEST(state) {
      state.isFetchingVacationSt = true;
    },
    GET_VACATION_STORAGE_SUCCESS(state, action) {
      const { payload } = action;

      state.vacationStorage = payload;
      state.isFetchingVacationSt = false;
    },
    GET_VACATION_STORAGE_FAILURE(state) {
      state.isFetchingVacationSt = false;
    },
    GET_MONTHLY_REPORTS_STORAGE_REQUEST(state) {
      state.isFetchingMonthlyReportsSt = true;
    },
    GET_MONTHLY_REPORTS_STORAGE_SUCCESS(state, action) {
      const { payload } = action;
      state.monthlyReportsStorage = payload;
      state.isFetchingMonthlyReportsSt = false;
    },
    GET_MONTHLY_REPORTS_STORAGE_FAILURE(state) {
      state.isFetchingMonthlyReportsSt = false;
    },
    GET_PERSONAL_USER_REQUEST(state) {
      state.isFetchingPersonalUser = true;
    },
    GET_PERSONAL_USER_SUCCESS(state, action) {
      const { payload } = action;
      state.personalUser = payload;
      state.isFetchingPersonalUser = false;
    },
    GET_PERSONAL_USER_FAILURE(state) {
      state.isFetchingPersonalUser = false;
    },
    GET_PERSONAL_USERS_STORAGE_REQUEST(state) {
      state.isFetchingPersonalUsersStorage = true;
    },
    GET_PERSONAL_USERS_STORAGE_SUCCESS(state, action) {
      const { payload } = action;
      state.personalUsersStorage = payload;
      state.isFetchingPersonalUsersStorage = false;
    },
    GET_PERSONAL_USERS_STORAGE_FAILURE(state) {
      state.isFetchingPersonalUsersStorage = false;
    },
  },
});

export const {
  RESET_STORAGE,
  SET_ROOT_USER,
  GET_ROOT_USER_REQUEST,
  GET_ROOT_USER_SUCCESS,
  GET_ROOT_USER_FAILURE,
  GET_VACATION_STORAGE_REQUEST,
  GET_VACATION_STORAGE_SUCCESS,
  GET_VACATION_STORAGE_FAILURE,
  GET_MONTHLY_REPORTS_STORAGE_REQUEST,
  GET_MONTHLY_REPORTS_STORAGE_SUCCESS,
  GET_MONTHLY_REPORTS_STORAGE_FAILURE,
  GET_PERSONAL_USER_REQUEST,
  GET_PERSONAL_USER_SUCCESS,
  GET_PERSONAL_USER_FAILURE,
  GET_PERSONAL_USERS_STORAGE_REQUEST,
  GET_PERSONAL_USERS_STORAGE_SUCCESS,
  GET_PERSONAL_USERS_STORAGE_FAILURE,
} = storagesSlice.actions;

export default storagesSlice.reducer;
