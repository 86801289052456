import { mdiFileOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Image, Tooltip } from 'antd';
import React, { useCallback } from 'react';

export default function FileIcon({ item }) {
  const viewName = useCallback((filename, name, fileSize) => {
    const fullName = `${filename || name} (${fileSize})`;
    return (
      <Tooltip placement="bottom" title={fullName}>
        <span
          className="text-upload-file"
          style={{
            fontSize: '14px',
            marginTop: -24,
          }}
        >
          {fullName.length > 7 ? `${fullName.slice(0, 6)}...` : fullName}
        </span>
      </Tooltip>
    );
  }, []);

  const {
    content_type: contentType,
    filename = '',
    type,
    name = 'file',
    file,
    uuid,
  } = item || {};
  const i = uuid;

  return (
    <Col className="position-relative d-flex flex-column align-items-center" key={`${uuid}${i}`}>
      {/* {iconDeleteFile(i)} */}

      {(contentType && contentType.indexOf('image') !== -1) || (type && type.indexOf('image') !== -1) ? (
        <div className="wrapper-file-icon">
          <Image
            width={37}
            height={37}
            src={type ? URL.createObjectURL(item) : `data:${contentType};base64, ${file}`}
          />
        </div>
      ) : (
        <Col
          className="d-flex align-items-center fileAttached"
        >
          <Icon
            className="fileIcon"
            color="#bfbfbf"
            path={mdiFileOutline}
            size={2}
          />
        </Col>
      )}
      {viewName(filename, name)}
    </Col>
  );
}
