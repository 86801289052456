import React from 'react';
import { Badge, Flex, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import EntityParamTag from '../../../w54_components/entityParamTag/EntityParamTag';

export const excludeSectionFilterResult = (filters) => {
  if (Array.isArray(filters)) return filters.filter((el) => !el.includes(':section'));
  return undefined;
};

export const getNestedFilters = (selectedFilters, filters) => {
  if (!Array.isArray(selectedFilters) || !Array.isArray(filters)) return undefined;

  const resultFilters = [];
  selectedFilters.forEach((filter) => {
    const isSection = filter.includes(':section');

    if (isSection) {
      const findedSection = filters.find((el) => el?.value === filter);
      const { children } = findedSection || {};

      if (findedSection && children?.length) {
        children.forEach((el) => {
          resultFilters.push(el?.value || el?.text?.toLowerCase());
        });
      }
    }

    if (!isSection) {
      filters.forEach((el) => {
        let isSelected = false;
        isSelected = el?.children
          ? el.children.find((item) => item?.value === filter)
          : el?.value === filter;
        if (isSelected) resultFilters.push(filter);
      });
    }
  });

  return resultFilters.length > 0 ? resultFilters : undefined;
};

export const artifactTypeFilterOptions = [
  {
    text: 'Инструкция',
    value: 'instruction',
  },
  {
    text: 'Файл',
    value: 'file',
  },
  {
    text: 'Сотрудник',
    value: 'user',
  },
];

export const artifactStatusFilterOptions = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Archive',
    value: 'archive',
  },
];
