import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Spin } from 'antd';
import { omit } from 'lodash';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import UsersTable from './UsersTable';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';
import useAPI from '../../../../api/useAPI';
import { getFetchingReports54Actors, getReports54Actors, getReports54ActorsTotal } from '../../../../redux/reports54Actors/selectors';
import CustomTablePagination from '../../../../components/commonComponents/customTablePagination/CustomTablePagination';

export default function UsersCard() {
  const {
    getPermissionsOfEntity,
    requestGetOpenInterfaces,
    getDeliveryUsers,
  } = useAPI();

  const deliveryPartition = useSelector(getDeliveryPartition);
  const actors = useSelector(getReports54Actors);
  const totalActors = useSelector(getReports54ActorsTotal);
  const isFetching = useSelector(getFetchingReports54Actors);

  const [valueSearch, setValueSearch] = useState('');
  const [listPerms, setListPerms] = useState(new Map([]));
  const [fetching, setFetching] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    actor_type: ['user', 'classic_user'],
    limit: 100,
    depth: 0,
    offset: 0,
    order: 'desc',
    order_by: 'created',
  });

  const {
    limit = 100,
  } = config;

  const onChangeSearch = (value) => {
    setValueSearch(value);
    setCurrentPage(1);
    if (value === '') {
      setConfig((prev) => ({
        ...omit(prev, 'search_data'),
        limit: 100,
        offset: 0,
      }));
      return;
    }
    setConfig((prev) => ({
      ...prev,
      limit: 100,
      offset: 0,
      search_data: {
        fields: {
          uinfo: {
            first_name: 'first_name',
            last_name: 'last_name',
          },
        },
        value,
      },
    }));
  };

  const title = useMemo(() => (
    (
      <div className="flex items-center">
        <span className="mr-2">Юзеры</span>
        <AntDesignSearchBox54origins
          maxWidth={250}
          valueSearch={valueSearch}
          onSearch={onChangeSearch}
        />
      </div>
    )
  ), []);

  const getEntityPerms = async () => {
    setFetching(true);
    try {
      const data = {
        entity_uuid: deliveryPartition,
        actors: actors?.map((el) => el?.actor),
        actor_type: ['user', 'classic_user'],
      };
      const { data: res } = await getPermissionsOfEntity({ data });
      const list = new Map([]);
      res.forEach((p) => list.set(p?.actor_uuid, p));
      setListPerms(list);
    } catch (e) {
      console.log(e, 'Ошибка!');
    }
    setFetching(false);
  };

  const onSizePerPageTable = (prevSize, newSize) => {
    setCurrentPage(1);
    setConfig((prev) => ({
      ...prev,
      offset: 0,
      limit: newSize,
    }));
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
    setConfig((prev) => ({
      ...prev,
      offset: (page - 1) * prev?.limit,
      limit: prev?.limit,
    }));
  };

  useEffect(() => {
    if (deliveryPartition && actors?.length) {
      getEntityPerms();
    }
  }, [
    deliveryPartition,
    JSON.stringify(actors),
  ]);

  useEffect(() => {
    if (deliveryPartition) {
      getDeliveryUsers(config);
    }
  }, [deliveryPartition, JSON.stringify(config)]);

  return (
    <Card title={title}>
      <Spin spinning={isFetching}>
        <UsersTable
          customFetching={fetching}
          dataSource={actors}
          listPerms={listPerms}
          updatePermCallback={getEntityPerms}
        />
        <CustomTablePagination
          onShowSizeChange={onSizePerPageTable}
          pageSizeOptions={['25', '50', '100', '200']}
          onChangePage={onChangePagination}
          currentPage={currentPage}
          total={totalActors}
          pageLimit={limit}
        />
      </Spin>
    </Card>
  );
}
