import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';

import {
  Card, Col, Divider, Row, Segmented, Space, Spin,
} from 'antd';
import { capitalize, get, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { string } from 'prop-types';
import {
  VACATION_STATUSES, defaultRequestOptions,
} from './utils';
import DeleteVacation from './components/DeleteVacation';
import {
  getAllVacations, getAllVacationsCount,
  getFetchingVacationList,
} from '../../../redux/userVacation/selectors';
import TableVacations from './TableVacations';
import EditVacation from './components/EditVacation';
import useAPI from '../../../api/useAPI';
import { getProfileInfo } from '../../../redux/profile/selectors';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import RequestVacation from './components/RequestVacation';
import InfoCreateVacation from './components/InfoCreateVacation';
import CopyURL from '../../../components/commonComponents/CopyURL';
import { getVacationStorageUUID } from '../../../redux/storages/selectors';
import AddNewVacation from './components/AddNewVacation';
import useVacationsAPI from '../../../api/useVacationsAPI';
import UserVacationConstants from '../../../redux/userVacation/constants';

function RequestVacationsCard({ actor }) {
  const dispatch = useDispatch();

  const {
    getListOfVacations,
  } = useVacationsAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);

  const allVacations = useSelector(getAllVacations);
  const allVacationsTotal = useSelector(getAllVacationsCount);
  const isFetching = useSelector(getFetchingVacationList);

  const [config, setConfig] = useState(defaultRequestOptions);

  const [selectedStatus, setStatus] = useState('');

  const optionsStatusFilter = useMemo(() => [
    {
      value: '',
      label: 'Все',
    },
    ...VACATION_STATUSES,
  ]);

  const getListVacationsRequests = () => {
    const configRequest = {
      entity_type: 'vacation',
      actor,
      ...config,
    };

    if (selectedStatus && selectedStatus !== 'all') {
      configRequest.params = {
        status: selectedStatus,
      };
    }

    getListOfVacations(
      vacationStorageUUID,
      configRequest,
      [
        UserVacationConstants.GET_ALL_USER_VACATIONS_REQUEST,
        UserVacationConstants.GET_ALL_USER_VACATIONS_SUCCESS,
        UserVacationConstants.GET_ALL_USER_VACATIONS_FAILURE,
      ],
    );
  };

  const getColumnsCallback = useCallback((defaultColumns) => {
    const data = defaultColumns.reduce(((acc, i) => {
      if (i?.key === 'status') {
        return [
          {
            dataIndex: 'uuid',
            width: 20,
            title: '',
            render: (uuid, row) => row?.entity_type === 'vacation' && (
              <CopyURL
                entityType="vacation"
                entityUUID={uuid}
              />
            ),
          },
          {
            title: 'Имя',
            dataIndex: ['uinfo'],
            render: (uinfo) => `${get(uinfo, 'first_name')} ${get(uinfo, 'last_name')}`,
          }, i, ...acc];
      }

      if (i?.key === 'created') {
        return [
          ...acc,
          i,
          {
            className: 'col-actions',
            dataIndex: ['uuid'],
            title: 'Действия',
            render: (uuid, row) => {
              const isDisabled = (row?.entity_type === 'vacation' && row.params.status !== 'created') || (row?.entity_type === 'holidays' && row?.params?.status !== 'created');
              const type = row?.entity_type;
              const isRequestDisabled = row.params.status !== 'created';

              return (
                <Space>
                  <RequestVacation
                    row={row}
                    buttonProps={{
                      type: 'primary',
                      ghost: true,
                      disabled: isRequestDisabled,
                    }}
                    updateCallback={getListVacationsRequests}
                  />
                  <EditVacation
                    data={row}
                    type={type}
                    buttonProps={{ disabled: isDisabled }}
                    saveCallback={getListVacationsRequests}
                  />
                  <DeleteVacation
                    uuid={uuid}
                    type={type}
                    isDisabled={isDisabled}
                    deleteCallback={getListVacationsRequests}
                  />
                </Space>
              );
            },
          }];
      }
      return [...acc, i];
    }), []);

    return data;
  }, [allVacationsTotal]);

  const savePaginationConfigCallback = (data) => setConfig((prev) => ({ ...prev, ...data }));

  const onChangeStatus = (status) => {
    setStatus(status);

    setConfig((prev) => ({
      ...prev,
      offset: 0,
    }));
  };

  const currentSortCallback = (data) => {
    setConfig((prev) => ({
      ...omit(prev, ['order_by', 'order_by_params']),
      order: data.order,
      [data.orderBy === 'created' ? 'order_by' : 'order_by_params']: data.orderBy,
    }));
  };

  useEffect(() => {
    if (actor && vacationStorageUUID) {
      getListVacationsRequests();
    }
  }, [actor, vacationStorageUUID, config]);

  useEffect(() => () => {
    dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: 'vacationRequests' });
  }, [selectedStatus]);

  return (
    <Spin spinning={isFetching}>
      <Card
        title={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Заявки на отпуск
            {'  '}
            <AddNewVacation
              actor={actor}
              vacationType="vacation"
              type="primary"
              ghost
              size="medium"
              className="ml-3"
              saveCallback={getListVacationsRequests}
            />
          </div>
        )}
      >
        <Row gutter={[16, 16]}>
          <InfoCreateVacation />
          <Col span={24}>
            <Space
              style={{ marginBottom: 12 }}
              wrap
              split={<Divider type="vertical" />}
            >
              <span>Статус:</span>
              <Segmented
                options={optionsStatusFilter}
                value={selectedStatus}
                onChange={onChangeStatus}
              />
            </Space>

            <div className="overflow-auto">
              <TableVacations
                id="vacationRequests"
                total={allVacationsTotal}
                vacations={allVacations}
                getColumnsCallback={getColumnsCallback}
                savePaginationConfigCallback={savePaginationConfigCallback}
                currentSortCallback={currentSortCallback}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
}

export default RequestVacationsCard;

RequestVacationsCard.propTypes = {
  actor: string,
};
