import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card, Col, Descriptions, Divider, Flex, Tag, Timeline, Tooltip,
} from 'antd';
import {
  mdiArrowRightThin, mdiCheckboxMarkedCircleOutline, mdiChevronRight, mdiReload,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  capitalize, get, uniq, omit,
  toPairs,
} from 'lodash';
import humanizeDuration from 'humanize-duration';
import { ClockCircleOutlined } from '@ant-design/icons';
import PageWrapper from '../../components/PageWrapper';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useLogsAPI from '../../api/useLogsAPI';
import { getDepartment, getDepartmentUUID } from '../../redux/departments/selectors/departmentsSelector';
import { getDeliveryPartition } from '../../redux/config/selectors';
import { allLogTypes, getLogNameByType, getLogTagByType } from '../../constants/logConstantsAndUtils';
import { getFullNameOrInitials, getNameForActor } from '../../utils/utils54origins';
import { entityRead } from '../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../api/app_config';
import { daysToMilliseconds, diffDays } from '../../utils/dateTimeUtils';
import { maxCountOfVacationDays } from '../user/vacationsRequests/utils';
import ListOfLogs from '../../components/logs/ListOfLogs';
import CardOfEntityLogs from '../../components/logs/CardOfEntityLogs';

export default function EntitiesAndLogsPage() {
  const [currentEntity, setCurrentEntity] = useState(null);

  return (
    <PageWrapper
      title="Логи"
    >
      <Flex className="w-full -mt-4">
        <ListOfLogs
          // hiddenColumns={currentEntity ? ['owner'] : []}
          onSelectLog={setCurrentEntity}
          cardClassName={currentEntity ? 'w-2/3' : 'w-full'}
        />
        {currentEntity && (
          <CardOfEntityLogs
            currentLog={currentEntity?.log}
            onSelectLog={setCurrentEntity}
            currentEntity={currentEntity?.entity}
          />
        )}
      </Flex>
    </PageWrapper>
  );
}
