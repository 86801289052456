import { mdiChevronDown, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Card, Col,
  Spin,
} from 'antd';
import {
  head,
  isEmpty, pathOr, prop, propOr,
} from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { capitalize } from 'lodash';
import ListPermissions from './ListPermissions';
import SaveAndResetBtn from './SaveAndResetBtn';
import PermissionsContext from '../../context/PermissionsContext';
import {
  getFetchingForPSP, getPermsForPSP, getPSPAdminGroupActorUUID, getPSPBanGroupActorUUID, getSelectedActorPermsForPSP, getTotalForPSP,
} from '../../redux/permissions/permissionsSelector';
import { deleteEntityPerm } from '../../redux/permissions/permissionsActions copy';
import ExpandRowPermList from './ExpandRowPermList';
import { actorIsRoot } from '../../actors/utils';
import useURLParams from '../../hooks/useURLParams';

const initialDataInInfo = {
  usersSelectedGroup: [],
  groupsInSelectedUser: [],
  usersWithoutGroup: [],
};

const initTreeData = {
  currentActor: [],
  treePerm: [],
};
const hiddenInSumPermInfoTable = ['columnType', 'columnName', 'columnEntityName', 'columnDeletePerm'];

const hiddenDataInfoListPerm = ['columnEntityName', 'columnDeletePerm'];

const disabledDeleteBut = new Set();

function InfoAndSetPermissionsCard({ notSavedPermissions, className = '', setResetFromMainContainer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    entityUUID,
    partitionType,
    getEntityPerms,
    notSavedDataCallback,
    getActorTreePermissions,
    updateSwitchDataCallback,
    resetFromMainContainer,
    saveTreePermissions,
    defaultPerms,
    checkIsUserType,
    getName,
    viewingAlert,
  } = useContext(PermissionsContext);

  const { clearSearchParams, setSearchParams, getURLParams } = useURLParams();

  const adminGroupUUID = useSelector(getPSPAdminGroupActorUUID);
  const banGroupUUID = useSelector(getPSPBanGroupActorUUID);

  const {
    isFetchingUsersInGroup,
  } = useSelector(getFetchingForPSP);
  const {
    usersInGroup,
  } = useSelector(getPermsForPSP);
  const {
    usersInGroupTotal,
  } = useSelector(getTotalForPSP);

  const actorPerm = useSelector(getSelectedActorPermsForPSP);

  const [usersInGroupConfig, setUsersInGroupConfig] = useState({
    actor_type: ['classic_user', 'user'],
    limit: 15,
    offset: 0,
  });

  // const [listPermInInfo, setListPermInInfo] = useState(initialDataInInfo);
  const [localReset, setLocalReset] = useState(false);
  const [treeAndCurrentActor, setTreePermsAndCurrentActor] = useState(initTreeData);
  const [newPerms, setNewPerms] = useState([]);

  const { activeActorPerm, typeActor, expandedActorPerm } = getURLParams();

  // const {
  //   usersSelectedGroup,
  //   groupsInSelectedUser,
  //   usersWithoutGroup,
  // } = listPermInInfo;

  const resetData = () => {
    setNewPerms([]);
    setLocalReset(true);
    notSavedDataCallback('infoCard', false);
  };

  const getSelectedActor = async () => {
    const res = await getEntityPerms({
      params: {
        actors: [activeActorPerm],
      },
      type: 'selectedPerm',
    });

    // if (res?.data?.[0]) {
    //   setActorPerm(res?.data?.[0])
    // }
  };
  const getSelectedActorTreePerms = async () => {
    if (activeActorPerm === 'USER_WITHOUT_GROUP') {
      setTreePermsAndCurrentActor(initTreeData);
      return;
    }

    const res = await getActorTreePermissions(activeActorPerm);

    if (isEmpty(res) || !res.some((i) => i.actor_uuid === activeActorPerm
      && i.entity_uuid === entityUUID)
    ) {
      res.push({
        ...actorPerm,
        ...defaultPerms,
        entity_type: 'current entity',
        entity_uuid: entityUUID,
      });
    }
    const newData = res.map((item) => ({ ...item, customId: `${item.actor_uuid}${item.entity_uuid}` }));

    setTreePermsAndCurrentActor({
      currentActor: [{ ...actorPerm, disabled: true }],
      treePerm: newData,
    });
  };

  const onChangeUserFunc = async () => {
    await getSelectedActor();
    await getSelectedActorTreePerms();
  };

  const removeEntityPerm = async (item) => {
    if (disabledDeleteBut?.has(item?.perm_uuid)) return;
    disabledDeleteBut.add(item?.perm_uuid);
    const data = {
      perm_uuid: prop('perm_uuid', item),
    };

    await dispatch(
      deleteEntityPerm({
        data,
        partitions: partitionType,
      }),
    );
    await onChangeUserFunc();
    disabledDeleteBut.delete(item?.perm_uuid);
  };

  const savePermissions = async () => {
    await saveTreePermissions(newPerms);
    await getSelectedActor();
    notSavedDataCallback('infoCard', false);
    setNewPerms([]);
    setResetFromMainContainer(true);
  };

  // const clickHeaderColumn = (e) => e.stopPropagation();

  const closeInfoCard = () => {
    clearSearchParams(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
    resetData();
  };

  const onExpandRow = (row) => {
    if (row.actor_uuid === expandedActorPerm) {
      clearSearchParams(['expandedActorPerm']);
      return;
    }
    setSearchParams({ expandedActorPerm: row.actor_uuid });
  };

  const expandRowActor = {
    expandedRowRender: (row) => (
      <ExpandRowPermList
        item={row}
        removeEntityPermCallback={removeEntityPerm}
        notSavedPermissions={notSavedPermissions}
      />
    ),
    showExpandColumn: true,
    expandedRowKeys: [expandedActorPerm],

    expandIcon: ({ expanded, rowKey, record }) => (
      <Icon
        path={mdiChevronDown}
        size={1}
        rotate={expanded ? 180 : 0}
      />
    ),
  };

  const rowEventsTable = (row) => ({
    onClick: () => { onExpandRow(row); },
  });

  const selectRowTable = {
    mode: 'radio',
    // clickToSelect: true,
    hideSelectColumn: true,
    // classes: activeActorPerm && 'row-perm-selected',
    selectedRowKeys: [expandedActorPerm],
  };

  const showListPermissions = (dataTable, title, wrapperStyle, {
    showButtonShowMore = false,
    onShowMoreCallback,
  }) => (
    <ListPermissions
      rowEvents={rowEventsTable}
      dataTable={dataTable}
      wrapperStyle={wrapperStyle}
      cardName="infoCard"
      onShowMoreCallback={onShowMoreCallback}
      showButtonShowMore={showButtonShowMore}
      hiddenData={hiddenDataInfoListPerm}
      title={title}
      expandRow={expandRowActor}
      selectRow={selectRowTable}
    />
  );

  const changeSwitchTreePerm = (currentItem, newPermsData) => {
    const perms = updateSwitchDataCallback(currentItem, newPerms, newPermsData);
    setNewPerms(perms);
    notSavedDataCallback('infoCard', !isEmpty(perms));
  };

  const buttonCloseCard = (
    <Button
      size="small"
      className="customAntOutOrangeBtn mr-1 mt-1"
      onClick={closeInfoCard}
    >
      <div className="flex align-item-center justify-center ">
        <Icon
          className="mr-1"
          path={mdiClose}
          size={0.8}
        />
        {capitalize(
          t('wms.buttons.close', 'close'),
        )}
      </div>
    </Button>
  );

  const checkNameGroup = (name) => getName(head(prop('currentActor', treeAndCurrentActor))) === name;

  const getUsersInGroup = ({
    withClear = false,
    params = {},
  }) => {
    getEntityPerms({
      params: {
        uinfo: {
          groups: [activeActorPerm],
        },
        ...usersInGroupConfig,
        ...params,
      },
      type: 'usersInGroup',
      withClear,
    });
    setUsersInGroupConfig((prev) => ({
      ...prev,
      ...params,
    }));
  };

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (notSavedPermissions.infoCard) {
      resetData();
    }
  }, [localReset]);

  useEffect(() => {
    if (activeActorPerm && actorPerm) {
      getSelectedActorTreePerms();
      // initialFunc();
    }
  }, [
    JSON.stringify(actorPerm),
    activeActorPerm,
    adminGroupUUID,
    banGroupUUID,
  ]);

  useEffect(() => {
    if (activeActorPerm) {
      getSelectedActor();
    }
  }, [activeActorPerm]);

  useEffect(() => {
    if (typeActor && activeActorPerm) {
      getUsersInGroup({
        withClear: true,
        params: {
          limit: 15,
          offset: 0,
        },
      });
    }
  }, [typeActor, activeActorPerm]);

  if (actorIsRoot(head(prop('currentActor', treeAndCurrentActor)))) {
    return (
      <Card className="dangerCard ml-4">
        {/* {buttonCloseCard} */}
        {viewingAlert(
          'success',
          capitalize('Это root'),
          capitalize('Root имеет полный доступ ко всем сущностям'),
        )}
      </Card>
    );
  }

  return (
    <Card className={`dangerCard ml-4 pr-4 w-full ${className}`}>
      <Col className="pb-2 w-full">
        {/* {buttonCloseCard} */}

        {checkNameGroup('BAN') && viewingAlert(
          'error',
          capitalize('Это Бан группа'),
          capitalize('Пользователи этой группы не имеют доступа'),
        )}

        {checkNameGroup('ADMIN') && viewingAlert(
          'success',
          // capitalize(t('wms.noun.success')),
          capitalize('Это группа администраторов'),
          capitalize('Пользователи этой группы имеют полный доступ ко всем сущностям'),
        )}

        {(activeActorPerm !== 'USER_WITHOUT_GROUP'
          && !checkNameGroup('ADMIN')
          && !checkNameGroup('BAN'))
          && (
            <>
              <div className="header_table_title orange_color mt-1">
                {getName(head(prop('currentActor', treeAndCurrentActor)))}
              </div>

              <ListPermissions
                dataTable={prop('currentActor', treeAndCurrentActor)}
                cardName="creatorCard"
                hiddenData={hiddenInSumPermInfoTable}
                headerClasses="hidden"
              />

              <div className="header_table_title orange_color justify-between">
                {typeActor === 'group'
                  ? capitalize('Все разрешения группы')
                  : capitalize('Все разрешения пользователя')}
                <SaveAndResetBtn
                  // withoutWrapper
                  disabledButtons={isEmpty(newPerms)}
                  saveCallback={savePermissions}
                  resetCallback={resetData}
                />
              </div>
              <ListPermissions
                dataTable={prop('treePerm', treeAndCurrentActor)}
                cardName="infoCard"
                customId="customId"
                resetSwitchBool={localReset}
                removePermCallback={removeEntityPerm}
                changeSwitchCallback={changeSwitchTreePerm}
              />
            </>
          )}
      </Col>

      {typeActor === 'group' && (
        <Spin spinning={isFetchingUsersInGroup}>
          {
            showListPermissions(
              usersInGroup,
              // capitalize(t('wms.permissions.headers.users', 'Users')),
              capitalize('Пользователи в группе'),
              null,
              {
                showButtonShowMore: usersInGroupTotal > usersInGroup.length,
                onShowMoreCallback: () => getUsersInGroup({
                  params: {
                    limit: 50,
                    offset: usersInGroupConfig.offset + usersInGroupConfig.limit,
                  },
                }),
              },

            )
          }
        </Spin>
      )}

      {/* {activeActorPerm === 'USER_WITHOUT_GROUP' && showListPermissions(
        usersWithoutGroup,
        // capitalize(t('wms.permissions.headers.users', 'Users')),
        capitalize(t('wms.labels.users_in_a_group')),
      )} */}

      {/* {!isEmpty(groupsInSelectedUser)
        && showListPermissions(
          groupsInSelectedUser,
          //  capitalize(t('wms.permissions.headers.Groups', 'Groups')),
          capitalize(t('wms.labels.groups_the_user_belongs_to')),
        )} */}
    </Card>
  );
}

export default InfoAndSetPermissionsCard;
