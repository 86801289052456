import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex, Modal,
} from 'antd';
import { useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import cn from 'classnames';
import EntityDepartmentForArtifact from '../common/EntityDepartmentForArtifact';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import useAPI from '../../../../api/useAPI';
import ListOfUsersHRM54 from '../users/ListOfUsersHRM54';
import ListOfCurrentUsers from '../users/ListOfCurrentUsers';
import useDefaultDepartment from '../../../../redux/departments/hooks/useDefaultDepartment';

export default function AddEmployeeButton({
  artifactsStorage,
  defaultDepartmentUUID = '',
  onCreateArtifactCallback,
}) {
  const [department, setDepartment] = useState('');

  const { uuid: defaultDepartment } = useDefaultDepartment(false);

  const [isOpen, setIsOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [usersUUIDs, setUsersUUIDs] = useState([]);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const {
    updateUserEntity,
    createEntityPermissions,
  } = useAPI();

  const onUserSelect = (value) => {
    if (!usersUUIDs?.includes(value?.actor)) {
      setUsers((prev) => [...prev, value]);
      setUsersUUIDs((prev) => [...prev, value.actor]);
    } else {
      setUsers((prev) => prev.filter((user) => user.actor !== value?.actor));
      setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value?.actor));
    }
  };

  const onCreateArtifact = (user) => {
    createEntityPermissions({
      data: {
        entity_uuid: department,
        data: [{
          actor: user?.actor,
          create: true,
          list: true,
          read: true,
          update: false,
          delete: false,
          set: false,
        }],
      },
    });
    updateUserEntity(user?.value, {
      parent: department,
    });
  };

  const onSave = async () => {
    if (users?.length) {
      await Promise.all(users.map((element) => onCreateArtifact(element)));

      if (onCreateArtifactCallback) {
        onCreateArtifactCallback();
      }
    }

    onCloseModal();
  };

  useEffect(() => {
    if (isOpen) {
      setDepartment(defaultDepartmentUUID);
    } else {
      setDepartment(null);
      setUsers([]);
      setUsersUUIDs([]);
    }
  }, [isOpen, defaultDepartmentUUID]);

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Добавить подрядчиков
      </Button>
      <Modal
        open={isOpen}
        title="Добавление подрядчиков"
        onCancel={onCloseModal}
        footer={null}
        centered
        width={730}
        destroyOnClose
      >
        <Flex align="center" justify="space-between" className="mb-3">
          <EntityDepartmentForArtifact
            department={department}
            artifactsStorage={artifactsStorage}
            onEditCallback={({ department: dep }) => setDepartment(dep)}
          />
        </Flex>
        <Flex
          justify="space-between"
          gap={8}
        >
          <Flex
            vertical
          >
            <span
              className="text-base"
            >
              Пользователи:
            </span>
            <ListOfUsersHRM54
              parent={defaultDepartment}
              selected={usersUUIDs}
              onUserSelect={onUserSelect}
            />
          </Flex>
          <Flex
            vertical
          >
            <span
              className="text-base"
            >
              Новые подрядчики:
            </span>
            <ListOfCurrentUsers
              users={users}
              onUserChange={onUserSelect}
            />
          </Flex>
        </Flex>
        <Flex
          className="mt-3"
          justify="end"
        >
          <Button
            className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
            onClick={onCloseModal}
          >
            Отмена
          </Button>
          <Button
            disabled={(!users?.length || !department)}
            className={cn('border-green-500 text-green-500 hover:bg-green-500 hover:!text-white', {
              'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white': !users?.length || !department,
            })}
            onClick={onSave}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

AddEmployeeButton.propTypes = {
  parent: string,
  artifactsStorage: string,
  defaultDepartmentUUID: string,
  onCreateArtifactCallback: func,
};
