import { mdiContentSaveOutline, mdiRestart } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Row } from 'antd';
import { capitalize } from 'lodash';

function SaveAndResetBtn({
  className,
  saveCallback,
  disabledButtons,
  resetCallback,
  withoutWrapper,
}) {
  const { t } = useTranslation();

  const buttonsData = (
    <>
      {/* <UnSaveDataConfirmNew */}
      {/*  dataIsNotSave={ */}
      {/*    !disabledButtons */}
      {/*  } */}
      {/* > */}
      {/*  <BaseButton */}
      {/*    className="mr-1 mt-1" */}
      {/*    onClick={saveCallback} */}
      {/*    disabled={disabledButtons} */}
      {/*    size="small" */}
      {/*    type="primary" */}
      {/*    id="saveresetbtn" */}
      {/*  > */}
      {/*    <Icon */}
      {/*      size={0.7} */}
      {/*      className="mr-1" */}
      {/*      path={mdiContentSaveOutline} */}
      {/*    /> */}

      {/*    {capitalize(t('wms.verb.save', 'save'))} */}
      {/*  </BaseButton> */}
      {/* </UnSaveDataConfirmNew> */}
      <Button
        className="mr-1 mt-1"
        onClick={saveCallback}
        disabled={disabledButtons}
        size="small"
        type="primary"
        id="saveresetbtn"
      >
        <Icon
          size={0.7}
          className="mr-1"
          path={mdiContentSaveOutline}
        />

        {capitalize(t('wms.verb.save', 'save'))}
      </Button>
      <Button
        size="small"
        className={`${!disabledButtons && 'customAntOutWarningBtn'} mr-1 mt-1`}
        disabled={disabledButtons}
        onClick={resetCallback}
      >
        <div className="flex align-item-center justify-center ">
          <Icon
            className="mr-1"
            path={mdiRestart}
            size={0.8}
          />
          {capitalize(
            t('wms.buttons.reset', 'reset'),
          )}
        </div>
      </Button>
    </>
  );

  return (
    <>
      {withoutWrapper
        ? buttonsData
        : (
          <div className={`flex ${className}`}>
            {buttonsData}
          </div>
        )}
    </>

  );
}

SaveAndResetBtn.propTypes = {
  className: PropTypes.string,
  saveCallback: PropTypes.func,
  disabledButtons: PropTypes.bool,
  resetCallback: PropTypes.func,
};

export default SaveAndResetBtn;
