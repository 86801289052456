import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrCreateArtifactsStorage } from '../actions/artifactsActions';

import { getDeliveryPartition } from '../../config/selectors';
import {
  getArtifactsStorage,
  getArtifactsStorageUUID,
  getIsFetchingArtifactsStorage,
} from '../selectors/artifactsStorageSelector';

function useArifactsStorage(isUpdateRequired = true) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const artifactsStorage = useSelector(getArtifactsStorage);
  const artifactsStorageUUID = useSelector(getArtifactsStorageUUID);
  const isFetching = useSelector(getIsFetchingArtifactsStorage);

  const onReloadFunc = () => {
    if (partitionUuid) {
      dispatch(getOrCreateArtifactsStorage(partitionUuid));
    }
  };

  useEffect(() => {
    if (partitionUuid && isUpdateRequired) {
      onReloadFunc();
    }
  }, [partitionUuid, isUpdateRequired]);

  return {
    uuid: artifactsStorageUUID,
    entity: artifactsStorage,
    isFetching,
    onReloadFunc,
  };
}

export default useArifactsStorage;
