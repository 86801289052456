import { isEmpty, prop } from 'ramda';

import axiosRequest from '../api';

export function getListOfAffix({
  data,
  partition,
  constants,
  doNotCheckError = false,
  aborting = false,
  cancelToken,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    const onSuccessData = (res = []) => onSuccess({
      affix_type: prop('affix_type', data),
      affix_uuids: prop('affix_uuids', res),
      total: prop('total', res),
    });

    dispatch(
      axiosRequest.post('entity/affix/list', constants, data, {
        partition,
        onSuccess: onSuccessData,
        onFailure,
        doNotCheckError,
        aborting,
        cancelToken,
      }),
    );
  });
}

export function getListAndReadAffix({
  data,
  constants = ['REQUEST_AFFIX', 'SUCCESS_AFFIX', 'FAILURE_AFFIX'],
  partition,
  doNotCheckError = false,
  aborting = false,
  cancelToken,
  requestOptions,
}) {
  const configList = {
    data,
    partition,
    constants: [constants[0], '', ''],
  };

  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(getListOfAffix(configList))
      .then((listParams) => {
        if (isEmpty(listParams.affix_uuids)) {
          if (typeof constants[1] === 'function') {
            dispatch({ type: constants[1]?.type, payload: [] });
          } else {
            dispatch({ type: constants[1], payload: [] });
          }
          onSuccess({ data: [], total: 0 });
          return;
        }
        // console.log('listParams',listParams)
        const onSuccessRead = (res) => onSuccess({
          data: res,
          total: prop('total', listParams),
        });

        const newParams = { ...listParams };

        if ('with_files' in data) {
          newParams.with_files = data.with_files;
        }

        dispatch(
          axiosRequest.post(
            'entity/affix/read',
            ['', constants[1], constants[2]],
            newParams,
            requestOptions || {
              partition,
              onSuccess: onSuccessRead,
              onFailure,
              doNotCheckError,
              aborting,
              cancelToken,
            },
          ),
        );
      })
      .catch((error) => {
        onFailure(error);
      });
  });
}

export function affixRead({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition,
  signal,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/affix/read', constants, data, {
        partition,
        onSuccess,
        onFailure,
        signal,
      }),
    );
  });
}
export function affixCreate({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition,
  signal,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/affix/create', constants, data, {
        partition,
        onSuccess,
        onFailure,
        signal,
      }),
    );
  });
}

export function affixUpdate({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition,
  signal,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/affix/update', constants, data, {
        partition,
        onSuccess,
        onFailure,
        signal,
      }),
    );
  });
}

export function affixDelete({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition,
  signal,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post('entity/affix/delete', constants, data, {
        partition,
        onSuccess,
        onFailure,
        signal,
      }),
    );
  });
}
