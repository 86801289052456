import { Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { capitalize, get } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import {
  array, bool, func, number, object, string,
} from 'prop-types';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';
import humanizeDuration from 'humanize-duration';
import { daysToMilliseconds, diffDays, formatDate } from '../../../utils/dateTimeUtils';
import { globalResetPageKey } from '../../../redux/globalUpdate/selectors';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import { nameSortTable } from '../../../utils/tableUtils';
import { getVacationStatusColor, getStatusParams, getVacationType } from '../../../utils';
import CustomTablePagination from '../../../components/commonComponents/customTablePagination/CustomTablePagination';
import { getLabelByStatus, maxCountOfVacationDays } from './utils';

export default function TableVacations({
  id,
  total,
  hide,
  onChangeCallback,
  loading,
  vacations,
  isSorterStatus = true,
  getColumnsCallback,
  savePaginationConfigCallback,
  rowClassName,
  currentSortCallback,
  defaultPageSize = 10,
  hidePagination = false,
  onRow = null,
}) {
  const dispatch = useDispatch();

  const globalResetPage = useSelector(globalResetPageKey);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const renderPeriod = (cell, row) => {
    const startDate = get(row, 'params.start_date');
    const endDate = get(row, 'params.end_date');
    const status = get(row, 'params.status');
    const days = diffDays(startDate, endDate);
    const diffDate = daysToMilliseconds(days);
    return (
      <div className="flex items-center">
        <div className={`mr-4 ${days > maxCountOfVacationDays && status !== 'approved' ? 'text-red-600' : 'text-green-600'} font-medium`}>
          {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
          {' '}
        </div>
        {dayjs(startDate).locale('ru').format('ddd DD-MM-YYYY')}
        <Icon path={mdiArrowRightThin} className="mx-3" size={1} />
        {dayjs(endDate).locale('ru').format('ddd DD-MM-YYYY')}
      </div>
    );
  };

  const columns = getColumnsCallback([
    {
      className: 'col-status',
      title: 'Статус',
      dataIndex: ['params', 'status'],
      sorter: isSorterStatus,
      key: 'status',
      render: (status) => (status ? (
        <Tag
          color={getVacationStatusColor(status)}
        >
          {getLabelByStatus(status)}
        </Tag>
      ) : (
        '-'
      )),
    },
    {
      className: 'col-date',
      title: 'Период',
      key: 'date',
      render: renderPeriod,
    },
    {
      dataIndex: 'entity_type',
      className: hide?.type ? 'hidden' : 'col-status',
      title: 'Тип',
      key: 'type',
      render: (entity_type) => {
        const { label, colorTag } = getVacationType(entity_type);
        return entity_type ? (
          <Tag
            style={{
              color: '#fff',
              borderColor: 'transparent',
            }}
            color={colorTag}
          >
            {capitalize(label)}
          </Tag>
        ) : (
          '-'
        );
      },
    },
    {
      className: 'col-days',
      title: 'Дата создания',
      dataIndex: ['created'],
      key: 'created',
      render: (created_at) => formatDate(created_at),
    },
  ]);

  const onShowSizeChange = (prevSize, newSize) => {
    setPageSize(newSize);
    setCurrentPage(1);
    savePaginationConfigCallback({
      offset: 0,
      limit: newSize,
    });
  };

  const onChangePage = (page) => {
    const offset = pageSize * (page - 1);
    setCurrentPage(page);

    savePaginationConfigCallback({
      offset,
      limit: pageSize,
    });
  };

  const onChange = (pagination, filters, sorter) => {
    if (onChangeCallback) {
      onChangeCallback(pagination, filters, sorter);
    }
    if (currentSortCallback && !get(sorter, 'order')) {
      currentSortCallback({
        orderBy: 'created',
        order: 'desc',
      });
    } else if (currentSortCallback) {
      currentSortCallback({
        orderBy: get(sorter, 'columnKey'),
        order: nameSortTable[get(sorter, 'order')],
      });
    }
  };

  useEffect(() => {
    if (globalResetPage && globalResetPage === id) {
      setCurrentPage(1);
      dispatch({ type: GlobalUpdateConstants.SET_RESET_PAGE, payload: '' });
    }
  }, [globalResetPage]);

  return (
    <>
      <Table
        className="vacations-table table-selectable w-full"
        rowKey={(row) => row.uuid}
        columns={columns}
        onChange={onChange}
        loading={loading}
        rowClassName={rowClassName}
        dataSource={vacations}
        onRow={onRow}
        size="small"
        pagination={false}
      />
      {!hidePagination && (
      <CustomTablePagination
        total={total}
        currentPage={currentPage}
        pageLimit={pageSize || 10}
        pageSizeOptions={['10', '25', '50', '100']}
        onShowSizeChange={onShowSizeChange}
        onChangePage={onChangePage}
      />
      )}
    </>
  );
}

TableVacations.propTypes = {
  id: string.isRequired,
  rowClassName: func,
  hide: object,
  vacations: array.isRequired,
  onRow: func,
  loading: bool,
  savePaginationConfigCallback: func.isRequired,
  currentSortCallback: func,
  total: number,
  getColumnsCallback: func,
  defaultPageSize: number,
  onChangeCallback: func,
  isSorterStatus: bool,
};
