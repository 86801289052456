import { get } from 'lodash';

export const getArtifacts = (state) => get(state, 'Departments.artifacts.artifacts', []);

export const getTotalArtifacts = (state) => get(state, 'Departments.artifacts.total', 0);

export const getIsFetchingArtifacts = (state) => get(state, 'Departments.artifacts.fetching', false);

export const getArtifact = (state) => get(state, 'Departments.artifacts.artifact', {});

export const getArtifactUUID = (state) => get(state, 'Departments.artifacts.artifact.uuid', '');

export const getIsFetchingArtifact = (state) => get(state, 'Departments.artifacts.fetchingSingle', false);
