import { Button, Card, Col, Flex, Spin, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { CSVLink } from 'react-csv';
import {
  mdiCommentProcessing, mdiFileExportOutline, mdiHistory, mdiOpenInNew,
} from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash';
import BaseTableWithPagination from '../../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import DateSwitcher from '../../../components/dateSwitcher/DateSwitcher';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getListAndReadEntities } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { isRootOrAdmin } from '../../../redux/profile/selectors';
import MonthlyReportLogsModal from '../../../components/logs/MonthlyReportLogsModal';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';

export default function DepartmentsStatistics() {
  const dispatch = useDispatch();

  const history = useHistory();

  const isAdmin = useSelector(isRootOrAdmin);

  const partitionUUID = useSelector(getDeliveryPartition);

  const [currentMonth, setCurrentMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));

  const [departmentReports, setDepartmentReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 50,
    offset: 0,
  });

  const [historyUUID, setHistoryUUID] = useState('');

  const onChangeDate = (date) => {
    setCurrentMonth(dayjs(date).format('YYYY-MM'));
  };

  const onSearch = (value) => {
    setCurrentPage(1);
    setConfig((prev) => ({
      ...prev,
      offset: 0,
      search_data: {
        fields: {
          params: {
            title: 'title',
          },
        },
        value,
      },
    }));
  };

  const onSort = (a, b, field) => {
    if (!a?.report) {
      return -1;
    }
    if (!b?.report) {
      return 1;
    }

    return get(a, ['report', 'params', 'statistics', field], 0) - get(b, ['report', 'params', 'statistics', field], 0);
  };

  const searchInTitle = (
    <Col className="d-flex align-item-end" span={24}>
      <AntDesignSearchBox54origins
        onSearch={onSearch}
        maxWidth={200}
      />
    </Col>
  );

  const columns = [
    {
      // title: 'Отдел',
      title: searchInTitle,
      width: '15%',
      dataIndex: ['params', 'title'],
      render: (cell = '', row) => (
        <Flex
          align="center"
        >
          {isAdmin && row?.uuid && (
          <Tooltip
            title="Открыть страницу отдела в новой вкладке браузера"
          >
            {/* <Button
              size="small"
              onClick={(e) => {
                window.open(`/departments/departmentsInfo?department=${row?.uuid}`, '_blank');
                e.preventDefault();
              }}
              style={{
                width: 30,
                height: 30,
              }}
              className="w-max mr-2 text-blue-500 border border-blue-500 hover:text-blue-500 hover:bg-blue-100 !rounded-full"
            > */}
            <Flex
              onClick={(e) => {
                window.open(`/departments/departmentsInfo?department=${row?.uuid}`, '_blank');
                e.preventDefault();
              }}
              style={{
                width: 30,
                height: 30,
              }}
              className="w-max mr-2 text-blue-500 border border-blue-500 hover:text-blue-500 hover:bg-blue-100 !rounded-full"
              align="center"
              justify="center"
            >
              <>
                <Icon path={mdiOpenInNew} size={1} />
              </>
            </Flex>
              {/* <Icon path={mdiFolderOpen} size={1} />
              <Icon path={mdiArrowRight} size={1} /> */}
              {/* <span className="ml-1">
                  Перейти к отделу
                </span> */}
              {/* </Flex> */}
            {/* </Button> */}
          </Tooltip>
          )}
          {(cell?.length > 40 ? (
            <Tooltip title={cell}>
              {cell?.slice(0, 37)}
              ...
            </Tooltip>
          ) : cell)}
        </Flex>
      ),
    },
    {
      title: 'Всего залоганных часов',
      width: '10%',
      dataIndex: ['report', 'allHours'],
      sortOrder: 'descend',
      render: (time, row) => {
        if (!row?.report) {
          return '-';
        }
        return +get(row, ['report', 'params', 'statistics', 'totalWorks'], 0)
        + +get(row, ['report', 'params', 'statistics', 'totalBenches'], 0);
      },
      sorter: (a, b) => {
        if (!a?.report) {
          return -1;
        }
        if (!b?.report) {
          return 1;
        }

        return +get(a, ['report', 'params', 'statistics', 'totalWorks'], 0)
        + +get(a, ['report', 'params', 'statistics', 'totalBenches'], 0)
        - +get(b, ['report', 'params', 'statistics', 'totalWorks'], 0)
        - +get(b, ['report', 'params', 'statistics', 'totalBenches'], 0);
      },
    },
    {
      title: 'Проектная работа',
      width: '8%',
      dataIndex: ['report', 'params', 'statistics', 'totalWorks'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
      className: '!bg-green-200',
      sorter: (a, b) => onSort(a, b, 'totalWorks'),
    },
    {
      title: 'Бенчи',
      width: '8%',
      className: '!bg-orange-200',
      dataIndex: ['report', 'params', 'statistics', 'totalBenches'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
      sorter: (a, b) => onSort(a, b, 'totalBenches'),
    },
    {
      title: 'Клиентский пр.',
      width: '8%',
      dataIndex: ['report', 'params', 'statistics', 'clientWork'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
      sorter: (a, b) => onSort(a, b, 'clientWork'),
    },
    {
      title: 'Внутренний пр.',
      width: '8%',
      dataIndex: ['report', 'params', 'statistics', 'internalWork'],
      render: (cell, row) => (row?.report ? (cell || 0) : '-'),
      sorter: (a, b) => onSort(a, b, 'internalWork'),
    },
    {
      title: 'Отгул',
      width: '10%',
      dataIndex: ['report', 'params', 'statistics', 'dayoff'],
      sorter: (a, b) => onSort(a, b, 'dayoff'),
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
    },
    {
      title: 'Больничный',
      width: '10%',
      dataIndex: ['report', 'params', 'statistics', 'sickday'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
      sorter: (a, b) => onSort(a, b, 'sickday'),
    },
    {
      title: 'Нерабочие часы',
      className: '!bg-red-200',
      width: '8%',
      dataIndex: ['report', 'params', 'statistics', 'totalDayOff'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
      sorter: (a, b) => onSort(a, b, 'totalDayOff'),
    },
    {
      title: 'Отпуск',
      width: '10%',
      className: '!bg-sky-200',
      dataIndex: ['report', 'params', 'statistics', 'vacation'],
      render: (cell, row) => <span className={+cell > 0 ? 'text-red-500' : ''}>{row?.report ? (cell || 0) : '-'}</span>,
      sorter: (a, b) => onSort(a, b, 'vacation'),
    },
    {
      title: 'Комментарий',
      width: '5%',
      dataIndex: ['report', 'params', 'statistics', 'comment'],
      render: (cell, row) => (
        <div className="comment-wrapper ml-2">
          {cell ? (
            <Tooltip title={cell} color="#0958d9">
              <Icon path={mdiCommentProcessing} size={1.5} color="#0958d9" />
            </Tooltip>
          ) : '-'}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: ['report', 'uuid'],
      width: '15%',
      render: (cell, row) => (
        <Flex
          vertical
        >
          {cell && (
          <Tooltip
            title="Открыть историю отчета"
          >
            <Button
              size="small"
              onClick={(e) => {
                setHistoryUUID(cell);
                e.preventDefault();
              }}
              className="mt-2 text-blue-500 border border-blue-500 hover:text-blue-500 hover:bg-blue-100"
            >
              <Flex
                align="center"
              >
                <Icon path={mdiHistory} size={1} />
                {/* <Icon path={mdiFileDocument} size={1} />
              <Icon path={mdiTable} size={1} /> */}
                <span className="ml-1">
                  {/* Открыть */}
                  {/* историю */}
                  История
                </span>
              </Flex>
            </Button>
          </Tooltip>
          )}
        </Flex>
      ),
    },
  ];

  const dataForDownload = useMemo(() => {
    const data = new Map([]);
    departmentReports.forEach((depReport) => {
      const downloadColumns = {
        Отдел: get(depReport, 'params.title') || '-',
        'Всего залоганных часов': (get(depReport, 'params.params.statistics.totalWorks')
          + get(depReport, 'params.params.statistics.totalBenches')) || 0,
        'Проектных часов': get(depReport, 'params.statistics.totalWorks') || 0,
        Бенчи: get(depReport, 'params.params.statistics.totalBenches') || 0,
        'Нерабочих часов': get(depReport, 'params.params.statistics.totalDayOff') || 0,
        'Клиентский пр.': get(depReport, 'params.params.statistics.clientWork') || 0,
        'Внутренний пр.': get(depReport, 'params.statistics.internalWork') || 0,
        Отгул: get(depReport, 'params.params.statistics.dayoff') || 0,
        Больничный: get(depReport, 'params.params.statistics.sickday') || 0,
        Отпуск: get(depReport, 'params.params.statistics.vacation') || 0,
        Комментарий: get(depReport, 'params.params.statistics.comment') || '-',
        Создан: get(depReport, 'created') || '-',
      };
      data.set(depReport?.params, downloadColumns);
    });
    return [...data.values()];
  }, [JSON.stringify(departmentReports)]);

  const initFunc = async () => {
    if (partitionUUID) {
      setIsFetching(true);
      const departments = await dispatch(getListAndReadEntities({
        data: {
          parent: partitionUUID,
          entity_type: 'department',
          depth: 0,
          ...config,
        },
        constants: [
          'GET_LIST_OF_DEPARTMENT_REQUEST_REQUEST',
          'GET_LIST_OF_DEPARTMENT_REQUEST_SUCCESS',
          'GET_LIST_OF_DEPARTMENT_REQUEST_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }));

      if (!departments?.data?.length) {
        setDepartmentReports([]);
        setTotal(0);
        setIsFetching(false);
        return;
      }

      const reports = await dispatch(getListAndReadEntities({
        data: {
          parent: partitionUUID,
          entity_type: 'departmentReport',
          depth: 0,
          params: {
            department: departments?.data?.map((el) => el?.uuid),
            date: currentMonth,
          },
        },
        constants: [
          'GET_LIST_OF_DEPARTMENT_REQUEST_REQUEST',
          'GET_LIST_OF_DEPARTMENT_REQUEST_SUCCESS',
          'GET_LIST_OF_DEPARTMENT_REQUEST_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }));

      setDepartmentReports(departments?.data?.map((dep) => {
        const report = reports?.data?.find((rep) => rep?.parent === dep?.uuid);

        return {
          ...dep,
          report,
        };
      }));
      setTotal(departments?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (currentMonth && partitionUUID) {
      initFunc();
    }
  }, [
    partitionUUID,
    currentMonth,
    JSON.stringify(config),
  ]);

  return (
    <>
      <Card
        title={(
          <Flex align="center" justify="space-between">
            <Flex>
              <DateSwitcher
                currentDate={currentMonth}
                onChangeHandler={onChangeDate}
              />
            </Flex>
            <Flex>
              <CSVLink data={dataForDownload} filename={`отчет за ${currentMonth}.csv`}>
                <Button type="primary" className="flex items-center gap-2" style={{ marginTop: -6 }}>
                  <Icon path={mdiFileExportOutline} size={1} />
                  Экспорт в CSV
                </Button>
              </CSVLink>
            </Flex>
          </Flex>
    )}
        className="card-h-full"
      >
        <Spin
          spinning={isFetching}
        >
          <BaseTableWithPagination
            columns={columns}
            data={departmentReports}
            rowKey="uuid"
            size="small"
            headerRowClassName="universal_header_table_row color-gray"
            // rowClassName={(row) => (`cursor-pointer ${row?.report ? '' : '!bg-red-50'}`)}
            rowClassName={(row) => ('cursor-pointer')}
            hideListSizeOption={false}
            useCustomPagination
            pageSizeOptions={[10, 25, 50, 100]}
            disablePagination={false}
            pageLimit={get(config, 'limit', 5)}
            total={total}
            changePageLimit={(newLimit) => {
              setCurrentPage(1);
              setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
            }}
            newCurrentPage={currentPage}
            onChangePageCallback={(newPage) => {
              setCurrentPage(newPage);
              setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
            }}
            getPaginationOptions={(newPart) => {
              const valueSearch = get(newPart, 'search', '');

              if (valueSearch) {
                setConfig((prev) => ({
                  ...prev,
                  ...omit(newPart, ['search']),
                  searchData: {
                    value: valueSearch,
                    ignore_case: true,
                    fields: { params: ['title', 'id'] },
                  },
                }));
              } else {
                setConfig((prev) => ({ ...omit(prev, 'searchData'), newPart }));
              }
            }}
          />
        </Spin>
      </Card>
      {historyUUID && (
      <MonthlyReportLogsModal
        entityType="departmentReport"
        entityUUID={historyUUID}
        isOpen={historyUUID}
        onClose={() => setHistoryUUID('')}
      />
      )}
    </>
  );
}

DepartmentsStatistics.propTypes = {};
