import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Flex } from 'antd';

import useArifactsStorage from '../../../redux/departments/hooks/useArifactsStorage';

import DepartmentRoot from './DepartmentRoot';

import './department.scss';
import UniGeneralButtons from '../../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import PageWrapper from '../../../components/PageWrapper';
import EmployeeRequestsPage from '../employeeRequests/EmployeeRequestsPage';
import DepartmentLogsPage from './DepartmentLogsPage';

export default function DepartmentPage() {
  useArifactsStorage(true);
  const history = useHistory();

  const tabs = [
    { name: 'departmentsInfo', title: 'Отделы' },
    { name: 'requests', title: 'Заявки' },
    { name: 'logs', title: 'Логи' },
  ];

  const [activeTab, setActiveTab] = useState('departmentsInfo');

  const switchViewFunc = (name) => {
    setActiveTab(name);
    history.push(`/departments/${name}`);
  };

  return (
    <PageWrapper
      title="Отделы"
      needWrapperClassName={false}
    >
      <Flex
        className="-mt-5 mb-3"
      >
        <UniGeneralButtons
          viewMode={activeTab}
          switchViewFunc={switchViewFunc}
          tabs={tabs}
        />
      </Flex>

      <Switch>
        <Route
          path="/departments/departmentsInfo"
          render={() => (
            <DepartmentRoot />
          )}
        />
        <Route
          path="/departments/requests"
          render={() => (
            <EmployeeRequestsPage />
          )}
        />
        <Route
          path="/departments/logs"
          render={() => (
            <DepartmentLogsPage />
          )}
        />
      </Switch>
    </PageWrapper>
  );
}
