/* eslint-disable default-param-last */

import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  entityCreate, entityDelete, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import useLogsAPI from './useLogsAPI';
import antNotification from '../utils/antNotification';

export default function useVacationsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const getListOfVacations = (parent, options = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'vacation',
            parent,
            depth: 0,
            ...options,
          },
          constants: constants || [
            'GET_LIST_OF_VACATIONS_REQUEST',
            'GET_LIST_OF_VACATIONS_SUCCESS',
            'GET_LIST_OF_VACATIONS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список отпусков!');
      return null;
    }
  };

  const createVacation = async (parent, options, constants) => {
    try {
      const newVacation = await dispatch(
        entityCreate({
          data: {
            entity_type: 'vacation',
            parent,
            ...options,
          },
          constants: constants || [
            'CREATE_VACATION_REQUEST',
            'CREATE_VACATION_SUCCESS',
            'CREATE_VACATION_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newVacation, '[0].uuid', '')) {
        createLog({
          parent: get(newVacation, '[0].uuid', ''),
          type: 'CREATE',
          entityType: 'vacation',
          values: get(newVacation, '[0].params', ''),
        });
      }

      return newVacation;
    } catch (e) {
      antNotification.error('Не удалось создать отпуск!');
      return null;
    }
  };

  const updateVacation = async (entityUUID, data, constants) => {
    try {
      const updatedVacation = await dispatch(
        entityUpdate({
          data: {
            entity_type: 'vacation',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'UPDATE_VACATION_REQUEST',
            'UPDATE_VACATION_SUCCESS',
            'UPDATE_VACATION_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedVacation, '[0].uuid', '')) {
        createLog({
          parent: get(updatedVacation, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'vacation',
          values: get(data, 'params', {}),
        });
      }

      return updatedVacation;
    } catch (e) {
      antNotification.error('Не удалось изменить отпуск!');
      return null;
    }
  };

  const deleteVacation = (entityUUID) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_type: 'vacation',
            entity_uuid: entityUUID,
          },
          constants: [
            'DELETE_VACATION_REQUEST',
            'DELETE_VACATION_SUCCESS',
            'DELETE_VACATION_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить отпуск!');
      return null;
    }
  };

  return {
    createVacation,
    getListOfVacations,
    updateVacation,
    deleteVacation,
  };
}
