import { Modal, Switch } from 'antd';
import { pathOr, prop } from 'ramda';
import { isUndefined, capitalize } from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { actorIsRootOrAdmin } from '../../actors/utils';
import PermissionsContext from '../../context/PermissionsContext';
import { stopPropagation } from '../../utils/utils54origins';

function SwitchComponent({
  item,
  changeSwitchCallback,
  resetSwitchBool,
  cardName,
  globalDisabled = false,
}) {
  const { t } = useTranslation();
  const {
    checkNotSavedPermissions,
  } = useContext(PermissionsContext);

  const nameGroup = pathOr('', ['uinfo', 'group_name'], item);
  const isAdmin = nameGroup === 'ADMIN' || actorIsRootOrAdmin(item);
  const isRootOrAdmin = actorIsRootOrAdmin(item);
  const userInBan = prop('userInBan', item);
  const [state, setState] = useState(item);

  const showConfirmForAffixCreator = (item, newData, typePerm) => {
    Modal.confirm({
      // title: capitalize(
      //   t('wms.modals.headers.removing_process', 'removing process'),
      // ),
      width: '600px',
      content: capitalize(t('wms.labels.assigned_acd_label')),
      okText: capitalize(
        t('wms.buttons.removing_process.yes', 'yes, I am'),
      ),
      okType: 'danger',
      cancelText: capitalize(
        t('wms.buttons.removing_process.no', 'no, I\'m not'),
      ),
      open: false,
      centered: true,
      maskClosable: true,
      okButtonProps: { className: 'btnDanger' },
      cancelButtonProps: { className: 'btnPrimary-outline' },

      onOk() {
        const data = { ...newData, recursive: true };
        setState(newData);
        changeSwitchCallback(item, data, typePerm);
      },
      onCancel() { },
    });
  };
  // if(cardName === 'creatorCard' )  console.log('state',state, 'item',item);

  const viewSwitch = (label, typePerm) => {
    const checked = isRootOrAdmin ? true : userInBan ? false : prop(typePerm, state);
    const disabled = isRootOrAdmin || userInBan || prop('disabled', item) || globalDisabled;

    const onChecked = (e) => {
      if (checkNotSavedPermissions && checkNotSavedPermissions(cardName)) {
        return;
      }

      const newData = {
        ...state,
        [typePerm]: e,
      };

      if (typePerm === 'affix_creator_delete') {
        showConfirmForAffixCreator(item, newData, typePerm);
      } else {
        setState(newData);
        changeSwitchCallback(item, newData, typePerm);
      }
    };

    return (
      <div className="container-switch">
        <div>{label}</div>
        <Switch
          disabled={disabled}
          onClick={onChecked}
          checked={!isUndefined(checked) ? checked : false}
          key={`${label}-${typePerm}`}
          className="switch-primary"
          checkedChildren="ON"
          unCheckedChildren="OFF"
          size="small"
        />
      </div>
    );
  };

  useEffect(() => {
    if (!isAdmin) {
      setState(item);
    }
  }, [
    prop('create', item),
    prop('read', item),
    prop('update', item),
    prop('delete', item),
    prop('list', item),
    prop('set', item),
    prop('affix_creator_delete', item),
  ]);

  useEffect(() => {
    if (resetSwitchBool) {
      setState(item);
    }
  }, [resetSwitchBool]);

  return (
    <div
      className="flex items-center justify-center "
      onClick={stopPropagation}
    >
      {viewSwitch('C', 'create')}
      {viewSwitch('R', 'read')}
      {viewSwitch('U', 'update')}
      {viewSwitch('D', 'delete')}
      {viewSwitch('L', 'list')}
      {viewSwitch('S', 'set')}
      {cardName === 'creatorCard' && viewSwitch('ACD', 'affix_creator_delete')}
    </div>
  );
}

SwitchComponent.propTypes = {
  item: PropTypes.object,
  changeSwitchCallback: PropTypes.func,
  resetSwitchBool: PropTypes.bool,
  cardName: PropTypes.string,
  globalDisabled: PropTypes.bool,
};

export default SwitchComponent;
