import { useLocation, useHistory } from 'react-router-dom';

const useURLParams = () => {
  const location = useLocation();
  const history = useHistory();

  const getURLParams = () => {
    const search = location.search.substring(1);
    return search.split('&').reduce((acc, param) => {
      const [key, value] = param.split('=');
      if (key) {
        acc[decodeURIComponent(key)] = decodeURIComponent(value || '');
      }
      return acc;
    }, {});
  };

  const transformSearchParams = (params) => Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const transformSearchParamsWithPrev = (params) => transformSearchParams({
    ...getURLParams(),
    ...params,
  });

  const routeNavigateTo = (path, searchParams) => {
    const search = searchParams ? transformSearchParamsWithPrev(searchParams) : '';
    history.push({ pathname: path, search });
  };

  const routeNavigateAndClearParams = (path, searchParams) => {
    const search = searchParams ? transformSearchParams(searchParams) : '';
    history.push({ pathname: path, search });
  };

  const removeKeysFromParams = (keys) => {
    const qParams = getURLParams();
    keys.forEach((name) => delete qParams[name]);
    return qParams;
  };

  const clearSearchParams = (keys) => {
    const newParams = removeKeysFromParams(keys);
    history.replace({ ...location, search: transformSearchParams(newParams) });
  };

  const addAndRemoveSearchParams = (addKeys = {}, removeKeys = []) => {
    const newParams = {
      ...removeKeysFromParams(removeKeys),
      ...addKeys,
    };
    history.replace({ ...location, search: transformSearchParams(newParams) });
  };

  const setSearchParams = (searchParams) => {
    const newParams = transformSearchParamsWithPrev(searchParams);
    history.replace({ ...location, search: newParams });
  };

  const getStartDateParam = getURLParams().startDate || '';
  const getActorUUIDParam = getURLParams().actor || '';

  return {
    addAndRemoveSearchParams,
    clearSearchParams,
    currentLocation: location,
    currentLocationPathname: location.pathname,
    getActorUUIDParam,
    getStartDateParam,
    getURLParams,
    routeNavigateAndClearParams,
    routeNavigateTo,
    transformSearchParams,
    setSearchParams,
    history,
  };
};

export default useURLParams;
