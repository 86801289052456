import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import {
  Alert, Button, Flex, Input,
} from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';
import { concat, head } from 'lodash';
import { getProfileInfo } from '../../../../redux/profile/selectors';
import useAPI from '../../../../api/useAPI';
import { getVacationStorageUUID } from '../../../../redux/storages/selectors';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import antNotification from '../../../../utils/antNotification';
import useVacationsAPI from '../../../../api/useVacationsAPI';

const { TextArea } = Input;

function PreAgreedVacationCard({ vacation, onUpdateVacation }) {
  const [comment, setComment] = useState('');
  const [collapse, setCollapse] = useState(false);

  const wasChecked = vacation?.params?.wasChecked;

  return (
    <Alert
      key={vacation?.uuid}
      message={(
        <Flex
          vertical
        >
          <span
            className="text-lg"
          >
            Ваш отпуск в период с
            {' '}
            {dayjs(vacation?.params?.start_date).format('DD-MM-YYYY')}
            {' '}
            по
            {' '}
            {dayjs(vacation?.params?.end_date).format('DD-MM-YYYY')}
            {' '}
            предварительно согласован (согласовал:
            {' '}
            <b>{`${vacation?.params?.agreed?.uinfo?.first_name} ${vacation?.params?.agreed?.uinfo?.last_name}`.slice(0, 60)}</b>
            ).
          </span>
          {!wasChecked ? (
            <>
              <span>
                Уведомите об отпуске
                {' '}
                <b>вашего руководителя и коллег на проекте.</b>
              </span>
              <span
                className="text-lg"
              >
                После этого
                {' '}
                <b>необходимо</b>
                {' '}
                указать проекты и тех, кто был уведомлен на проекте (доступно только
                {' '}
                <b>1 раз</b>
                ).
              </span>
              {collapse && (
              <>
                <span
                  className="mt-1 text-orange-400"
                >
                  <span className="text-red-500 text-lg">*</span>
                  Комментарий:
                </span>
                <TextArea
                  className="w-1/2 border border-orange-200"
                  value={comment}
                  onChange={(e) => setComment(e?.target?.value)}
                  maxLength={5000}
                  rows={4}
                />
              </>
              )}
              <Flex className="mt-2" gap={8}>
                {collapse
                && (
                <Button
                  className={cn('w-[max-content] border !border-orange-400 bg-orange-400 !text-white hover:!text-orange-400 hover:!bg-white', {
                    '!bg-orange-200': !comment?.length,
                    '!border-orange-200': !comment?.length,
                  })}
                  disabled={!comment?.length}
                  onClick={() => onUpdateVacation(vacation?.uuid, comment)}
                >
                  Отправить
                </Button>
                )}
                <Button className="w-[max-content]" onClick={() => setCollapse(!collapse)}>
                  {collapse ? 'Скрыть' : 'Дополнить отпуск'}
                </Button>
              </Flex>
            </>
          ) : (
            <span
              className="text-lg"
            >
              Ожидайте утверждения отпуска.
            </span>
          )}
        </Flex>
    )}
      type={wasChecked ? 'info' : 'warning'}
      showIcon
      description={(<div />)}
    />
  );
}

PreAgreedVacationCard.propTypes = {
  vacation: object,
  onUpdateVacation: func,
};

export default function PreAgreedUserVacations({ actor }) {
  const myProfileData = useSelector(getProfileInfo);
  const vacationStorageUUID = useSelector(getVacationStorageUUID);

  const {
    updateVacation,
    getListOfVacations,
  } = useVacationsAPI();

  const [vacations, setVacations] = useState([]);

  const getListPreAgreedVacationsRequests = async () => {
    const configRequest = {
      entity_type: 'vacation',
      actor,
      params: {
        status: 'pre-agreed',
      },
    };

    const res = await getListOfVacations(
      vacationStorageUUID,
      configRequest,
      [
        'GET_PRE_AGREED_USER_VACATIONS_REQUEST',
        'GET_PRE_AGREED_USER_VACATIONS_SUCCESS',
        'GET_PRE_AGREED_USER_VACATIONS_FAILURE',
      ],
    );
    setVacations(res?.data ?? []);
  };

  const onUpdateVacation = async (uuid, comment) => {
    if (uuid) {
      const res = await updateVacation(uuid, {
        params: {
          additionalСomment: comment,
          wasChecked: true,
        },
      });
      const changedVacation = head(res);
      if (changedVacation) {
        antNotification.success('Вы успешно дополнили отпуск!');
        setVacations((prev) => {
          const newVacations = prev?.filter((el) => el?.uuid !== changedVacation?.uuid);
          return concat(newVacations, res);
        });
      }
    }
  };

  useEffect(() => {
    if (vacationStorageUUID && actor) {
      getListPreAgreedVacationsRequests();
    }
  }, [vacationStorageUUID, actor]);

  return (
    <Flex gap={16}>
      {vacations?.map((el) => (
        <PreAgreedVacationCard
          key={el.uuid}
          vacation={el}
          onUpdateVacation={onUpdateVacation}
        />
      ))}
    </Flex>
  );
}

PreAgreedUserVacations.propTypes = {
  customActor: string,
};
