import React, {
  useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Badge, Button, Col, Descriptions, Modal, Row, Space,
} from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { bool, string } from 'prop-types';
import Icon from '@mdi/react';
import { mdiCancel, mdiCheck } from '@mdi/js';
import useAPI from '../../../api/useAPI';
import { diffDays, formatDate } from '../../../utils/dateTimeUtils';
import antNotification from '../../../utils/antNotification';
import GlobalUpdateConstants from '../../../redux/globalUpdate/constants';
import { getUserRootInfo, getVacationStorageUUID } from '../../../redux/storages/selectors';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { isRootOrAdmin } from '../../../redux/profile/selectors';
import useVacationsAPI from '../../../api/useVacationsAPI';
import UserVacationConstants from '../../../redux/userVacation/constants';

export default function VacationInfo({
  vacationUUID,
  showApprove = true,
}) {
  const dispatch = useDispatch();

  const { getListOfVacations, updateVacation } = useVacationsAPI();

  const [vacation, setVacation] = useState(null);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);
  const partitionUUID = useSelector(getDeliveryPartition);
  const vacationStorageUUID = useSelector(getVacationStorageUUID);

  const userInfo = useSelector(getUserRootInfo);

  const onClose = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    params.delete('v');

    const newUrl = `${url.pathname}?${params.toString()}`;

    window.history.replaceState({}, '', newUrl);
    setVacation(null);
  };

  const saveAgreedCallback = () => {
    getListOfVacations(
      isUserRootOrAdmin ? partitionUUID : vacationStorageUUID,
      {
        params: { status: 'pending' },
        ...{
          limit: 10,
          offset: 0,
          order: 'desc',
          order_by: 'created',
        },
      },
      [
        UserVacationConstants.GET_USER_VACATIONS_REQUEST,
        UserVacationConstants.GET_USER_VACATIONS_SUCCESS,
        UserVacationConstants.GET_USER_VACATIONS_FAILURE,
      ],
    );
    dispatch({ type: GlobalUpdateConstants.SET_GET_DATA, payload: 'getAgreedList' });
  };

  const approveVacation = async () => {
    try {
      await updateVacation(vacation.uuid, {
        params: {
          status: 'approved',
          reviewed: {
            uinfo: userInfo.uinfo,
            actor: userInfo.actor,
          },
        },
      });
      saveAgreedCallback();
      antNotification.success('Заявка на отпуск успешно одобрена.');
    } catch {
      antNotification.error('Что-то пошло не так.');
    }

    onClose();
  };

  const rejectVacation = async () => {
    try {
      await updateVacation(vacation.uuid, {
        params: {
          status: 'rejected',
          reviewed: {
            uinfo: userInfo.uinfo,
            actor: userInfo.actor,
          },
        },
      });
      saveAgreedCallback();
      antNotification.success('Заявка на отпуск успешно отклонена.');
    } catch {
      antNotification.error('Что-то пошло не так.');
    }

    onClose();
  };

  const initFunc = async () => {
    try {
      let parentUUID = vacationStorageUUID;
      if (isUserRootOrAdmin) {
        parentUUID = partitionUUID;
      }
      const res = await getListOfVacations(
        parentUUID,
        {
          entity_uuid: vacationUUID,
        },
        [
          'GET_VACATION_REQUEST',
          'GET_VACATION_SUCCESS',
          'GET_VACATION_FAILURE',
        ],
      );
      if (!res?.length) {
        antNotification.error('Такого отпуска нет или у вас нет доступа к нему!');
      } else {
        setVacation(res?.[0]);
      }
    } catch (err) {
      antNotification.error('Такого отпуска нет или у вас нет доступа к нему!');
    }
  };

  useEffect(() => {
    if (vacationUUID && vacationStorageUUID && partitionUUID) {
      initFunc();
    }
  }, [vacationUUID, vacationStorageUUID, partitionUUID, isUserRootOrAdmin]);

  return vacation ? (
    <Modal
      title={`Заявка на отпуск для ${vacation?.uinfo?.first_name} ${vacation?.uinfo?.last_name}`}
      open={!isEmpty(vacation)}
      onCancel={() => onClose()}
      destroyOnClose
      footer={showApprove && isUserRootOrAdmin && vacation?.params?.status === 'pending' ? (
        <Space>
          <Button
            onClick={() => rejectVacation()}
            danger
            ghost
            className="flex items-center"
          >
            <Icon path={mdiCancel} className="mr-2" size={0.8} />
            {capitalize('отклонить')}
          </Button>
          <Button
            onClick={() => approveVacation()}
            type="primary"
            ghost
            className="flex items-center"
          >
            <Icon path={mdiCheck} className="mr-2" size={0.8} />
            {capitalize('утвердить')}
          </Button>
        </Space>
      ) : null}
    >
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        <Col span={24}>
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Имя:">
              {vacation?.uinfo?.first_name}
            </Descriptions.Item>
            <Descriptions.Item label="Фамилия:">
              {vacation?.uinfo?.last_name}
            </Descriptions.Item>
            {!showApprove || vacation?.params?.status !== 'pending' ? (
              <Descriptions.Item label="Статус:">
                {capitalize(vacation?.params?.status)}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Дата начала:">
              {formatDate(vacation?.params?.start_date)}
            </Descriptions.Item>
            <Descriptions.Item
              label="Дата окончания:"
            >
              {formatDate(vacation?.params?.end_date)}
            </Descriptions.Item>
            <Descriptions.Item
              label="Количество дней:"
            >
              <Badge
                count={diffDays(
                  vacation?.params?.start_date,
                  vacation?.params?.end_date,
                )}
              />
            </Descriptions.Item>
            {vacation?.params?.comment.trim() ? (
              <Descriptions.Item
                label="Комментарий:"
              >
                {vacation?.params?.comment}
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  ) : null;
}

VacationInfo.propTypes = {
  vacationUUID: string,
  showApprove: bool,
};
