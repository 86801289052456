import React, { useEffect, useState } from 'react';

import { trim } from 'lodash';
import { bool, func, string } from 'prop-types';

import { Flex, Input, Tooltip } from 'antd';

import EditButton from './EditButton';

export default function EntityTitleForCard({
  title = '',
  globalEditMode = false,
  isGlobalEdit = false,
  hideEditButton = false,
  className = '',
  onChangeValue,
  onEditCallback,
}) {
  const [isEdit, setIsEdit] = useState(false);

  const [titleValue, setTitleValue] = useState(title);

  const onChangeTitle = (e) => {
    setTitleValue(e.target.value);
    if (onChangeValue) {
      onChangeValue?.(e.target.value);
    }
  };

  const toggleBtn = (e, closeEdit) => {
    if (closeEdit || !isEdit) {
      setTitleValue(title);
    }
    if (isEdit && trim(titleValue) !== trim(title) && !closeEdit) {
      onEditCallback({ title: titleValue });
    }
    setIsEdit((prev) => !prev);
  };

  useEffect(() => {
    setTitleValue(title);
  }, [isGlobalEdit, title]);

  return (
    <Flex
      align="center"
      justify="start"
      className={`w-full overflow-hidden pr-2 ${className}`}
    >
      <span
        className="mr-2 text-base"
      >
        Название:
      </span>
      {!hideEditButton && (
      <EditButton
        id="editTitleButton"
        isEditFlag={isEdit}
        onClick={toggleBtn}
      />
      )}
      {(isEdit && !hideEditButton) || isGlobalEdit ? (
        <Input
          id="department_input_title"
          className="text-base mt-1"
          style={{
            width: 350,
          }}
          placeholder="Enter title"
          maxLength={128}
          onChange={onChangeTitle}
          value={titleValue}
        />
      ) : (
        <Tooltip
          title={title}
          style={{
            wordBreak: 'break-word',
          }}
        >
          <span
            className="text-blue-500 overflow-hidden whitespace-nowrap text-ellipsis"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {title}
          </span>
        </Tooltip>
      )}
    </Flex>
  );
}

EntityTitleForCard.propTypes = {
  title: string,
  className: string,
  onEditCallback: func,
  hideEditButton: bool,
  globalEditMode: bool,
  isGlobalEdit: bool,
};
