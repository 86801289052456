/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { head } from 'lodash';

const initialState = {
  fetching: false,
  artifacts: [],
  fetchingSingle: false,
  artifact: {},
};

const artifactsSlice = createSlice({
  name: 'artifactsSlice',
  initialState,
  reducers: {
    RESET_SINGLE_ARTIFACT(state) {
      state.artifact = {};
    },
    GET_ARTIFACTS_REQUEST(state) {
      state.fetching = true;
    },
    GET_ARTIFACTS_SUCCESS(state, action) {
      state.fetching = false;
      state.total = action.total;
      state.artifacts = action.payload;
    },
    GET_ARTIFACTS_FAILURE(state) {
      state.fetching = false;
    },
    CREATE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    CREATE_ARTIFACT_SUCCESS(state, action) {
      state.fetching = false;
    },
    CREATE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    GET_SINGLE_ARTIFACT_REQUEST(state) {
      state.fetchingSingle = true;
    },
    GET_SINGLE_ARTIFACT_SUCCESS(state, action) {
      state.fetchingSingle = false;
      state.artifact = head(action.payload);
    },
    GET_SINGLE_ARTIFACT_FAILURE(state) {
      state.fetchingSingle = false;
    },
    DELETE_SINGLE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    DELETE_SINGLE_ARTIFACT_SUCCESS(state) {
      state.fetching = false;
      state.artifact = {};
    },
    DELETE_SINGLE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    UPDATE_SINGLE_ARTIFACT_REQUEST(state) {
      state.fetching = true;
    },
    UPDATE_SINGLE_ARTIFACT_SUCCESS(state, action) {
      state.fetching = false;
      state.artifact = head(action.payload);
    },
    UPDATE_SINGLE_ARTIFACT_FAILURE(state) {
      state.fetching = false;
    },
    SET_SINGLE_ARTIFACT(state, action) {
      state.artifact = action.payload;
      state.artifacts = state.artifacts.map((el) => {
        if (el?.uuid === action.payload?.uuid) {
          return action.payload;
        }
        return el;
      });
    },
  },
});

export const {
  RESET_SINGLE_ARTIFACT,
  CREATE_ARTIFACT_REQUEST,
  CREATE_ARTIFACT_SUCCESS,
  CREATE_ARTIFACT_FAILURE,
  GET_SINGLE_ARTIFACT_REQUEST,
  GET_SINGLE_ARTIFACT_SUCCESS,
  GET_SINGLE_ARTIFACT_FAILURE,
  DELETE_SINGLE_ARTIFACT_REQUEST,
  DELETE_SINGLE_ARTIFACT_SUCCESS,
  DELETE_SINGLE_ARTIFACT_FAILURE,
  UPDATE_SINGLE_ARTIFACT_REQUEST,
  UPDATE_SINGLE_ARTIFACT_SUCCESS,
  UPDATE_SINGLE_ARTIFACT_FAILURE,
  GET_ARTIFACTS_REQUEST,
  GET_ARTIFACTS_SUCCESS,
  GET_ARTIFACTS_FAILURE,
  SET_SINGLE_ARTIFACT,
} = artifactsSlice.actions;

export default artifactsSlice.reducer;
