import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import config from './config/reducer';
import humanResources from './humanResource/reducer';
import locales from './locale/reducer';
import profileInfo from './profile/reducer';
import publicUI from './publicInterfaces/reducers';
import userTimelineStatuses from './userTimelineStatus/reducer';
import userVacations from './userVacation/reducer';
import userHolidays from './userHolidays/reducer';
import vacations from './vacations/reducer';
import holidays from './holidays/reducer';
import monthlyReport from './monthlyReport/reducer';
import rootEntities from './rootEntities/reducer';
import globalUpdate from './globalUpdate/reducer';
import userDayOff from './userDayOff/reducer';
import actors from './actors/reducer';
import publicUserEntity from './publicUserEntity/reducer';
import reports from './reports/reducer';
import storagesSlice from './storages/storagesSlice';
import masqueradingSlice from './masquerading/masqueradingSlice';
import reports54Actors from './reports54Actors/reducer';
import groups from './groups/reducer';
import departmentsSlice from './departments/slices/departmentsSlice';
import artifactsSlice from './departments/slices/artifactsSlice';
import permissionsSlice from './permissions/permissionsSlice';
import artifactsStorageSlice from './departments/slices/artifactsStorageSlice';
import resetSlice from './reset/resetSlice';
import defaultDepartmentSlice from './departments/slices/defaultDepartmentSlice';
import employeeRequest from './employeeRequest/employeeRequestSlice';

const createRootReducer = (history) => combineReducers({
  config,
  locales,
  profile: profileInfo,
  rootEntities,
  globalUpdate,
  userDayOff,
  storages: storagesSlice,
  actors,
  groups,
  Masquerading: masqueradingSlice,
  publicUserEntity,
  monthlyReport,
  reports,
  humanResources,
  userTimelineStatuses,
  userVacations,
  userHolidays,
  reports54Actors,
  vacations,
  holidays,
  Departments: combineReducers({
    artifacts: artifactsSlice,
    artifactsStorage: artifactsStorageSlice,
    listOfDepartments: departmentsSlice,
    defaultDepartment: defaultDepartmentSlice,
  }),
  Permissions: combineReducers({
    PSP: permissionsSlice,
  }),
  reset: resetSlice,
  public_interfaces: publicUI,
  router: connectRouter(history),
  employeeRequest,
});

export default createRootReducer;
