import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArtifactsList from '../../admin/departments/artifacts/ArtifactsList';
import PageWrapper from '../../../components/PageWrapper';
import DepartmentsList from '../../admin/departments/DepartmentsList';
import { getUserRootActorUUID } from '../../../redux/storages/selectors';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT,
} from '../../../redux/departments/slices/departmentsSlice';
import DepartmentReports from '../../admin/departments/DepartmentReports';
import ArtifactCard from '../../admin/departments/artifacts/ArtifactCard';
import {
  GET_SINGLE_ARTIFACT_FAILURE, GET_SINGLE_ARTIFACT_REQUEST, GET_SINGLE_ARTIFACT_SUCCESS, RESET_SINGLE_ARTIFACT,
} from '../../../redux/departments/slices/artifactsSlice';
import { getArtifact, getArtifactUUID } from '../../../redux/departments/selectors/artifactsSelector';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useArtifactsAPI from '../../../api/useArtifactsAPI';

export default function DepartmentContractorsPage() {
  const dispatch = useDispatch();

  const userActorUUID = useSelector(getUserRootActorUUID);

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const { readListOfDepartments } = useDepartmentsAPI();
  const { readListOfArtifacts } = useArtifactsAPI();

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Подрядчики
        </Flex>
      )}
    >
      <Flex>
        <Flex
          vertical
          className="w-full"
        >
          <DepartmentsList
            hideAddButton
            hideKPIButton
            hideExpand
            defaultConfig={{
              params: {
                usersSearch: [userActorUUID],
              },
            }}
            name="department_list"
            currentDepartment={department}
          />
          {activeDepartment && (
          <ArtifactsList
            defaultConfig={{
              entity_type: 'user',
            }}
            hideTypeFilter
            name="artifact_list"
            hideAddEmployee
            hideAddArtifact
            parent={activeDepartment}
            title="Артефакты"
            showUserCreateButton
            department={department}
            departmentUUID={activeDepartment}
          />
          )}
        </Flex>

        {activeArtifact && (
        <ArtifactCard
          className="ml-6"
          artifact={artifact}
          artifactUUID={artifactUUID}
          onBackArtCallback={() => {
            clearSearchParams(['artifact']);
          }}
          hideDeleteButton
          hideCopyURLButton
        />
        )}
      </Flex>
    </PageWrapper>
  );
}
