import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Button, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

function CommonInfo({
  creator,
  createdDate,
  placement,
  style = {},
  iconSize = 1.2,
  className = '',
}) {
  const { t } = useTranslation();

  const createdByTitle = 'Создал';
  const createdTitle = 'Создан';

  const creatorInfo = creator || '-';
  const createDateInfo = createdDate ? `${dayjs(createdDate).format('DD-MM-YYYY')} ${dayjs(createdDate).format('HH:mm')}` : '-';

  const tooltipTitle = (
    <div>
      {creator && (
        <>
          {createdByTitle}
          :&nbsp;
          {creatorInfo}
          {' '}
          <br />
        </>
      )}
      {createdDate && ((
        <>
          {' '}
          {createdTitle}
          :&nbsp;
          {createDateInfo}
          {' '}
          <br />
        </>
      ))}
    </div>
  );

  return (
    <Tooltip
      title={tooltipTitle}
      placement={placement || 'topLeft'}
      color="#3e8adb"
    >
      <Button
        id="viewLinkInfoButton"
        shape="circle"
        className={`p-0 flex border-sky-500 items-center justify-center text-sky-500 hover:!text-sky-500 ${className}`}
        style={style}
      >
        <Icon
          path={mdiInformationOutline}
          size={iconSize}
        />
      </Button>
    </Tooltip>
  );
}

CommonInfo.propTypes = {
  className: PropTypes.string,
  creator: PropTypes.string,
  createdDate: PropTypes.string,
  firstActivity: PropTypes.string,
  lastActivity: PropTypes.string,
  lastModifiedBy: PropTypes.string,
  placement: PropTypes.string,
  executedBy: string,
  executedOn: string,
  version: string,
};

export default CommonInfo;
