/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { head } from 'lodash';

const initialState = {
  fetching: false,
  departments: [],
  total: 0,
  fetchingSingle: false,
  department: {},
};

const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    RESET_SINGLE_DEPARTMENT(state) {
      state.department = {};
    },
    CREATE_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    CREATE_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.departments.push(head(action.payload));
    },
    CREATE_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    GET_DEPARTMENTS_REQUEST(state) {
      state.fetching = true;
    },
    GET_DEPARTMENTS_SUCCESS(state, action) {
      state.fetching = false;
      state.total = action.total;
      state.departments = action.payload;
    },
    GET_DEPARTMENTS_FAILURE(state) {
      state.fetching = false;
    },
    GE_SINGLET_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    GET_SINGLE_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.department = head(action.payload);
    },
    GET_SINGLE_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    UPDATE_SINGLE_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
    },
    UPDATE_SINGLE_DEPARTMENT_SUCCESS(state, action) {
      state.fetching = false;
      state.department = action.payload;
    },
    UPDATE_SINGLE_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    DELETE_SINGLE_DEPARTMENT_REQUEST(state) {
      state.fetching = true;
      state.department = {};
    },
    DELETE_SINGLE_DEPARTMENT_SUCCESS(state) {
      state.fetching = false;
    },
    DELETE_SINGLE_DEPARTMENT_FAILURE(state) {
      state.fetching = false;
    },
    SET_SINGLE_DEPARTMENT(state, action) {
      state.department = action.payload;
      state.departments = state.departments.map((el) => {
        if (el?.uuid === action.payload?.uuid) {
          return action.payload;
        }
        return el;
      });
    },
  },
});

export const {
  RESET_SINGLE_DEPARTMENT,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENTS_FAILURE,
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  GET_SINGLE_DEPARTMENT_FAILURE,
  UPDATE_SINGLE_DEPARTMENT_REQUEST,
  UPDATE_SINGLE_DEPARTMENT_SUCCESS,
  UPDATE_SINGLE_DEPARTMENT_FAILURE,
  DELETE_SINGLE_DEPARTMENT_REQUEST,
  DELETE_SINGLE_DEPARTMENT_SUCCESS,
  DELETE_SINGLE_DEPARTMENT_FAILURE,
  SET_SINGLE_DEPARTMENT,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
