import { get } from 'lodash';

export const getUserPermissions = (state) => get(state, 'Permissions.PSP.permissions', {});

export const getFetchingUserPermissions = (state) => get(state, 'Permissions.PSP.fetching', false);

// PSP - PermissionsStartPage component

export const getFetchingForPSP = (state) => get(state, 'Permissions.PSP.fetchingPSP', {});

export const getPermsForPSP = (state) => get(state, 'Permissions.PSP.permsPSP', {});

export const getTotalForPSP = (state) => get(state, 'Permissions.PSP.totalPSP', {});

export const getPSPAdminGroup = (state) => get(state, 'Permissions.PSP.permsPSP.defaultGroups', [])
    .find((item) => get(item, 'uinfo.group_name', '') === 'ADMIN');

export const getPSPAdminGroupActorUUID = (state) => get(getPSPAdminGroup(state), 'actor_uuid', '');

export const getPSPBanGroup = (state) => get(state, 'Permissions.PSP.permsPSP.defaultGroups', [])
    .find((item) => get(item, 'uinfo.group_name', '') === 'BAN');

export const getPSPBanGroupActorUUID = (state) => get(getPSPBanGroup(state), 'actor_uuid', '');

export const getSelectedActorPermsForPSP = (state) => get(state, 'Permissions.PSP.permsPSP.selectedActor', {});
