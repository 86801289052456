/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { head } from 'lodash';

const initialState = {
  fetching: false,
  departmentEmployeeRequestsList: [],
  allDepartmentsEmployeeRequestsList: [],
  departmentsList: [],
  totalRequests: 0,
};

const employeeRequestSlice = createSlice({
  name: 'employeeRequestSlice',
  initialState,
  reducers: {
    CREATE_EMPLOYEE_REQUEST_START(state) {
      state.fetching = true;
    },
    CREATE_EMPLOYEE_REQUEST_SUCCESS(state, action) {
      state.fetching = false;
      state.departmentEmployeeRequestsList.unshift(head(action.payload));
    },
    CREATE_EMPLOYEE_REQUEST_FAILURE(state) {
      state.fetching = false;
    },
    GET_DEPARTMENT_EMPLOYEE_REQUESTS_START(state) {
      state.fetching = true;
    },
    GET_DEPARTMENT_EMPLOYEE_REQUESTS_SUCCESS(state, action) {
      state.fetching = false;
      state.departmentEmployeeRequestsList = action.payload;
    },
    GET_DEPARTMENT_EMPLOYEE_REQUESTS_FAILURE(state) {
      state.fetching = false;
    },
    GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_START(state) {
      state.fetching = true;
    },
    GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_SUCCESS(state, action) {
      state.fetching = false;
      state.totalRequests = action.total;
      state.allDepartmentsEmployeeRequestsList = action.payload;
    },
    GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_FAILURE(state) {
      state.fetching = false;
    },
    GET_DEPARTMENTS_LIST_START(state) {
      state.fetching = true;
    },
    GET_DEPARTMENTS_LIST_SUCCESS(state, action) {
      state.fetching = false;
      state.departmentsList = action.payload;
    },
    GET_DEPARTMENTS_LIST_FAILURE(state) {
      state.fetching = false;
    },
    EDIT_EMPLOYEE_REQUEST_START(state) {
      state.fetching = true;
    },
    EDIT_EMPLOYEE_REQUEST_SUCCESS(state, action) {
      state.fetching = false;
      const updatedRequest = action.payload[0];
      state.allDepartmentsEmployeeRequestsList = state.allDepartmentsEmployeeRequestsList.map((request) => (
        request.uuid === updatedRequest.uuid ? updatedRequest : request
      ));
    },
    EDIT_EMPLOYEE_REQUEST_FAILURE(state) {
      state.fetching = false;
    },
  },
});

export const {
  CREATE_EMPLOYEE_REQUEST_START,
  CREATE_EMPLOYEE_REQUEST_SUCCESS,
  CREATE_EMPLOYEE_REQUEST_FAILURE,
  GET_DEPARTMENT_EMPLOYEE_REQUESTS_START,
  GET_DEPARTMENT_EMPLOYEE_REQUESTS_SUCCESS,
  GET_DEPARTMENT_EMPLOYEE_REQUESTS_FAILURE,
  GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_START,
  GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_SUCCESS,
  GET_ALL_DEPARTMENTS_EMPLOYEE_REQUESTS_FAILURE,
  GET_DEPARTMENTS_LIST_START,
  GET_DEPARTMENTS_LIST_SUCCESS,
  GET_DEPARTMENTS_LIST_FAILURE,
  EDIT_EMPLOYEE_REQUEST_START,
  EDIT_EMPLOYEE_REQUEST_SUCCESS,
  EDIT_EMPLOYEE_REQUEST_FAILURE,
} = employeeRequestSlice.actions;

export default employeeRequestSlice.reducer;
