import dayjs from 'dayjs';

export const sumTime = (totalTime, newTime) => {
  if (!newTime) {
    return totalTime;
  }
  totalTime.hours += parseInt(newTime);
  totalTime.minutes += (parseInt(newTime.slice(newTime.length - 3))
    || parseInt(newTime.slice(newTime.length - 2)));

  if (totalTime.minutes >= 60) {
    totalTime.hours++;
    totalTime.minutes -= 60;
  }
  return totalTime;
};

export const getHours = (arrOfDay, field = 'hoursWorked') => {
  const hoursObj = arrOfDay
    .reduce((acc, el) => sumTime(acc, el?.[field]), { hours: 0, minutes: 0 });
  if (hoursObj.minutes === 0) {
    return hoursObj.hours;
  }

  return Math.ceil((hoursObj.hours + hoursObj.minutes / 60) * 10) / 10;
};

export function enumerateDaysBetweenDates(start, end) {
  let currentStart = start;
  const date = [];
  while (dayjs(currentStart) <= dayjs(end)) {
    date.push(dayjs(currentStart).format('YYYY-MM-DD'));
    currentStart = dayjs(currentStart).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
}

export const getArrByType = (arr, dates) => {
  const list = new Map([]);
  const workTypesOfDay = ['office_work', 'remote_work', 'work_on_day_off'];

  arr.forEach((el) => {
    const rangeDates = el?.params?.rangeDates ?? el?.rangeDates;
    const type = el?.entity_type ?? el?.type;
    const isWorkDay = workTypesOfDay.includes(type);

    if (rangeDates) {
      rangeDates.forEach((i) => {
        if (dates.includes(i)) {
          const candidate = list.get(i);
          if (candidate) {
            if (dayjs(candidate.created).isBefore(dayjs(el?.created))) {
              candidate.created = el?.created;
              candidate.type = type;
              candidate.hoursWorked = null;
              candidate.startWork = null;
              candidate.finishWork = null;
              candidate.lunchBreak = null;
              candidate.comment = el?.comment || '';
              if (isWorkDay) {
                candidate.hoursWorked = el?.hoursWorked || '08h00m';
                candidate.startWork = el?.startWork || null;
                candidate.finishWork = el?.finishWork || null;
                candidate.lunchBreak = el?.lunchBreak || null;
              }
            }
          } else {
            const newDay = {
              date: i,
              type,
              created: el?.created,
              hoursWorked: null,
              startWork: null,
              finishWork: null,
              lunchBreak: null,
              comment: el?.comment || '',
            };
            if (isWorkDay) {
              newDay.hoursWorked = el?.hoursWorked || '08h00m';
              newDay.startWork = el?.startWork || null;
              newDay.finishWork = el?.finishWork || null;
              newDay.lunchBreak = el?.lunchBreak || null;
            }
            list.set(i, newDay);
          }
        }
      });
    }
  });
  dates.forEach((day) => {
    if (!list.has(day)) {
      list.set(day, {
        date: day,
        type: 'report_not_sent',
        created: null,
      });
    }
  });
  return list;
};

export const isWeekday = (date) => {
  const dayOfWeek = dayjs(date).day();
  return dayOfWeek >= 1 && dayOfWeek <= 5;
};

export const computeStatisticsForReport = (records, startMonth, endMonth) => {
  const data = {
    office_work: 0,
    remote_work: 0,
    work_on_day_off: 0,
    totalWorks: 0,
    sickday: 0,
    holidays: 0,
    vacation: 0,
    projectDayOffs: 0,
    dayoff: 0,
    localHoliday: 0,
    totalDayOff: 0,
    bench_1_month: 0,
    bench_over_1_month: 0,
    bench_over_3_month: 0,
    totalBenches: 0,
    internalWork: 0,
    clientWork: 0,
  };

  const allDaysWithType = [...getArrByType(
    records,
    enumerateDaysBetweenDates(startMonth, endMonth),
  )]
    .map(([key, value]) => ({ ...value }));

  data.office_work = getHours(allDaysWithType.filter((item) => item.type === 'office_work'));
  data.remote_work = getHours(allDaysWithType.filter((item) => item.type === 'remote_work'));
  data.work_on_day_off = getHours(allDaysWithType.filter((item) => item.type === 'work_on_day_off'));

  data.fullTotalWorks = data.office_work + data.remote_work + data.work_on_day_off;
  data.totalWorks = Math.ceil(data.fullTotalWorks);

  data.bench_1_month = (allDaysWithType.filter((item) => item.type === 'bench_1_month').length || 0) * 8;
  data.bench_over_1_month = (allDaysWithType.filter((item) => item.type === 'bench_over_1_month').length || 0) * 8;
  data.bench_over_3_month = (allDaysWithType.filter((item) => item.type === 'bench_over_3_month').length || 0) * 8;

  data.totalBenches = data.bench_1_month + data.bench_over_1_month
  + data.bench_over_3_month;

  data.sickday = (allDaysWithType.filter((item) => item.type === 'sickday').length || 0) * 8;
  data.vacation = (allDaysWithType.filter((item) => item.type === 'vacation' && isWeekday(item?.date)).length || 0) * 8;
  data.dayoff = (allDaysWithType.filter((item) => item.type === 'dayoff').length || 0) * 8;

  data.totalDayOff = data.sickday + data.vacation
    + data.dayoff + data.bench_1_month + data.bench_over_1_month
    + data.bench_over_3_month;

  return data;
};
