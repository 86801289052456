import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDeliveryPartition } from '../../config/selectors';
import {
  getArtifactsStorage,
} from '../selectors/artifactsStorageSelector';
import { getDefaultDepartmentUUID, getIsFetchingDefaultDepartment } from '../selectors/defaultDepartmentSelector';
import { isRootOrAdmin } from '../../profile/selectors';
import { getListOfEntities, getOrCreateSingle } from '../../../api/actions/entity';
import { getMD5HashFromString } from '../../../utils';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import {
  GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE,
  GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST,
  GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS,
  GET_LIST_OF_DEFAULT_DEPARTMENT_REQUEST,
  GET_LIST_OF_DEFAULT_DEPARTMENT_SUCCESS,
  GET_LIST_OF_DEFAULT_DEPARTMENT_FAILURE,
} from '../slices/defaultDepartmentSlice';

function useDefaultDepartment(isUpdateRequired = true) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const isAdminOrRoot = useSelector(isRootOrAdmin);

  const defaultDepartment = useSelector(getArtifactsStorage);
  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);
  const isFetching = useSelector(getIsFetchingDefaultDepartment);

  async function onReloadFunc() {
    if (!partitionUuid) {
      return;
    }
    if (isAdminOrRoot) {
      dispatch(
        getOrCreateSingle({
          data: {
            entity_type: 'defaultDepartment',
            parent: partitionUuid,
            key: getMD5HashFromString(`defaultDepartment${partitionUuid}`),
          },
          constants: [
            GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST,
            GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS,
            GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
          doNotCheckError: true,
        }),
      );
    } else {
      dispatch(
        getListOfEntities({
          data: {
            entity_type: 'defaultDepartment',
            parent: partitionUuid,
            key: getMD5HashFromString(`defaultDepartment${partitionUuid}`),
          },
          constants: [
            GET_LIST_OF_DEFAULT_DEPARTMENT_REQUEST,
            GET_LIST_OF_DEFAULT_DEPARTMENT_SUCCESS,
            GET_LIST_OF_DEFAULT_DEPARTMENT_FAILURE,
          ],
          partition: SERVICE_PARTITION_NAME,
          doNotCheckError: true,
        }),
      );
    }
  }

  useEffect(() => {
    if (partitionUuid && isUpdateRequired) {
      onReloadFunc();
    }
  }, [partitionUuid, isUpdateRequired, isAdminOrRoot]);

  return {
    uuid: defaultDepartmentUUID,
    entity: defaultDepartment,
    isFetching,
    onReloadFunc,
  };
}

export default useDefaultDepartment;
