import axios from 'axios';
import { isEmpty } from 'ramda';

import { anyServiceURL, SERVICE_PARTITION_NAME } from './app_config'
import store from '../redux/store';
import { parseErrorFromRequest54origins } from '../utils/utils54origins';
import { getToken, handleError } from './utils';

import {
  getEntityDomain,
  getHeaderPartition,
} from '../redux/config/selectors';

class AxiosAPI {
  allSources = new Map([]);

  getSourceToken = (requestType) => {
    const source = this.allSources.has(requestType)
      ? this.allSources.get(requestType)
      : axios.CancelToken.source();

    this.allSources.set(requestType, axios.CancelToken.source());

    return source.token;
  };

  removeSource = (requestType) => {
    this.allSources.delete(requestType);
  };

  request = (endpoint, types = [], data, options = {}, method) => {
    const {
      doNotCheckError,
      header = {},
      onCancel,
      onFailure,
      onSuccess,
      partition = SERVICE_PARTITION_NAME,
      signal,
      tokenName,
      service,
      url,
    } = options;
    const [REQUEST, SUCCESS, FAILURE, CANCELED] = types;
    const headers = !isEmpty(header)
      ? header
      : {
        'Content-Type':
						data instanceof FormData
						  ? 'multipart/form-data'
						  : 'application/json',
        'Session-Token': getToken(tokenName || 'Entity'),
        'Partition-Uuid': getHeaderPartition(
          store.getState(),
          partition,
        ),
        'Http-Accept-Language': localStorage.getItem('locale'),
			  };

    const getUrl = () => {
      if (!service || service === 'entity') {
        return (
          getEntityDomain(store.getState())
					|| anyServiceURL(service || 'entity')
        );
      }

      return anyServiceURL(service);
    };

    const config = {
      url: `${url || getUrl()}/${endpoint}`,
      method,
      data,
      headers,
      signal,
    };

    return (dispatch, getState) => {
      if (REQUEST) {
        dispatch({ type: REQUEST.type || REQUEST });
      }

      const state = getState();

      axios
        .request(config)
        .then((response) => {
          const { data: resData, status } = response || {};
          if (SUCCESS) {
            dispatch({
              type: SUCCESS.type || SUCCESS,
              payload: SUCCESS.payload
                ? SUCCESS.payload(resData, state, status)
                : resData,
              total: config.data?.total || null,
            });
          }

          if (onSuccess) {
            onSuccess(resData, state, status);
          }
        })
        .catch((error) => {
          const checkFailure = () => {
            if (onFailure) {
              onFailure(parseErrorFromRequest54origins(error));
            }

            if (FAILURE) {
              dispatch({
                type: FAILURE.type || FAILURE,
                payload: FAILURE.payload
                  ? FAILURE.payload(
                    parseErrorFromRequest54origins(
                      error,
                    ),
                    state,
									  )
                  : parseErrorFromRequest54origins(error),
              });
            }
          };

          if (!doNotCheckError) {
            handleError(error);
          }

          if (axios.isCancel) {
            if (onCancel) {
              onCancel(parseErrorFromRequest54origins(error));
            }

            if (CANCELED) {
              dispatch({
                type: CANCELED.type || CANCELED,
              });
            }

            if (!onCancel && !CANCELED) {
              checkFailure();
            }
          } else {
            checkFailure();
          }
        });
    };
  };

  get = (endpoint, types, options) => this.request(endpoint, types, null, options, 'get');

  post = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'post');

  put = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'put');

  abort = (abortMessage = 'abort', abortType) => {
    const isString = typeof abortType === 'string';
    // console.log('allSources', this.allSources);

    // console.log('abortType abortType', abortType);
    const onAbort = (requestType) => {
      const source = this.allSources.has(requestType)
        ? this.allSources.get(requestType)
        : '';

      if (source) {
        source.cancel(`abort-${requestType}`);
      }
    };

    if (abortType && isString) {
      if (this.allSources.has(abortType)) {
        onAbort(abortType);
      }
    } else if (abortType && !isString) {
      abortType.forEach((key) => onAbort(key));
    } else {
      Object.keys(this.allSources).forEach((key) => {
        onAbort(key);
      });
    }
  };
}

const axiosRequest = new AxiosAPI();

export default axiosRequest;
