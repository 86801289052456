import { useEffect, useState } from 'react';
import { capitalize, omit } from 'lodash';
import { useSelector } from 'react-redux';
import useAPI from '../../api/useAPI';
import {
  actorIsRootOrAdmin, firstName, getUuid, lastName,
} from '../utils';
import { getDeliveryPartition } from '../../redux/config/selectors';

let needClearUserList = false;

export default function useActorsHRM54({
  defaultConfig = {},
}) {
  const {
    getDeliveryUsers,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    limit = 100,
    depth = 0,
    parent = partitionUUID,
  } = defaultConfig;

  const [actors, setActors] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);
  const [config, setConfig] = useState({
    actor_type: ['user', 'classic_user'],
    limit,
    offset: 0,
    depth,
    parent,
  });

  const onChangeSearch = (value) => {
    needClearUserList = true;
    if (value === '') {
      setConfig((prev) => ({
        ...omit(prev, 'search_data'),
        limit,
        offset: 0,
      }));
    } else {
      setConfig((prev) => ({
        ...prev,
        limit,
        offset: 0,
        search_data: {
          fields: {
            uinfo: {
              first_name: 'first_name',
              last_name: 'last_name',
            },
          },
          value,
        },
      }));
    }
  };

  const getActorsR54 = async () => {
    setIsFetching(true);
    const res = await getDeliveryUsers(config, [
      'FETCH_ACTORS_HRM54_REQUEST',
      'FETCH_ACTORS_HRM54_SUCCESS',
      'FETCH_ACTORS_HRM54_FAILURE',
    ]);
    if (res) {
      const newActors = res?.data?.map?.((item) => ({
        value: getUuid(item),
        actor: item?.actor ?? '',
        label: `${capitalize(firstName(item))} ${capitalize(lastName(item))}`,
        isWMSAdmin: actorIsRootOrAdmin(item),
        user: item,
      })) ?? [];

      if (needClearUserList) {
        setActors([...newActors]);
      } else {
        setActors((prev) => ([...prev, ...newActors]));
      }

      needClearUserList = false;
      setTotal(res?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (parent) {
      setConfig((prev) => ({
        ...prev, limit, depth, parent,
      }));
    }
  }, [parent, limit, depth]);

  useEffect(() => {
    if (parent) {
      getActorsR54();
    }
  }, [parent, JSON.stringify(config)]);

  return {
    actors,
    total,
    config,
    setConfig,
    onChangeSearch,
    valueSearch: config?.search_data?.value ?? '',
    isFetching,
  };
}
