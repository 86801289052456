import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, head, isEmpty } from 'lodash';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';
import { entityRead } from '../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../api/app_config';

function ShortLinksRedirect() {
  const dispatch = useDispatch();

  const goToDefaultPage = () => window.location.replace(`${window.location.origin}`);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);
  const userInfo = useSelector(getProfileInfo);

  const getCurrentEntity = async (uuid, type) => {
    const res = await dispatch(entityRead({
      data: {
        entity_type: type,
        entity_uuid: uuid,
      },
      constants: [
        'SHORT_LINK_ENTITY_REQUEST',
        'SHORT_LINK_ENTITY_SUCCESS',
        'SHORT_LINK_ENTITY_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));

    return head(res) ?? {};
  };

  const getLink = async () => {
    const arrOfRout = window.location.pathname.split('=');
    const pathForEntity = arrOfRout[0];
    const entityUUID = arrOfRout[1];

    switch (pathForEntity) {
      case '/s/v':
        if (isUserRootOrAdmin) {
          window.location.replace(`${window.location.origin}/admin/vacations?v=${entityUUID}`);
        } else {
          window.location.replace(`${window.location.origin}/deliverydashboard?v=${entityUUID}`);
        }
        break;
      case '/s/de':
        if (isUserRootOrAdmin) {
          window.location.replace(`${window.location.origin}/departments/departmentsInfo?department=${entityUUID}`);
        }
        break;
      case '/s/ar':
        if (isUserRootOrAdmin) {
          const artifact = await getCurrentEntity(entityUUID, 'artifact');
          const departmentUUID = get(artifact, ['parent']);

          window.location.replace(`${window.location.origin}/departments/departmentsInfo?department=${departmentUUID}&artifact=${entityUUID}`);
        }
        break;
      default:
        goToDefaultPage();
        break;
    }
  };

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getLink();
    }
  }, [userInfo]);

  return null;
}

export default ShortLinksRedirect;
