import React, { useState } from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import './PermissionsStyle.scss';
import Icon from '@mdi/react';
import { mdiAppleKeyboardControl } from '@mdi/js';

function DescriptionPermissions() {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const toogleBtn = () => setShow(!show);

  const showString = (title, type, descriptions) => (
    <div>
      <span className="font-bold">
        {title}
        {type}
      </span>
      {descriptions}
    </div>
  );

  return (
    <Alert
      type="info"
      showIcon
      description={(
        <div className="flex justify-between">
          {!show ? (
            <div className="flex flex-col">
              {showString(
                capitalize(t('wms.permissions.names.create', 'create')),
                ' (C) - ',
                capitalize(t('wms.permissions.body.create_description', 'Позволяет пользователю создавать сущности ')),
              )}

              {showString(
                capitalize(t('wms.permissions.names.read', 'read')),
                ' (R) - ',
                capitalize(t('wms.permissions.body.read_description', 'Позволяет пользователю получать сущности')),
              )}

              {showString(
                capitalize(t('wms.permissions.names.update', 'update')),
                ' (U) - ',
                capitalize(t('wms.permissions.body.update_description', 'Позволяет пользователю обновлять объекты')),
              )}

              {showString(
                capitalize(t('wms.permissions.names.delete', 'delete')),
                ' (D) - ',
                capitalize(t('wms.permissions.body.delete_description', 'Позволяет пользователю удалять сущности')),
              )}

              {showString(
                capitalize(t('wms.permissions.names.list', 'list')),
                ' (L) - ',
                capitalize(t('wms.permissions.body.list_description', 'Позволяет пользователю получить доступ к сущностям внутри')),
              )}

              {showString(
                capitalize(t('wms.permissions.names.set', 'set')),
                ' (S) - ',
                capitalize(t('wms.permissions.body.super_set_description', 'Позволяет пользователю менять владельца или удалять сущности')),
              )}
              {showString(
                capitalize('Affix creator delete'),
                ' (ACD) - ',
                capitalize(t('wms.labels.assigned_acd_description', 'Позволяет пользователю удалять свои сущности которые он создал, такие как (комментарии, журнал времени) ')),
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              Help me
            </div>
          )}
          <div className="ml-2">
            <div
              className="show-button"
              onClick={toogleBtn}
            >
              <span>
                {show ? 'Show' : 'Hide'}
              </span>
              <Icon
                path={mdiAppleKeyboardControl}
                size={0.8}
                className={`ml-1 pt-1 show-button__icon ${show ? 'show-button__icon-active' : ''}`}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default DescriptionPermissions;
