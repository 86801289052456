import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Popover, Table, Typography,
} from 'antd';
import { object, string } from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiCommentOutline, mdiHelpCircleOutline } from '@mdi/js';
import { capitalize, concat, isNil } from 'lodash';
import useAPI from '../../../../api/useAPI';
import { getMonthlyStorageUUID } from '../../../../redux/storages/selectors';
import { getProfileInfo } from '../../../../redux/profile/selectors';

const {
  Paragraph,
} = Typography;

export default function UserPersonalReportHistory({
  currentDate,
  actor,
}) {
  const {
    getOrCreateMonthlyReport,
  } = useAPI();

  const myProfileData = useSelector(getProfileInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);

  const [monthlyReport, setMonthlyReport] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const onCloseModal = () => setModalOpen(false);
  const onOpenModal = () => setModalOpen(true);

  const dataTable = concat(monthlyReport?.params?.statistics, monthlyReport?.params?.logs)
    ?.filter((el) => !isNil(el));

  const columns = [
    {
      title: 'Дата создания',
      dataIndex: ['created'],
      render: (date, row) => {
        const content = (
          <Paragraph>{row?.comment}</Paragraph>
        );
        if (row?.comment) {
          return (
            <div className="flex">
              <span className="mr-1">{dayjs(date).format('HH:mm, DD MMM YYYY')}</span>
              <Popover
                content={content}
                style={{
                  cursor: 'pointer',
                  color: '#1677ff',
                }}
                title="Comment"
              >
                <Icon
                  color="#1d39c4"
                  path={mdiCommentOutline}
                  size={1}
                />
              </Popover>
            </div>
          );
        }
        return dayjs(date).format('DD MMM YYYY');
      },
      sorter: (a, b) => dayjs(a?.created).unix() - dayjs(b?.created).unix(),
    },
    {
      title: 'Проектные часы',
      dataIndex: ['totalWorks'],
      render: (totalWorks) => <span>{totalWorks ?? 0}</span>,
      sorter: (a, b) => +(a?.totalWorks ?? 0) - +(b?.totalWorks ?? 0),
    },
    {
      title: 'Нерабочие часы',
      dataIndex: ['totalDayOff'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => +(a?.totalDayOff ?? 0) - +(b?.totalDayOff ?? 0),
    },
    {
      title: 'Бенч',
      dataIndex: ['totalBenches'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => +(a?.totalBenches ?? 0) - +(b?.totalBenches ?? 0),
    },
    {
      title: 'Больничный',
      dataIndex: ['sickday'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => +(a?.sickday ?? 0) - +(b?.sickday ?? 0),
    },
    {
      title: 'Отгул',
      dataIndex: ['dayoff'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => +(a?.dayoff ?? 0) - +(b?.dayoff ?? 0),
    },
    {
      title: 'Отпуск',
      dataIndex: ['vacation'],
      render: (el) => <span>{el ?? 0}</span>,
      sorter: (a, b) => +(a?.vacation ?? 0) - +(b?.vacation ?? 0),
    },
  ];

  const getListDayOffsAndReport = async () => {
    if (!actor) return;

    const configRequest = {
      actor,
      params: {
        date: dayjs(currentDate).format('YYYY-MM'),
      },
    };
    const res = await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest);

    setMonthlyReport(res);
  };

  useEffect(() => {
    if (currentDate && isModalOpen) {
      getListDayOffsAndReport();
    }
  }, [
    currentDate, actor, myProfileData?.uuid, isModalOpen,
  ]);

  return (
    <>
      <Button
        onClick={onOpenModal}
      >
        История отчетов за
        {dayjs(currentDate).format(' MMMM')}
      </Button>
      <Modal
        title={`История отчетов за ${capitalize(dayjs(currentDate).format('MMMM, YYYY'))}`}
        open={isModalOpen}
        onCancel={onCloseModal}
        footer={null}
        destroyOnClose
        centered
        width={1000}
      >
        <Table
          // rowKey={(row) => row?.created}
          columns={columns}
          className="mt-4"
          dataSource={dataTable}
          size="small"
        />
      </Modal>
    </>
  );
}

UserPersonalReportHistory.propTypes = {
  currentDate: string,
  currentActor: string,
};
