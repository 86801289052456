import React, { useEffect, useState } from 'react';
import {
  Button, Card, Divider, Flex,
  Input,
} from 'antd';
import dayjs from 'dayjs';
import {
  mdiArrowLeft, mdiCalendarClock, mdiCheckboxMarkedCircleOutline, mdiClose, mdiPencil, mdiPlus, mdiPuzzle,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { head } from 'lodash';
import PageWrapper from '../../../components/PageWrapper';
import BaseTableWithPagination from '../../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import CommonInfo from '../../admin/departments/common/CommonInfo';
import CopyURL from '../../../components/commonComponents/CopyURL';
import ArtifactCard from '../../admin/departments/artifacts/ArtifactCard';
import ArtifactsList from '../../admin/departments/artifacts/ArtifactsList';
import useArtifacts from '../../../redux/departments/hooks/useArtifacts';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import CardCreateMetaevent from '../../../components/events/CardCreateMetaevent';
import { getListAndReadEntities } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { allEventsEntityTypes } from '../../../components/events/utils/eventConstants';
import { getFullNameOrInitials } from '../../../utils/utils54origins';
import { getEventNameByMetaeventType } from '../../../components/events/utils/eventUtils';
import antNotification from '../../../utils/antNotification';

const data = [{
  title: 'Создание отчета',
  date: '2024-01-01',
  name: 'Oleg Olegovich',
  status: 'active',
}, {
  title: 'Утверждение отпуска',
  date: '2023-02-10',
  name: 'Artem Novik',
  status: 'archive',
}, {
  title: 'Увольнение',
  date: '2020-12-11',
  name: 'Artem Novik',
  status: 'active',
}, {
  title: 'Онбординг',
  date: '2024-05-05',
  name: 'Oleg Olegovich',
  status: 'active',
}, {
  title: 'Больничный',
  date: '2023-02-10',
  name: 'Artem Novik',
  status: 'archive',
}];

const events = [{
  title: 'Создание месячного отчета подрядчика',
  value: 'create_monthly_report',
  steps: 3,
}, {
  title: 'Утверждение отпуска',
  // value: 'vacation',
  steps: 6,
}, {
  title: 'Отключение подрядчика',
  // value: 'disconnecting',
  steps: 4,
}, {
  title: 'Онбординг подрядчика',
  // value: 'onboarding',
  steps: 7,
}, {
  title: 'Создание больничного',
  // value: 'sickday',
  steps: 5,
}, {
  title: 'Утверждение отгула',
  // value: 'dayoff',
  steps: 4,
}, {
  title: 'Создание отчета отдела',
  // value: 'departmentReport',
  steps: 3,
}, {
  title: 'Перевод подрядчика в другой отдел',
  // value: 'transfer',
  steps: 2,
}, {
  title: 'Создание заявки на подрядчика',
  // value: 'employeeRequest',
  steps: 2,
}];

export default function EventsPage() {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [metaeventType, setMetaeventType] = useState('');
  const [metaevents, setMetaevents] = useState([]);

  const {
    artifacts,
    paginationOptions,
  } = useArtifacts({
    parentUUID: partitionUUID,
    defaultConfig: {
      depth: 0,
      offset: 6,
      limit: 3,
      entity_type: 'instruction',
    },
  });

  const columnsEvents = [
    {
      key: 'title',
      title: 'Название',
      dataIndex: ['title'],
      width: '80%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {cell}
        </span>
      ),
    }, {
      key: 'steps',
      title: <Flex justify="center">Шаги</Flex>,
      dataIndex: ['steps'],
      width: '10%',
      render: (cell) => (
        <Flex
          justify="center"
          className="text-blue-500 font-bold"
        >
          {cell}
        </Flex>
      ),
    }, {
      key: 'value',
      title: 'Конструктор',
      dataIndex: ['value'],
      width: '10%',
      render: (cell) => cell && (
        <Flex
          style={{
            border: '1px solid black',
            maxWidth: '100px',
          }}
          onClick={() => setMetaeventType(cell)}
          justify="center"
          className="cursor-pointer !border-blue-500 !rounded-md hover:!bg-sky-100"
        >
          <Icon path={mdiPlus} className="text-blue-500" size={1} />
          <Icon path={mdiPuzzle} className="text-blue-500" size={1.15} />
        </Flex>
      ),
    }];

  const columns = [
    {
      key: 'title',
      title: 'Название',
      dataIndex: ['params', 'title'],
      width: '30%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {cell}
        </span>
      ),
    },
    // {
    //   key: 'status',
    //   title: 'Статус',
    //   dataIndex: ['status'],
    //   width: '20%',
    //   render: (cell) => {
    //     switch (cell) {
    //       case 'active': {
    //         return (
    //           <Flex
    //             align="center"
    //             style={{
    //               width: '75px',
    //               border: '1px solid #22c55e',
    //             }}
    //             className="w-max border-green-500 bg-green-50 px-2 rounded text-sm text-green-500"
    //           >
    //             <Flex
    //               className="mr-1"
    //             >
    //               <Icon path={mdiCheckboxMarkedCircleOutline} size={0.7} />
    //             </Flex>
    //             Active
    //           </Flex>
    //         );
    //       }
    //       case 'archive': {
    //         return (
    //           <Flex
    //             align="center"
    //             style={{
    //               width: '75px',
    //               border: '1px solid #A9A9A9',
    //             }}
    //             className="w-max border-gray-500 bg-gray-50 px-2 rounded text-sm text-gray-500"
    //           >
    //             <Flex
    //               className="mr-1"
    //             >
    //               <Icon path={mdiCalendarClock} size={0.7} />
    //             </Flex>
    //             Archive
    //           </Flex>
    //         );
    //       }
    //       default:
    //         return null;
    //     }
    //   },
    // },
    {
      key: 'event',
      title: 'Событие',
      dataIndex: ['entity_type'],
      width: '30%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {getEventNameByMetaeventType(cell)}
        </span>
      ),
    },
    {
      key: 'actor',
      title: 'Создатель',
      dataIndex: ['uinfo'],
      width: '30%',
      render: (cell, row) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {getFullNameOrInitials(row, 'fullname')}
        </span>
      ),
    },
    {
      key: 'date',
      title: 'Создано',
      dataIndex: ['created'],
      width: '20%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {dayjs(cell).format('YYYY-MM-DD')}
        </span>
      ),
    }];

  // const columnsArt = [
  //   {
  //     key: 'type',
  //     title: 'Тип',
  //     dataIndex: ['entity_type'],
  //     width: '10%',
  //     render: (cell) => {
  //       switch (cell) {
  //         case 'file': {
  //           return <Flex className="text-blue-500">Файл</Flex>;
  //         }
  //         case 'instruction': {
  //           return <Flex className="text-blue-500">Инструкция</Flex>;
  //         }
  //         case 'user': {
  //           return <Flex className="text-green-500">Сотрудник</Flex>;
  //         }
  //         default: return '';
  //       }
  //     },
  //   },
  //   {
  //     key: 'title',
  //     title: 'Название',
  //     dataIndex: ['params', 'title'],
  //     width: '50%',
  //     render: (cell, row) => (
  //       <span
  //         style={{
  //           wordBreak: 'break-word',
  //         }}
  //       >
  //         {cell}
  //       </span>
  //     ),
  //   },
  //   {
  //     key: 'status',
  //     title: 'Статус',
  //     dataIndex: ['params', 'status'],
  //     width: '20%',
  //     render: (cell) => {
  //       switch (cell) {
  //         case 'active': {
  //           return (
  //             <Flex
  //               align="center"
  //               style={{
  //                 width: '75px',
  //                 border: '1px solid #22c55e',
  //               }}
  //               className="w-max border-green-500 bg-green-50 px-2 rounded text-sm text-green-500"
  //             >
  //               <Flex
  //                 className="mr-1"
  //               >
  //                 <Icon path={mdiCheckboxMarkedCircleOutline} size={0.7} />
  //               </Flex>
  //               Active
  //             </Flex>
  //           );
  //         }
  //         case 'archive': {
  //           return (
  //             <Flex
  //               align="center"
  //               style={{
  //                 width: '75px',
  //                 border: '1px solid #A9A9A9',
  //               }}
  //               className="w-max border-gray-500 bg-gray-50 px-2 rounded text-sm text-gray-500"
  //             >
  //               <Flex
  //                 className="mr-1"
  //               >
  //                 <Icon path={mdiCalendarClock} size={0.7} />
  //               </Flex>
  //               Archive
  //             </Flex>
  //           );
  //         }
  //         default:
  //           return (
  //             <Flex
  //               align="center"
  //               style={{
  //                 width: '75px',
  //                 border: '1px solid #22c55e',
  //               }}
  //               className="w-max border-green-500 bg-green-50 px-2 rounded text-sm text-green-500"
  //             >
  //               <Flex
  //                 className="mr-1"
  //               >
  //                 <Icon
  //                   path={mdiCheckboxMarkedCircleOutline}
  //                   size={0.7}
  //                 />
  //               </Flex>
  //               Active
  //             </Flex>
  //           );
  //       }
  //     },
  //   }, {
  //     key: 'created',
  //     title: 'Дата',
  //     dataIndex: ['created'],
  //     width: '20%',
  //     render: (cell) => dayjs(cell).format('YYYY-MM-DD'),
  //   }];

  const initMetaEvents = async () => {
    const res = await dispatch(getListAndReadEntities({
      data: {
        parent: partitionUUID,
        depth: 0,
        entity_type: allEventsEntityTypes,
      },
      constants: [
        'UPDATE_USER_REQUEST',
        'UPDATE_USER_SUCCESS',
        'UPDATE_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
    // console.log(res);
    setMetaevents(res?.data);
  };

  useEffect(() => {
    if (partitionUUID) {
      initMetaEvents();
    }
  }, [partitionUUID]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Панель событий
        </Flex>
      )}
    >
      <Flex
        className="w-full"
      >
        <Flex
          vertical
          className="w-1/2 h-max"
        >
          <Card
            className="p-0 pb-2 mb-6 blue-base-card"
            title={(
              <Flex
                align="center"
              >
                <Flex
                  className="text-xl"
                >
                  События
                </Flex>
              </Flex>
      )}
          >
            <Flex
              className="mx-2"
              vertical
            >
              <BaseTableWithPagination
                columns={columnsEvents}
                data={events}
                rowKey="uuid"
                size="small"
                className="transition-transform duration-300 ease-in-out"
                headerRowClassName="universal_header_table_row color-gray"
                hideListSizeOption={false}
                useCustomPagination
                pageSizeOptions={[10, 25, 50]}
                disablePagination={false}
              />
            </Flex>
          </Card>
          <Card
            className="p-0 pb-2 orange-base-card"
            title={(
              <Flex
                align="center"
              >
                <Flex
                  className="text-xl"
                >
                  Метасобытия
                </Flex>
                <Button
                  size="small"
                  className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
                >
                  Создать метасобытие
                </Button>
              </Flex>
      )}
          >
            <Flex
              className="mx-2"
              vertical
            >
              <BaseTableWithPagination
                columns={columns}
                data={metaevents}
                rowKey="uuid"
                size="small"
                className="transition-transform duration-300 ease-in-out"
                headerRowClassName="universal_header_table_row color-gray"
                hideListSizeOption={false}
                useCustomPagination
                pageSizeOptions={[10, 25, 50]}
                disablePagination={false}
              />
            </Flex>
          </Card>
        </Flex>
        <Flex
          vertical
          className="w-1/2 ml-6 h-max"
        >
          {metaeventType && (
          <CardCreateMetaevent
            onClose={() => {
              setMetaeventType('');
            }}
            onCreate={() => {
              antNotification.success('Метасобытие создано!');
              setMetaeventType('');
              initMetaEvents();
            }}
            defaultValues={{
              eventType: metaeventType,
            }}
          />
          )}
          {/* <Card
            className="p-0 pb-2 mb-6 blue-base-card"
            title={(
              <Flex
                align="center"
                className="w-full"
              >
                <Flex
                  align="center"
                  justify="space-between"
                  className="cursor-pointer w-full"
                >
                  <span className="ml-3 text-xl">
                    Конструктор метасобытия
                  </span>
                  <Icon path={mdiClose} size={0.9} />
                </Flex>
              </Flex>
      )}
          >
            <Flex
              className="mx-12 mt-2"
            >
              <span className="text-blue-500 text-base">Событие:</span>
              <span className="text-base ml-2">Утверждение отпуска</span>
            </Flex>
            <Divider className="my-2" />
            <Flex
              align="center"
              className="mx-12 mt-2"
            >
              <span className="text-blue-500 text-base">Название:</span>
              <Input
                className="text-base ml-2"
                value="Утверждение отпуска (для отдела PHP)"
              />
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12 mt-2 text-blue-500 text-lg"
            >
              Шаг 1 (создание отпуска)
            </Flex>
            <Flex
              className="mx-12 mt-2"
              vertical
            >
              <Flex
                className="mt-0.5"
              >
                <span className="text-blue-500 text-base">Условие вызова:</span>
                <span className="text-base ml-2">Нажатие на кнопку "Создать отгул"</span>
              </Flex>
              <Flex
                className="mt-0.5"
              >
                <span className="text-blue-500 text-base">Инструкция:</span>
                <span className="text-base ml-2">Правила создания отпуска</span>
                <Flex
                  style={{ border: '1px solid black' }}
                  className="ml-2 p-0.5 !rounded-full text-blue-500 !border-blue-500"
                >
                  <Icon path={mdiPencil} size={0.8} />
                </Flex>
              </Flex>
              <Flex
                className="mt-0.5"
                vertical
              >
                <span className="text-blue-500 text-base">Отправить уведомления:</span>
                <span className="text-base ml-4">Руководитель отдела (MyBoardUser MyBoardUser)</span>
                <span className="text-base ml-4">Заместитель отдела (Oleg Olegovich)</span>
              </Flex>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12 mt-2 text-blue-500 text-lg"
            >
              Шаг 2 (предварительное согласование)
            </Flex>
            <Flex
              className="mx-12 mt-2"
              vertical
            >
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Условие вызова:</span>
                <span className="text-base ml-2">Нажатие на кнопку "Предварительно согласовать"</span>
              </Flex>
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Инструкция:</span>
                <span className="text-base ml-2">Правила согласования и утверждения отпуска</span>
                <Flex
                  style={{ border: '1px solid black' }}
                  className="ml-2 p-0.5 !rounded-full text-blue-500 !border-blue-500"
                >
                  <Icon path={mdiPencil} size={0.8} />
                </Flex>
              </Flex>

              <Flex
                className="mt-0.5"
                vertical
              >
                <span className="text-blue-500 text-base">Отправить уведомления:</span>
                <span className="text-base ml-4">Отправитель заявки на отпуск (Подрядчик)</span>
              </Flex>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12 mt-2 text-blue-500 text-lg"
            >
              Шаг 3 (утверждение)
            </Flex>
            <Flex
              className="mx-12 mt-2"
              vertical
            >
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Условие вызова:</span>
                <span className="text-base ml-2">Нажатие на кнопку "Утвердить"</span>
              </Flex>
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Инструкция:</span>
                <span className="text-base ml-2">Правила согласования и утверждения отпуска</span>
                <Flex
                  style={{ border: '1px solid black' }}
                  className="ml-2 p-0.5 !rounded-full text-blue-500 !border-blue-500"
                >
                  <Icon path={mdiPencil} size={0.8} />
                </Flex>
              </Flex>

              <Flex
                className="mt-0.5"
                vertical
              >
                <span className="text-blue-500 text-base">Отправить уведомления:</span>
                <span className="text-base ml-4">Отправитель заявки на отпуск (Подрядчик)</span>
              </Flex>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12 mt-2 text-blue-500 text-lg"
            >
              Возможный Шаг (отклонение)
            </Flex>
            <Flex
              className="mx-12 mt-2"
              vertical
            >
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Условие вызова:</span>
                <span className="text-base ml-2">Нажатие на кнопку "Отклонить"</span>
              </Flex>
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Инструкция:</span>
                <span className="text-base ml-2">Правила отклонения отпуска</span>
                <Flex
                  style={{ border: '1px solid black' }}
                  className="ml-2 p-0.5 !rounded-full text-blue-500 !border-blue-500"
                >
                  <Icon path={mdiPencil} size={0.8} />
                </Flex>
              </Flex>

              <Flex
                className="mt-0.5"
                vertical
              >
                <span className="text-blue-500 text-base">Отправить уведомления:</span>
                <span className="text-base ml-4">Отправитель заявки на отпуск (Подрядчик)</span>
              </Flex>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12"
            >
              <span className="text-blue-500 text-base">Прикрепленные артефакты:</span>
            </Flex>
            <BaseTableWithPagination
              columns={columnsArt}
              data={artifacts}
              rowKey="uuid"
              size="small"
              className="w-full h-full !mb-3"
              pageSizeOptions={[20, 50, 100]}
              useCustomPagination
              disablePagination={false}
            />
          </Card> */}

          {/* <Card
            className="p-0 pb-2 orange-base-card"
            title={(
              <Flex
                align="center"
              >
                <Flex
                  align="center"
                  className="cursor-pointer"
                >
                  <Icon path={mdiArrowLeft} size={0.9} />
                  <span className="ml-3 text-xl">
                    Метасобытие
                    {' '}
                  </span>
                </Flex>
                <CommonInfo
                  iconSize={1}
                  style={{
                    width: 25,
                    minWidth: 25,
                    height: 25,
                  }}
                  className="ml-2 mt-1"
                />
                <CopyURL
                  entityType="department"
                // entityUUID={uuid}
                  className="ml-1 pt-2.5 mt-0.5 pl-2"
                  iconSize={1}
                  style={{
                    width: 25,
                    minWidth: 25,
                    height: 25,
                  }}
                />
              </Flex>
      )}
          >
            <Flex
              className="mx-12 mt-2"
            >
              <span className="text-blue-500 text-base">Название:</span>
              <span className="text-base ml-2">Отгул</span>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12 mt-2"
              justify="space-between"
            >
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Условие вызова:</span>
                <span className="text-base ml-2">Нажатие на кнопку "Создать отгул"</span>
              </Flex>
              <Flex
                className=""
              >
                <span className="text-blue-500 text-base">Приоритет:</span>
                <span className="text-base text-red-500 ml-2">Высокий</span>
              </Flex>
            </Flex>
            <Divider className="my-2" />
            <Flex
              className="mx-12"
            >
              <span className="text-blue-500 text-base">Прикрепленные артефакты:</span>
            </Flex>
            <BaseTableWithPagination
              columns={columnsArt}
              data={artifacts}
              rowKey="uuid"
              size="small"
              className="w-full h-full !mb-3"
              pageSizeOptions={[20, 50, 100]}
              useCustomPagination
              disablePagination={false}
            />
          </Card> */}
        </Flex>
      </Flex>
    </PageWrapper>
  );
}
