import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Button, Col, Divider, Form, Input, Modal, Row, Segmented, Typography,
} from 'antd';
import { capitalize, head } from 'lodash';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessDays } from '../../../../utils/dateTimeUtils';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import useAPI from '../../../../api/useAPI';
import { getArrByType, getHours } from '../../dayOff/utils';
import AntUploaderFiles from '../../../../components/commonComponents/AntUploaderFiles';
import { getMonthlyStorageUUID, getUserRootInfo, getVacationStorageUUID } from '../../../../redux/storages/selectors';
import useVacationsAPI from '../../../../api/useVacationsAPI';
import { entityRead, getListAndReadEntities } from '../../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';

const { Paragraph } = Typography;

const optionsTypeForm = [{
  label: 'Данные календаря',
  value: 'calendar',
}, {
  label: 'Данные предыдущего отчета',
  value: 'lastreport',
}];

function UserStatisticsForm({
  isAheadOfTime,
  isModalOpen,
  onCancelModal,
  currentMonth,
  onFinish,
  formSettings,
  actor,
}) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const validTotalWorks = +Form.useWatch('totalWorks', form);

  const userRootInfo = useSelector(getUserRootInfo);

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);

  const [userDayOff, setUserDayOff] = useState([]);
  const [monthlyReport, setMonthlyReport] = useState(null);
  const [vacationsArr, setVacationsArr] = useState([]);
  const [filesList, setFiles] = useState([]);
  const [selectedTypeForm, setSelectedTypeForm] = useState('calendar');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [metaevent, setMetaevent] = useState(null);

  const {
    getMonthlyReport,
  } = useAPI();

  const {
    getListOfVacations,
  } = useVacationsAPI();

  const { title, commentLabel, totalWorksLabel } = formSettings;

  const begin = dayjs(currentMonth).startOf('month');
  const end = dayjs(currentMonth).endOf('month');
  const startMonth = dayjs(currentMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(currentMonth).endOf('month').format('YYYY-MM-DD');

  const isWeekday = (date) => {
    const dayOfWeek = dayjs(date).day();
    return dayOfWeek >= 1 && dayOfWeek <= 5;
  };

  const userStatistic = useMemo(() => {
    const data = {
      office_work: 0,
      remote_work: 0,
      work_on_day_off: 0,
      totalWorks: 0,
      sickday: 0,
      holidays: 0,
      vacation: 0,
      projectDayOffs: 0,
      dayoff: 0,
      localHoliday: 0,
      totalDayOff: 0,
      comment: '',
      bench_1_month: 0,
      bench_over_1_month: 0,
      bench_over_3_month: 0,
      totalBenches: 0,
      internalWork: 0,
      clientWork: 0,
    };

    const allDaysWithType = [...getArrByType([
      ...vacationsArr, ...userDayOff,
    ], enumerateDaysBetweenDates(startMonth, endMonth))]
      .map(([key, value]) => ({ ...value }));

    data.office_work = getHours(allDaysWithType.filter((item) => item.type === 'office_work'));
    data.remote_work = getHours(allDaysWithType.filter((item) => item.type === 'remote_work'));
    data.work_on_day_off = getHours(allDaysWithType.filter((item) => item.type === 'work_on_day_off'));

    data.totalWorks = data.office_work + data.remote_work + data.work_on_day_off;

    data.bench_1_month = (allDaysWithType.filter((item) => item.type === 'bench_1_month').length || 0) * 8;
    data.bench_over_1_month = (allDaysWithType.filter((item) => item.type === 'bench_over_1_month').length || 0) * 8;
    data.bench_over_3_month = (allDaysWithType.filter((item) => item.type === 'bench_over_3_month').length || 0) * 8;

    data.totalBenches = data.bench_1_month + data.bench_over_1_month
      + data.bench_over_3_month;

    data.sickday = (allDaysWithType.filter((item) => item.type === 'sickday').length || 0) * 8;
    // data.holidays = (allDaysWithType.filter((item) => item.type === 'holidays').length || 0) * 8;
    data.vacation = (allDaysWithType.filter((item) => item.type === 'vacation' && isWeekday(item?.date)).length || 0) * 8;
    data.dayoff = (allDaysWithType.filter((item) => item.type === 'dayoff').length || 0) * 8;

    data.totalDayOff = data.sickday + data.vacation
      + data.dayoff + data.bench_1_month + data.bench_over_1_month
      + data.bench_over_3_month;

    return data;
  }, [
    JSON.stringify(vacationsArr),
    JSON.stringify(userDayOff),
    JSON.stringify(startMonth),
    JSON.stringify(endMonth),
    isModalOpen,
  ]);

  const getListAgreedVacations = async () => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
    };
    const res = await getListOfVacations(
      vacationStorageUUID,
      configRequest,
      [
        'GET_VACATIONS_REQUEST',
        'GET_VACATIONS_SUCCESS',
        'GET_VACATIONS_FAILURE',
      ],
    );
    setVacationsArr(res?.data);
  };

  const getListDayOffsAndReport = async () => {
    const configRequest = {
      actor,
      params: {
        date: dayjs(startMonth).format('YYYY-MM'),
      },
      with_files: true,
      depth: 0,
    };
    const res = await getMonthlyReport(monthlyStorageUUID, configRequest);

    setMonthlyReport(res?.data?.[0]);
    setUserDayOff(res?.data?.[0]?.params?.days || []);
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);

    let data;

    if (selectedTypeForm === 'calendar') {
      data = {
        ...userStatistic,
        ...values,
      };
    } else {
      data = {
        ...monthlyReport?.params?.statistics,
        ...values,
      };
    }
    await onFinish(
      data,
      filesList,
      monthlyReport?.uuid,
      monthlyReport?.files,
    );

    setFiles([]);
    setDisabledSubmit(false);
  };

  const onChangeTypeForm = (type) => {
    setSelectedTypeForm(type);

    if (type === 'calendar') {
      form.setFieldsValue({
        ...userStatistic,
        comment: '',
      });
    } else {
      form.setFieldsValue({
        office_work: 0,
        // remote_work: 0,
        // work_on_day_off: 0,
        totalWorks: 0,
        sickday: 0,
        // holidays: 0,
        vacation: 0,
        // projectDayOffs: 0,
        dayoff: 0,
        // localHoliday: 0,
        totalDayOff: 0,
        comment: '',
        bench_1_month: 0,
        bench_over_1_month: 0,
        bench_over_3_month: 0,
        totalBenches: 0,
        ...(monthlyReport?.params?.statistics ?? {}),
      });
    }
  };

  const initMetaeventInstruction = async () => {
    const res = await dispatch(entityRead({
      data: {
        entity_uuid: userRootInfo?.params?.personal_metaevent_create_monthly_report,
      },
      constants: [
        'READ_METAEVENTS_REQUEST',
        'READ_METAEVENTS_SUCCESS',
        'READ_METAEVENTS_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }));
    console.log('инструкция', res);
    setMetaevent(head(res));
  };

  useEffect(() => {
    if (userRootInfo?.params?.personal_metaevent_create_monthly_report) {
      initMetaeventInstruction();
    }
  }, [userRootInfo?.params?.personal_metaevent_create_monthly_report]);

  useEffect(() => {
    if (actor && vacationStorageUUID && monthlyStorageUUID) {
      getListDayOffsAndReport();
      getListAgreedVacations();
    }
  }, [
    actor,
    vacationStorageUUID,
    startMonth,
    isModalOpen,
    monthlyStorageUUID,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      ...userStatistic,
      comment: '',
    });
  }, [
    JSON.stringify(userStatistic),
    isModalOpen,
  ]);

  useEffect(() => {
    setSelectedTypeForm('calendar');
  }, [isModalOpen]);

  return (
    <Modal
      width={1000}
      title={title}
      open={isModalOpen}
      onCancel={() => {
        setFiles([]);
        onCancelModal();
      }}
      destroyOnClose
      centered
      footer={null}
    >
      {monthlyReport?.params?.status === 'submitted' && (
        <Col span={24} style={{ marginBottom: 8 }}>
          <Segmented
            options={optionsTypeForm}
            value={selectedTypeForm}
            defaultValue="calendar"
            onChange={onChangeTypeForm}
          />
        </Col>
      )}
      <Form
        preserve={false}
        form={form}
        name="report"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          ...userStatistic,
          comment: '',
        }}
        onFinish={onSubmit}
      >
        <Row gutter={[24, 24]}>
          <Alert
            className="-mb-2"
            message="У вас есть персональная инструкция по заполнению отчета!"
            description={metaevent?.params?.textInfo}
            type="error"
            showIcon
          />

          <div style={{ marginLeft: 12, marginBottom: -14 }}>
            Всего должно быть рабочих часов в месяце:
            {' '}
            {getBusinessDays(end, begin) * 8}
          </div>
          <Row
            style={{
              width: '100%',
              marginLeft: 12,
              marginTop: -12,
              marginBottom: -20,
              fontSize: '12px',
              color: 'grey',
            }}
          >
            * Без учета праздников
          </Row>
          <Col span={24}>
            {isAheadOfTime && (
              <Alert
                style={{ paddingTop: 15, marginBottom: 10 }}
                message={(
                  <Paragraph>
                    Отправка отчета досрочно крайне нежелательна.
                    Причина должна быть указана в поле "Комментарий".
                  </Paragraph>
                )}
                type="error"
                showIcon
              />
            )}

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Divider
                  plain
                  style={{
                    fontSize: '16px !important',
                    marginBottom: 2,
                    marginTop: -4,
                    color: '#777',
                  }}
                >
                  Проектные часы
                </Divider>

                <Form.Item
                  name="totalWorks"
                  label="Общие проектные часы"
                  rules={[{
                    required: true,
                    pattern: /^\.?(?!-)\d+(?:\.\d{1,2})?$/,
                    message: 'Поле является обязательным. Значение может быть целым или дробным (от одного до двух символов после точки).',
                    warningOnly: false,
                  }]}
                  normalize={(value) => value.replace(/[^\d\.]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>

                <div
                  style={{
                    color: 'grey', fontSize: 14, marginTop: -20, marginBottom: 5,
                  }}
                >
                  {`* В отчете будет отправлено: ${Math.ceil(validTotalWorks)}`}
                </div>
                <Row
                  justify="space-between"
                  style={{ marginBottom: -20 }}
                >
                  <Col span={11}>
                    <Form.Item
                      name="clientWork"
                      label="Часы на клиентском проекте:"
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input
                        maxLength={6}
                        placeholder={capitalize('введите количество часов')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="internalWork"
                      label="Часы на внутреннем проекте:"
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input
                        maxLength={6}
                        placeholder={capitalize('введите количество часов')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12} className="-mt-3">
                <Divider
                  plain
                  style={{
                    fontSize: '16px !important',
                    marginBottom: 4,
                    marginTop: 6,
                    color: '#777',
                  }}
                >
                  Нерабочие часы
                </Divider>
                <Row
                  justify="space-between"
                  style={{ marginBottom: -16 }}
                >
                  <Col span={11}>
                    <Form.Item
                      name="dayoff"
                      label="Количество часов отгула:"
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input
                        maxLength={6}
                        placeholder={capitalize('введите количество часов')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="sickday"
                      label="Количество больничных часов:"
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input
                        maxLength={6}
                        placeholder={capitalize('введите количество часов')}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  justify="space-between"
                  style={{
                    marginBottom: -16,
                  }}
                >
                  <Col span={24}>
                    <Form.Item
                      name="vacation"
                      label="Количество часов отпуска:"
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input
                        maxLength={6}
                        placeholder={capitalize('введите количество часов')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Divider
                  plain
                  style={{
                    fontSize: '16px !important',
                    marginBottom: 2,
                    marginTop: -4,
                    color: '#777',
                  }}
                >
                  Бенч
                </Divider>
                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="totalBenches"
                  label="Количество часов на бенче:"
                  normalize={(value) => value.replace(/[^\d]/g, '')}
                >
                  <Input
                    maxLength={6}
                    placeholder={capitalize('введите количество часов')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="comment"
              label={commentLabel}
              style={{ marginBottom: 12 }}
            >
              <TextArea
                maxLength={5000}
                placeholder="Введите комментарий"
                rows={4}
              />
            </Form.Item>

            <Form.Item
              label="Файлы (больничный):"
              style={{ marginBottom: 12 }}
            >
              <AntUploaderFiles
                hideTitle
                filesList={filesList}
                setFiles={setFiles}
              />
            </Form.Item>
            <Alert
              style={{ marginBottom: 14 }}
              message="Все изменения, внесенные в форму выше, не сохраняются после выхода из нее."
              type="warning"
              showIcon
            />
            <Form.Item
              shouldUpdate
              style={{
                display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 16, marginBottom: 4,
              }}
            >
              {() => (
                <>
                  <Button
                    style={{ marginRight: 10 }}
                    htmlType="button"
                    disabled={false}
                    onClick={() => {
                      setFiles([]);
                      onCancelModal();
                    }}
                  >
                    Отмена
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !!form.getFieldsError().filter(({ errors }) => errors.length).length
                      || disabledSubmit
                    }
                  >
                    Отправить отчет
                  </Button>
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default UserStatisticsForm;

UserStatisticsForm.propTypes = {

};
