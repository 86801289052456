// THIS IS GLOBAL REACT FILE FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.07.11 || YYYY/MM/DD

import { get } from 'lodash';
import Cookies from 'universal-cookie';

export function logoutAndClearAllCookies(callback) {
  try {
    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    document.cookie.split(';').forEach((c) => {
      document.cookie = `${c.trim().split('=')[0]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    // localStorage.clear();
    callback?.();
  } catch (e) {
    console.log('Something wrong with cookies', e);
  }
}

export const getNameForActor = (actor) => {
  const {
    actor_type: actorType,
    uinfo: {
      first_name: firstNameUINFO,
      last_name: lastNameUINFO,
      group_name: groupNameUINFO,
      service_name: serviceNameUINFO,
    } = {},
    first_name: firstNameActor,
    last_name: lastNameActor,
    group_name: groupNameActor,
    service_name: serviceNameActor,
  } = actor || {};

  const emptyName = 'name not found';

  const firstName = firstNameUINFO || firstNameActor || '';
  const lastName = lastNameUINFO || lastNameActor || '';
  const groupName = groupNameUINFO || groupNameActor || '';
  const serviceName = serviceNameUINFO || serviceNameActor || '';

  switch (actorType) {
    case 'user':
    case 'classic_user':
      return firstName || lastName ? `${firstName} ${lastName}` : emptyName;
    case 'group':
      return groupName || emptyName;
    case 'service':
      return serviceName || emptyName;
    default:
      return ((firstName || lastName) && (`${firstName} ${lastName}`)) || groupName || serviceName || emptyName;
  }
};

export const getFullNameOrInitials = (user, action) => {
  const firstName = get(user, 'uinfo.first_name', '');
  const lastName = get(user, 'uinfo.last_name', '');
  const fullName = `${firstName} ${lastName}`;
  const initials = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();

  if (action === 'fullname') {
    return fullName;
  }
  if (action === 'initials') {
    return initials;
  }
};

export const getCookie = (cookie) => {
  const cookies = new Cookies();

  return cookies.get(cookie);
};

export const removeCookie = (name) => {
  try {
    const cookies = new Cookies();

    cookies.remove(name, { path: '/' });
  } catch (e) {
    console.log('remove cookie', e);
  }
};

export const parseErrorFromRequest54origins = (error) => {
  const {
    status = 500,
    data: {
      error_message: errMessage = '',
      error_content: errContent = [],
    } = {},
  } = error?.response || {};

  let finalErrMessage = errMessage;

  if (errContent.length > 0) {
    finalErrMessage = errContent
      .map(({
        field,
        message,
      }) => `${field}: ${message}`)
      .join('\t');
  }

  return {
    message: finalErrMessage,
    status,
  };
};

export const stopPropagation = (e) => e?.stopPropagation();
