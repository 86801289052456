import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BaseTable from '../BaseTable/BaseTable';

function BaseTableWithPagination(props) {
  const {
    setLimitFromCollapse,
    changePageLimit,
    pageLimit,
    useCustomPagination,
    projectLimitFromCollapse,
    paginationSize = 'default',
    disablePagination = false,
    getPaginationOptions,
    defaultPage = 1,
    newCurrentPage,
    onChangePageCallback,
  } = props;

  const isDidMount = useRef(null);

  const [currentPage, changePage] = useState(defaultPage);

  const offset = pageLimit * (currentPage - 1);

  // console.log('pageLimit', pageLimit)
  // console.log('currentPage', currentPage)
  // console.log('offset', offset)

  const onChangePagination = (page) => {
    onChangePageCallback?.(page);
    changePage(page);
  };

  const onShowSizeChange = (current, size) => {
    changePage(1);
    changePageLimit?.(size);
    if (setLimitFromCollapse) {
      if (size === 15) {
        setLimitFromCollapse(15);
      } else {
        setLimitFromCollapse(50);
      }
    }
  };

  useEffect(() => {
    if (projectLimitFromCollapse) {
      changePageLimit?.(projectLimitFromCollapse);
    }
  }, [projectLimitFromCollapse]);

  useEffect(() => {
    if (isDidMount.current) {
      getPaginationOptions?.({
        pageLimit,
        currentPage,
        offset,
        limit: pageLimit,
      });
    } else { isDidMount.current = true; }
  }, [pageLimit, currentPage]);

  useEffect(() => {
    if (newCurrentPage && newCurrentPage !== currentPage) {
      changePage(newCurrentPage);
    }
  }, [newCurrentPage]);

  return (
    <BaseTable
      useCustomPagination={useCustomPagination}
      disablePagination={disablePagination}
      onChangePagination={onChangePagination}
      onShowSizeChange={onShowSizeChange}
      paginationSize={paginationSize}
      paginationPageSize={pageLimit}
      currentPage={currentPage}
      {...props}
    />
  );
}

export default BaseTableWithPagination;

BaseTableWithPagination.propTypes = {
  defaultPage: PropTypes.number,
  defaultPageLimit: PropTypes.number,
  disablePagination: PropTypes.bool,
  getPaginationOptions: PropTypes.any,
  newCurrentPage: PropTypes.any,
  paginationSize: PropTypes.string,
  total: PropTypes.any,
};
