import { get } from 'lodash';

export function convertTimeFormat(input) {
  const regex = /(\d+)h(\d+)m/;
  const match = input.match(regex);

  if (match) {
    const hours = match[1].padStart(2, '0');
    const minutes = match[2].padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return '';
}

export function convertTimeFormatToHours(timeStr) {
  const regex = /(\d{1,2})h(\d{2})m?/;
  const match = timeStr?.match(regex);

  if (!match) {
    return '';
  }

  const hours = parseInt(match[1], 10);
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  let result = '';

  if (hours > 0) {
    result += `${hours} час.`;
  }

  if (minutes > 0) {
    result += ` ${minutes} мин.`;
  }

  return result.trim();
}

export function compareActorUUIDs(a, b) {
  return get(a, 'actor', {}) === get(b, 'actor', {});
}
