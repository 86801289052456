import { get } from 'lodash';

export const getDepartments = (state) => get(state, 'Departments.listOfDepartments.departments', []);

export const getTotalDepartments = (state) => get(state, 'Departments.listOfDepartments.total', 0);

export const getIsFetchingDepartments = (state) => get(state, 'Departments.listOfDepartments.fetching', false);

export const getDepartment = (state) => get(state, 'Departments.listOfDepartments.department', {});

export const getDepartmentUUID = (state) => get(state, 'Departments.listOfDepartments.department.uuid', '');

export const getIsFetchingDepartment = (state) => get(state, 'Departments.listOfDepartments.fetchingSingle', false);
