import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Tabs } from 'antd';
import { get } from 'lodash';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import PermissionsStartPage from '../../../../components/permissions/PermissionsStartPage';
import useAPI from '../../../../api/useAPI';
import useArifactsStorage from '../../../../redux/departments/hooks/useArifactsStorage';
import useDefaultDepartment from '../../../../redux/departments/hooks/useDefaultDepartment';

export default function AdminPermissions() {
  const rootPartitionUuid = useSelector(getDeliveryPartition);

  const [activeKey, setActiveKey] = useState('1');

  const { uuid: artifactsStorageUUID } = useArifactsStorage(true);

  const { uuid: defaultDepartmentUUID } = useDefaultDepartment(false);

  return (
    <Flex
      vertical
      className="w-full"
    >
      <Tabs
        type="card"
        className="mb-2"
        defaultActiveKey="1"
        activeKey={activeKey}
        items={[
          {
            key: '1',
            label: 'Delivery partition permissions',
          },
          {
            key: '2',
            disabled: !defaultDepartmentUUID,
            label: 'Default department permissions',
          },
          {
            key: '3',
            disabled: !artifactsStorageUUID,
            label: 'Default artifacts storage permissions',
          },
        ]}
        onChange={setActiveKey}
        tabBarStyle={{
          marginBottom: 0,
          borderRadius: '0px',
        }}
      />
      {activeKey === '1' && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={rootPartitionUuid}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Delivery partition permissions"
        />
      )}
      {activeKey === '2' && defaultDepartmentUUID && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={defaultDepartmentUUID}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Default department permissions"
        />
      )}
      {activeKey === '3' && artifactsStorageUUID && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={artifactsStorageUUID}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Default artifact storage permissions"
        />
      )}
    </Flex>
  );
}
